/**
 * @Project: School-Bus
 * @Filename: students.list.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-24T16:04:14+05:30
 * @Copyright: Group10 Technologies
 */


import {
  Component, Directive,
  ElementRef,
  AfterViewInit,
  OnDestroy, EventEmitter,
  ViewChild, Input, Output
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
//import { AppComponent } from './../../app.component';

//import { globalService } from './../../services/global.service';
import { ApiService } from './../../services/ApiService';
//import { ActivatedRoute } from '@angular/router';
import { authGaurd } from './../../services/auth-guard.service';
//import { GlobalUtils } from './../../services/global-utils';
import { PagerService } from './../../services/pager.service';

declare const alasql: any;
declare var $: any;
declare var require: any
const swal = require('sweetalert');

@Component({
  // template: ``,
  templateUrl: './students.list.component.html',
  styles: [`

  /* Table css Start */
  a.del
  {
      background:#d9534f;
      border-radius: 2px; 
      width: 35px;
      height:28px; 
      padding-left:12px;
      line-height:10px;
  }
  
  a.edit
  {
      padding-left:10px;
      background:#337ab7;
      color:#fff;
      border-radius:2px;
      border:none;
  }
  
  tr.row-name
  {
      font-size: 16px;
      color:#448aff;*/
      font-size: 15px;
      color: #307171; /*#26428C*/
     /* background: #64c7e9*/
  }
  
  tr.row-content
  {
      color:#6c7173;
  }
  
  table
  {
      /*border-bottom: 8px solid #448aff;*/
      margin:0 auto;
      width:95%;
  }
  
  td.check
  {
      text-align: center;
  }
  
  .table-striped>tbody>tr:nth-of-type(odd)
  {
      background:#F0F2F2 !important; 
  }
  
  a.btn-danger:hover
  {
      background: #de6c69;
  }
  
  a.btn-danger
  {
      background:#d9534f;  
  }
  
  a.btn-top
  {
      background:#448aff;
      color:#fff;
      border:1px solid #448aff; 
      padding: 7px 10px; 
      border-radius:4px; 
  }
  
  a.btn-top:hover
  {
      text-decoration: none;
      box-shadow:3px 3px 5px #222; 
      transition:box-shadow 0.5s; 
  }
  
  /* Table css End */
  
.fixed_headers td,
.fixed_headers th {
 min-width: 150px;
}
.fixed_headers thead tr {
 display: block;
 position: relative;
}
.fixed_headers tbody {
 display: block;
 overflow-x:hidden;
 overflow-y:scroll;
 width: 100%;
 height: 300px;
}

.clickHead{
 cursor:pointer;
}

animation css */
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
 padding: 8px;
 border-top:1px solid #f5f5f5
}

.addedShadow{
  height:46px;
}
.panel-default>.panel-heading {
 color: #333;
 background-color: #ffff;
 border-color: #ffff;
}
.panel {
 box-shadow: 1px 2px 4px 0px lightgrey;
}
.panel-default {
 border-color: #fdfafa;
}

.panel-body {
  padding: 10px;
}

.customInput{
  border-radius: 5px;
  height: 41px;
  border: 1px solid #ddd;
  box-shadow: none;
}

.pageClickItem {
  cursor:pointer;
}

.primary-mobile{
    background: #337ab7;
    display: inline;
    font-size: 79%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    margin-left: -3px;
    padding: 1px 3px 1px 3px;
}

.sec-mobile{
      background: #6c757d;
      display: inline;
      padding: 1px 3px 1px 3px;
      font-size: 79%;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: .25em;
      margin-left: -3px;
  }


 `],
})
export class studentsListPageComponent {


  public viewSearch: boolean = false;
  public viewMore1: boolean = true;
  public studentList: any = {};
  public studentDetails: any = [];
  public studentInfo: any = [];
  public filterObj: any = {};
  public MemberName: any = "";
  public MemberClass: any = "";
  public MemberSection: any = "";
  public searchPhone: any = "";
  public MemberRfid: any = "";
  public MemberUid: any = "";
  public routeId: any = "";
  public pickupId: any = "";
  public RouteType: any = "";
  public pageJump: number = 0;
  private viewMore: boolean = false;
  public noMoreData: boolean = false;
  counter = 0;
  imeiLogDetails: any = [];;
  imeiNo: any;
  totalRecords: any;
  public noMoredata: boolean;
  public routePageJump: number = 1;

  newExcelObj: any = []
  xlsLoader1: boolean;
  // default value -- it can be anythhing
  public limitOfRecords: number = 25;
  private schoolRoutes: any = [];
  private routePickUpPoints: any = [];
  private selectedRoute: any;
  private selectedPp: any;
  private selectedPpRoute: any;
  private selectedPickupPoint: any;
  //for student count
  public totalCount: any = [];
  public dashboardCount: any = {};
  public selectedFilter: any = "";
  public selectedOrder: any = "";
  public selectedAssNotAss: any = "";
  public selectedImageFilter: any = "";

  //private apiResponseMesage: string;
  private RoutesGlobalFilterObj: any = {};
  public isLoading: boolean; //Loader Var
  public isLoadingExcel: boolean = false;
  public clearRout: boolean = false;
  public clearPPRout: boolean = false;
  public filterStarted: boolean = false;
  private allItems: any;
  public faceAttendance: any = {};
  public OrgClasses: any[];
  public orgSections: any[];
  public values = '';
  // pager object
  pager: any = {};

  // paged items
  pagedItems: any[];

  constructor(private router: Router,
    private apiService: ApiService,
    public authGaurd: authGaurd,
    //private globalUtils: GlobalUtils,
    private pagerService: PagerService) {

    this.isLoading = true;
  }

  ngOnInit() {
    this.authGaurd.checkToken();
    this.setPage(1);
  }


  getAvailableClasses() {
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken')
      }
    }
    this.apiService.getCurrentOrgDetails(apiHeader).then(response => {
      if (response.response) {
        this.OrgClasses = response.response.classLists;
        this.orgSections = response.response.SectionLists;
      }
      if (response.response && response.response.reports && response.response.reports.faceAttendance
        && response.response.reports.faceAttendance.faceAttendance
      ) {
        this.faceAttendance = response.response.reports.faceAttendance.faceAttendance

      }


    });
  }

  setPage(page: number) {

    const parent = this;

    if (page < 1 || page > this.pager.totalPages) {

      return;
    }

    this.viewStudentList(page - 1).then(function (result: any) {
      // get pager object from service

      if (result != undefined) {
        parent.noMoreData = false;

        parent.pagerSetUp(page, result.totalMember);
      } else {

        parent.noMoreData = true;
      }

    })
    //get current page of items -- this should be used when api returns all the data at a time
    // this.pagedItems = this.studentList.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  pagerSetUp(page: number, allItemsCount: number) {
    if (allItemsCount != 0)
      this.pager = this.pagerService.getPager(allItemsCount, page, 25);
    // console.log('totalItems : ',this.pager.totalItems);
    // console.log('currentPage : ',this.pager.currentPage);
    // console.log('pageSize : ',this.pager.pageSize);
    // console.log('totalPages : ',this.pager.totalPages);
    // console.log('startPage : ',this.pager.startPage);
    // console.log('endPage : ',this.pager.endPage);
    // console.log('startIndex : ',this.pager.startIndex);
    // console.log('endIndex : ',this.pager.endIndex);
    // console.log('pages : ',this.pager.pages);
    if (this.pager.totalItems == undefined) {
      this.pager.totalItems = this.allItems;
    }

  }

  passPageNumber() {

  }


  addStudent() {

    this.router.navigate(['/add-student-details', {}]);
  }
  backToDashboard() {

    this.router.navigate(['/student-dashboard', {}]);
  }
  excelUpload() {
    this.router.navigate(['/student-excel-upload', {}]);
  }

  rfidUpload() {
    this.router.navigate(['/rfid-upload']);
  }

  UpdateStudentExcelUpload() {
    this.router.navigate(['/studentInfo-update-upload', {}]);
  }



  onSelectFilter(filterOption: any) {
    this.selectedFilter = filterOption
  }

  onSelectOrder(filterOrder: any) {
    this.selectedOrder = filterOrder
  }
  onSelectAssignedNotAssigned(filterOrder: any) {
    this.selectedAssNotAss = filterOrder
  }
  onSelectimageFilter(filterOrder: any) {
    this.selectedImageFilter = filterOrder
  }
  //selectedAssNotAss
  generateExcel(pageIdx: any) {
    // console.log('pageIdx',pageIdx);

    if (this.filterStarted) {
      this.getApiLoop(pageIdx);
    }


  }

  getApiLoop(pageIdx: any) {
    let imeiNewObj: any = [];
    //this.getAvailableClasses();
    this.isLoadingExcel = true;


    //start code -2029


    // data: {
    //   key: localStorage.getItem('scbToken'),
    //   extra: {
    //     pageJump: pageJump,
    //     orderByDateCreated: -1
    //   },
    //   filter: this.filterObj

    // }

    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          pageJump: pageIdx,
          orderByDateCreated: -1
          //limit:25
        },
        filter: this.filterObj

      }

    }
    pageIdx = pageIdx + 1;
    this.apiService.viewMember(apiHeader).then(response => {
      let studentDats: any = response.response;
      let totalMember: any = response.totalMember;
      let pageSize = 25;
      let totalPages = Math.ceil(totalMember / pageSize);
      //console.log('TO : ',totalPages,' TTTTT ',pageIdx);
      if (pageIdx <= totalPages && studentDats != null) {
        //console.log('iffffffffffffff : ',totalPages,' TTTTT ',pageIdx,'RRRRRRRRR',studentDats);
        (Object.keys(studentDats)).forEach((stu: string) => {
          //console.log('QQQQQ : ',studentDats[stu]['memberInfo']);
          //memberName,memberClass,memberSection
          let route1: any = '';
          let route2: any = '';
          let pickup1: any = '';
          let pickup2: any = '';
          if (studentDats[stu]['memberInfo'] === undefined) {
            route1 = '';
            route2 = '';
            pickup1 = '';
            pickup2 = '';
          } else {

            Object.keys(studentDats[stu]['memberInfo']).forEach((member_key: any) => {

              if (studentDats[stu]['memberInfo'][member_key].routeType === 'pickup') {

                route1 = studentDats[stu]['memberInfo'][member_key].routeName;
                pickup1 = studentDats[stu]['memberInfo'][member_key].pickuppoint;

              } else {

                route2 = studentDats[stu]['memberInfo'][member_key].routeName;
                pickup2 = studentDats[stu]['memberInfo'][member_key].pickuppoint;
              }

            });

          }

          let rfidNo: any = '';
          let secondaryNo: any = '';
          if (studentDats[stu].rfid === null) {
            rfidNo = '';

          } else {

            rfidNo = studentDats[stu].rfid;
          }


          if (studentDats[stu].memberSecondaryMobileNo === null) {
            secondaryNo = '';

          } else {

            secondaryNo = studentDats[stu].memberSecondaryMobileNo;
          }

          let tempObj = {
            // assigned: item.assigned
            "Id": studentDats[stu].memberUID,
            "Name": studentDats[stu].memberName,
            "Class": studentDats[stu].memberClass,
            "Section": studentDats[stu].memberSection,
            "Primary No": studentDats[stu].memberPrimaryMobileNo,
            "Secondary No": secondaryNo,
            "Route-1": route1,
            "Pickup-1": pickup1,

            "Route-2": route2,
            "Pickup-2": pickup2,
            "RFID": rfidNo



          }
          // this.newExcelObj.push(item);
          this.newExcelObj.push(tempObj);


        });
        this.generateExcel(pageIdx);
      } else {
        // console.log('22222222222222222 : ',totalPages,' TTTTT ',pageIdx);
        this.CreateCSV(this.newExcelObj);
      }

    }).catch(error => { this.isLoadingExcel = false; })



    //end code -2029







  }

  CreateCSV(ExcelObj: any) { //console.log(ExcelObj);

    let sheetName = { sheetid: 'StudentList', header: true };

    this.isLoadingExcel = false;
    if (ExcelObj.length > 0) {

      alasql('SELECT INTO XLSX("StudentList.xlsx",?) FROM ?',
        [sheetName, ExcelObj]);
      // this.commonservice.JSONToCSVConvertor(ExcelObj, "Feedback_report", excelTitle, columns);
    }
    setTimeout(() => {

      this.xlsLoader1 = false;
      // console.log('xlsLoader',this.xlsLoader1);
    }, 2000)

  }

  filterFunction(routeDet: any) {
    this.filterStarted = true;
    this.onSelectFilter(this.selectedFilter); //getting selected values from select box filter
    this.onSelectOrder(this.selectedOrder); // getting selected valies from selec box filter
    this.onSelectAssignedNotAssigned(this.selectedAssNotAss);
    //this.pageJump = 0;
    this.onSelectimageFilter(this.selectedImageFilter);

    this.filterObj.MemberName = this.MemberName;
    this.filterObj.MemberClass = this.MemberClass;
    this.filterObj.MemberSection = this.MemberSection;
    this.filterObj.mobileNo = this.searchPhone;
    this.filterObj.MemberRfid = this.MemberRfid;
    this.filterObj.MemberUID = this.MemberUid;


    if (routeDet === "OnlyPP") {

      this.filterObj.routeId = this.selectedPpRoute;
      this.filterObj.memberInfo = true;

      this.selectedPickupPoint = this.selectedPp;
      this.filterObj.pickupId = this.selectedPp;
    } else {

      this.filterObj.routeId = this.selectedRoute;
      this.filterObj.memberInfo = true;

      this.selectedPickupPoint = this.pickupId;
      this.filterObj.pickupId = this.pickupId;
    }
    // this.filterObj.memberInfo = true;

    if (this.selectedOrder == 'asc') {
      this.filterObj.asc = this.selectedFilter;
    } else {
      this.filterObj.asc = '';
    }

    if (this.selectedOrder == 'dsc') {
      this.filterObj.dsc = this.selectedFilter;
    } else {
      this.filterObj.dsc = '';
    }



    if (this.selectedAssNotAss == '1') {

      this.filterObj.RfidAssigned = '1';
    } else if (this.selectedAssNotAss == '2') {

      this.filterObj.RfidAssigned = '2';
    }
    else {

      this.filterObj.RfidAssigned = '';
    }



    if (this.selectedImageFilter == 'image') {

      this.filterObj.imageAssigned = 'image';
    } else if (this.selectedImageFilter == 'noImage') {

      this.filterObj.imageAssigned = 'noImage';
    }
    else {

      this.filterObj.imageAssigned = '';
    }
    this.setPage(1);


  }

  clearFilter() {  //function for clearing all the filters

    this.noMoreData = false;
    this.filterStarted = false;
    this.MemberName = "";
    this.MemberClass = "";
    this.MemberSection = "";
    this.searchPhone = "";
    this.MemberRfid = "";
    this.MemberUid = "";
    this.selectedRoute = "";
    this.selectedPickupPoint = '';
    this.selectedFilter = "";
    this.selectedOrder = "";
    this.selectedAssNotAss = "";
    this.selectedImageFilter = "";
    this.pickupId = "";
    this.clearPPRout = true;
    this.filterObj = {}; //cleaing fillter object that is used in the  function definition
    this.RouteType = ""
    this.RoutesGlobalFilterObj = {};
    this.viewMore1 = true;
    this.clearRout = true;
    setTimeout(() => { this.clearRout = false; }, 100);
    this.routePickUpPoints = [];

    this.viewStudentList(0);

  }

  // viewLatestStudentList() { // This will show latest Updated Data in Student Table List
  //   this.apiService.viewMember({
  //     data: {
  //       key: localStorage.getItem('scbToken'),
  //       extra: {
  //         pageJump: this.pageJump,
  //         orderByDateCreated: -1
  //       },
  //       filter: this.filterObj

  //     }
  //   }).then(response => {
  //     let result = response;
  //     if (result.status == "success") {

  //         this.studentList = result.response; // here response is API output
  //         this.studentDetails = $.map(this.studentList, function (value: any, index: any) {
  //           return [value]
  //         });
  //       }

  //     this.studentInfo = this.studentDetails[0];

  //   }).catch(error => {

  //   });
  // }




  viewStudentList(pageJump: number) {
    this.getAvailableClasses();

    this.isLoading = true;

    if (pageJump === 25) {

      this.filterObj = {};
      this.filterObj.memberinfo = true;
      pageJump = 0;

      this.MemberName = "";
      this.MemberClass = "";
      this.MemberSection = "";
      this.searchPhone = "";
      this.MemberRfid = "";
      this.MemberUid = "";
      this.selectedRoute = "";
      this.selectedPickupPoint = '';
      this.selectedFilter = "";
      this.selectedOrder = "";
      this.selectedAssNotAss = "";
      this.selectedImageFilter = "";
      this.pickupId = "";
      this.clearPPRout = true;
      this.filterObj = {}; //cleaing fillter object that is used in the viewStudentList() function definition
      this.RouteType = ""
      this.RoutesGlobalFilterObj = {};

    }
    this.filterObj.memberinfo = true;

    let result: any;
    return this.apiService.viewMember({
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          pageJump: pageJump,
          orderByDateCreated: -1
        },
        filter: this.filterObj

      }
    }).then(response => {
      result = response;
      if (result.status == "success") {
        this.isLoading = false;
        if (result.response != null && result.response != undefined && result.response != '') {
          this.studentList = result.response; // here response is API output
          this.totalCount = result.totalMember;
          this.allItems = result.totalMember;

          this.studentDetails = $.map(this.studentList, function (value: any, index: any) {
            return [value]
          });

          return result
        } else {
          this.totalCount = null;
          this.allItems = null;
        }

      }

      this.studentInfo = this.studentDetails[0];

    })

      .catch(error => {
        console.log('Error while logging in', error);
      });

  }

  passIdOnClik(memberId: any) {

    this.apiService.viewMember({
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          pageJump: 0
        },
        filter: {
          memberId: memberId
        }
      }
    })
    this.router.navigate(['/edit-student-details', memberId]);

  }




  deleteStudent(memberId: any) {
    const parent = this;
    let memObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          memberId: memberId
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'The Selected Student Will be removed',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.deleteMember(memObj).then(response => {
          if (response) {


            parent.viewStudentList(25);
            //  this.noMoreData=false;



            this.router.navigateByUrl('/school-students-list');

          }
        }).catch(error => { });
      } else {
      }
    });
  }

  // Filter Function For Route



  filterRouteFunction() {
    this.RoutesGlobalFilterObj = {};

    if (this.RouteType) Reflect.set(this.RoutesGlobalFilterObj, 'routeType', this.RouteType);
    this.getRoutes();

  }

  getRoutes() {
    let parent: any = this;
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
      }
    };
    if (Object.keys(this.RoutesGlobalFilterObj).length) {
      routeObj['data']['filter'] = {};
      routeObj['data']['filter'] = this.RoutesGlobalFilterObj;
    }
    this.apiService.getRoutes(routeObj).then(response => {
      var parent: any = this;
      if (response) {
        this.schoolRoutes = response.response;

      };
    }).catch(error => { });
  }

  setSelectedRoute(selectedRoute: any) {
    if (selectedRoute.length) {
      this.selectedRoute = selectedRoute[0].routeId; //carrying forward  previously declared global var strategy.

      this.filterFunction(selectedRoute);
      this.populatePickUpPoints(this.selectedRoute);
    }
  }
  showhide(event: any) {
    //this.values += event.target.value + ' | ';
    //alert(event.target.value);
    if (event.target.value === '') {
      this.viewMore1 = true;
    } else {
      this.viewMore1 = false;
    }
  }
  setSelectedPp(selectedPp: any) {
    if (selectedPp.length) {

      this.selectedPp = selectedPp[0].pickupId; //carrying forward  previously declared global var strategy.

      this.selectedPpRoute = selectedPp[0].routeId;
      this.filterFunction("OnlyPP");
      // this.populatePickUpPoints(this.selectedRoute);
    }

  }
  populateRouteStoppages() {
    this.routePickUpPoints = [];
    this.populatePickUpPoints(this.selectedRoute);
  }

  populatePickUpPoints(selectedRoute: any) {
    var stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          "routeId": this.selectedRoute,
        }
      }
    }
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      this.routePickUpPoints = response.response.pickuppoints;

    });
  }

  sortTable(n: any) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("mytable");
    switching = true;
    //Set the sorting direction to ascending:
    dir = "asc";
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
      //start by saying: no switching is done:
      switching = false;
      rows = table.getElementsByTagName("TR");
      /*Loop through all table rows (except the
      first, which contains table headers):*/
      for (i = 1; i < (rows.length - 1); i++) {
        //start by saying there should be no switching:
        shouldSwitch = false;
        /*Get the two elements you want to compare,
        one from current row and one from the next:*/
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /*check if the two rows should switch place,
        based on the direction, asc or desc:*/
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /*If a switch has been marked, make the switch
        and mark that a switch has been done:*/
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        //Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /*If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again.*/
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

}
