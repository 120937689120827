import {
  Component, ElementRef, AfterViewInit, AfterContentInit,
  OnDestroy, ViewChild, Input, Output, NgZone, HostListener, EventEmitter
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  animation,
} from '@angular/animations';
import { AppComponent } from './../../../app.component';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { decodeAddress } from './../../../services/decodeAddress.service';
import { authGaurd } from './../../../services/auth-guard.service';
import { URLSearchParams } from '@angular/http';
import { ActivatedRoute, Params, NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GlobalUtils } from './../../../services/global-utils';
import { StorageService } from './../../../services/storage.service';
import { COM } from './../../../services/com.services';
import { async } from 'rxjs/internal/scheduler/async';
import { map } from 'rxjs/operators';
import { googleMapService } from '../../../services/googleMapService';
import { KDTreeService } from "./../../../services/kdtree.service";
import { analyzeAndValidateNgModules, ThrowStmt } from '@angular/compiler';
import { copyConfig } from '@angular/router/src/config';
import { ExcelService } from './../../../services/excel.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;
declare var google: any;
declare var MarkerWithLabel: any;
let routeDetails: any = [];
const swal = require('sweetalert');

@Component({

  styles: [`

.loader {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #757575;
    width: 40px;
    height: 40px;
    margin-left: 300px;
    margin-top: 60px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.routeFilter {
    color: #fff;
    font-size: 17px;
    margin: 5px;
    width: 35px;
    background: #2ebdd4;
    border-radius: 20px;
    height: 34px;
    text-align: center;
    line-height: 35px;
    box-shadow: 0px 2px 5px rgba(94,94,94,0.68);
    cursor:pointer;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

/*animation css */
.grow {
  transition: all .2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
}
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
}
span.lable-tit {
  font-weight: 500;
}
.modal-footer button {
   margin: 2px 2px 2px 2px;
}
.custom {
  background: #fdfcfc;
  border: 1px solid #ece9e9;
  height:34px;
}

.effects{
  box-shadow: 2px -1px 5px 0 rgba(146, 137, 137, 0.26);
}
.effects:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
.IntroVideoLink{
   color: #8C96A9;
   text-decoration: none;
   outline: none;
   font-size:13px;
}
.createNewRoute{
   font-size: 2.27rem;
   font-weight: 400;
   width: 35px;
   border-radius: 50%;
   color: white;
   line-height: 35px;
   text-align: center;
   height: 35px;
   margin: 4px auto;
   box-shadow: 0px 2px 5px rgba(94, 94, 94, 0.68);
   cursor: pointer;
   background: #00BCD4;
   z-index: 10;
   margin-right: 5px;
}
span {
  line-height: 14px;
}
.list-item-container{
   display: inline-block;
   list-style: none;
   position: relative;
   width: 100%;
   border-bottom: 1px solid #e6e6e6;
   min-height: 50px;
   padding: 2%;
}
.p-28percent{
  padding-left: 18%;
}
.bg-secondary{
  background:#f6f6f6;
  border-bottom:1px solid #e0e0e0;
}
.bg-secondary-border-bottom-0{
  background:#f6f6f6;
}

.routeName{
  font-size: 17px;
}
.routeTiming{
  font-size:13px;
}
.routeDriverDetails{
  font-size: 14px;
  font-weight: 400;
}
.routeStartLocation{
  font-size: 14px;
  font-weight: 400;
}
.route-user-avatar{
  margin-left: -22px;
}
.route-heading-tabs{
  min-height:45px;
}
.list-heading{
  font-size: 30px;
  font-weight: 400;
}

.assistanceText :hover{ 
  color:#77839a;
}

.supClass {
  background: #337ab7;
  display: inline;
  padding: .2em .3em;
  font-size: 79%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  margin-left: 4px;
  top: -0 em;

  }

h5{
  margin: 12px;
}
.modal-dialog {
  width: 900px;
 
}

#dynamicContent {
  position: absolute;
  top: 51px;
  left: 8%;
  min-width: 85%;
  max-width: 85%;
  z-index: 99;
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 1.0);
  border: 1px solid #ccc;
  height: 66px;
}
// popover css
.shadow	{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
}
.text-grey	{color: #b8c2cc;}
.cursor-pointer	{cursor: pointer;}
.my-card{
  transition: 1s;
  margin: 6px 0px;
  width: 100%;
  padding: 6px 0px;
}
.border-bottom{
  border-bottom:1px solid #d4d4d4
}
// card css
.card-footer{
 
}
.upcoming-route{
  height: 70px; width: 70px; background-color: #ffffff;      border-style: solid;
    border-color: #dd9c9c;border-radius:100rem;

}
.mt-4{
  margin-top:10px;
}

.route-info-card{
  height: 70px; width: 70px; background-color: #ffffff;  border-radius:100rem;     border-style: solid;
  border-color: #dadada;
}
.route-time-failure{
position: fixed;top: 100px;margin-left: 341px;background: white;width: 40%;  border-style: solid;
  border-color: #e8e8e8;
}

   `],

  templateUrl: './school.routes.list.html',

})

export class schoolRouteListComponent {

  public type: string;

  public popoveranim: string = 'false';
  public routes: any[];
  public routeStoppages: any[];

  public clickedRouteId: any;
  public clickedStoppageId: any;
  public pickupDetails: any;
  public routeInfoDetails: any;
  public stoppageInfo: any;
  public showTimeline: boolean = false;
  public editRouteForm: boolean = false;
  public showStoppageUploadform: boolean = false;
  public showStoppageForm: boolean = false;
  public pickupRefMarkers: any[] = [];
  public showEditPickupPointForm: boolean = false;
  public showRouteEditForm: boolean = false;
  public addRouteForm: boolean = false;
  public filterDisplay = false;
  public _map: any;
  // private _map: any;
  private routeStartPoint: any = '';
  private geocodedLatLng: any;
  public routeFormDisplay: boolean = true;
  public showingStoppageDetails: boolean = false;
  private markerDraggedLocation: string;
  private markerDraggedLatLng: any;
  private geoRadius: any;
  marked = false;
  //private showDialog: boolean;
  private userOperationData: any = {}; ///holding temporary user data , in case of dialog shown.

  // private showSuccessDialog: boolean;
  // private showFailureDialog: boolean;
  // private showDialogLoader:boolean  = false;
  // private showUploadSuccess:boolean = false;
  // private showUploadFailure:boolean = false;
  private orgVehicles: any = [];
  private vehicleName: any = {};
  private pickupPointMarkers: any = [];
  private mapBounds: any;
  private pageJump: number = 0;
  private isScrolled: boolean = false;
  private noMoredata: boolean;

  private _geoRadius: any;
  private _geoFillColor: any;
  //  private pickupDetails:boolean = true;

  public showSettingsOverlay: boolean = false;
  public tempArr: any = [];
  private viewFilter: boolean = false;
  private viewallDownload: boolean = false;
  public RouteName: any = "";
  public RouteTag: any = "";
  public RouteType: any = "";
  public globalFilterObj: any = {};
  private routeSub: Subscription;
  private bypassedRouteId: any;
  private RoutesGlobalFilterObj: any = {}
  public isLoading: boolean; //Loader Var

  public studentMemberID: string = '';
  //private globalFilterObj:any = {}
  public controlStudentModule: boolean;
  private routeCreateMarker: any;
  private testLang: any;
  public studentInfo: any;
  // public mapview: string = "pickuponly";

  private globalGeocoder = new google.maps.Geocoder();
  public EndDateString: any;
  public StartDateString: any;
  // public haltonlyReportDatas: any = [];
  // public pickupointDatas: any = [];
  // public haltwithpickupPoint: any = {
  //   haltonlyReportData: this.haltonlyReportDatas,
  //   pickupoint: this.pickupointDatas,
  // };
  public popover: boolean;
  haltandpickuppoints: any;
  disabledbtnfordaterange: string;
  disabledbtn: string;
  endDate: any;
  startDate: any;
  public routeStoppagesExcelData: any = [];
  public routeStoppagesProcessData: any = {};
  public dateWiseExlStatus: any = {};
  public showExcelDownload: boolean = false;

  public iframe_head_rname: string = 'routename';
  public iframe_head_vehname: string = 'vehname';
  public cameraModuleRender: boolean = false;

  public routeInfo: any = {};
  stopingpointCount: number;
  contentHeight: number = 100;
  mapMarker: any = [];
  showProgress: boolean;
  dateArr: any = [];
  singledate: any = 1;
  haltFilter: boolean;
  showButton: boolean;
  menuList: boolean;
  public popoverObj: any = {};
  public list: boolean = false;
  showIframe: boolean = false;
  iframeUrl: string;
  cleartimeout_Drag: any;
  rName: any;
  rTag: any;
  rType: any;
  revertType: any;
  public haltLogStopageData: any = [];
  routePickup_points: any;
  fail: any;
  routeTimeFailure: any;
  misMatchData: any;
  submitted: boolean;
  endTimeDiff: string = '';
  startTimeDiff: string = '';
  totalStartTime: any;
  routeStartTime: any;
  routeEndTime: any;
  modified_time_route: string;
  modified_time_bothRoute: string;
  result: string;
  resultEndTime: string;
  valueRoute: any;
  routeIDDetails: any;
  routeDetails: any;
  loading = false


  constructor(private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private decodeAddress: decodeAddress,
    public authGaurd: authGaurd,
    private globalUtils: GlobalUtils,
    private globalservice: globalService,
    private KDTree: KDTreeService,
    private storage: StorageService,
    private com: COM,
    private excel: ExcelService,
    private formBuilder: FormBuilder
  ) {
    //called first time before the ngOnInit()
    // let draggable = 
    // let injectableScript = 
    this.cameraModuleRender = this.storage.get('video');
    this.dateArr = [];
    this.isLoading = true;
    this.com.comAction.subscribe((e: any) => {
      if (e.type == 'haltLogReport') {
        //  this.haltLogStopageData = e.data;

        if (e.initialCall) {
          this.dateWiseExlStatus = {};
          this.routeStoppagesProcessData = {};
        }
        this.getReportData(e.data, e.date);
        this.dateWiseExlStatus[e.date] = false;
        // console.log(this.dateWiseExlStatus);
      }
    })
    $("#routetypeSearch").prop("selectedIndex", 0);

    this.com.comAction.subscribe((res: any) => {

      if (res.label == 'addStudent') {
        this.studentControl(res.payload);
      }

    })
    this.singledate = true;
    this.routeInfo = '';
    this.storage.set({
      'HaltLog': false
    })

    this.storage.set({
      'kind_of_report': ''
    })

    this.storage.set({ 'editpickuppoint': false })

    this.stopingpointCount = 0; this.showProgress = false;

    this.popoverObj = {
      'position': 'absolute',
      'top': '101px',
      'z-index': 999,
      'width': '20%',
      'text-align': 'center',
      'background': 'white',
      'box-shadow': '0 4px 8px 0 rgba(0,0,0,0.12),0 2px 4px 0 rgba(0,0,0,0.08);'
    }

  }
  @Output() pickupHaltEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideEditRouteForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Output() editRoute: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {

    this.showExcelDownload = false;
    //   let navigationFrom = this.globalservice.getData().navigationFrom; //console.log(navigationFrom);
    //   this.globalservice.setData({
    //     'currentRouteID':''
    //   });

    //   if(navigationFrom!='editStudent'){
    //       this.globalservice.setData({
    //           'sudentID':'',
    //           'studentName':'',
    //           'newRouteName':''

    //     });
    // }


    // this.dateArr=["2019-01-07","2019-01-08","2019-01-09"]

    this.authGaurd.checkToken();
    this.getRoutes(true);
    this.getOrgVehicles();
    this.routeSub = this.route.params.subscribe((params: Params): void => {
      // console.log(params['id']);
      this.bypassedRouteId = params['id'];
      // console.log(this.bypassedRouteId);
      // console.log(this.routeInfo.routeId);

      if (this.bypassedRouteId) {
        setTimeout(() => { this.showRouteTimeline(this.bypassedRouteId, false); }, 1000);
      }


    });
    $("#routetypeSearch").prop("selectedIndex", 0);


    let studentId = this.storage.get('sudentID');
    let selectedRouteId = this.storage.get('selectedRouteId');
    let navigateType = this.storage.get('navigateType');

    if ((selectedRouteId && studentId) && navigateType == 'routeList') {

      //  this.showingAddRouteForm();
      this.studentMemberID = studentId;
      setTimeout(() => { this.showRouteTimeline(selectedRouteId, false); }, 500);
      //console.log('--'+studentId+"----");

    }

    this.routeInfo = this.storage.get('routeInfo');
    // console.log(this.routeInfo)
    // const daylist = this.getDaysArray(new Date("2018-05-01"),new Date("2018-05-03"));
    // daylist.map((v)=>{
    //   // console.log(v.toISOString().slice(0,10))
    //   this.dateArr.push(v);
    // })
    // console.log(daylist);
    // this.notifyFilterChanges();
  }
  getNearestLocation(latlng: any, stopedLocations: any) {
    return new Promise(async (res, rej) => {

      let distance = 0, data: any = [], i = 0, firstFlag = false, dupData: any = [];
      let keys = Object.keys(stopedLocations);
      for (let k = 0; k < keys.length; k++) {
        let location = stopedLocations[k]['location'];
        if ((location['lat'] != null && location['lat'] != NaN) && (location['lng'] != null && location['lng'] != NaN)) {
          let dis = this.KDTree.calcCrow(latlng['lat'], latlng['lng'], location['lat'], location['lng']);

          //   distance[dis+'']= objcect[item]
          dis = Math.round(dis);
          if (dis <= 55) {// distance changed from 50 to 55
            console.log(dis)
            try {
              if (stopedLocations[k]['gpsTimeStamp']) {
                let gpsTs = stopedLocations[k]['gpsTimeStamp'].split('-');
                let convertedTs = `${gpsTs[1]}/${gpsTs[0]}/${gpsTs[2]}`;
                // console.log(location['lat'],location['lng']);
                // console.log(latlng['lat'], latlng['lng'],'Dis ---> ',dis);
                let d = new Date(convertedTs);
                let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
                if (stopedLocations[k]['speed'] <= 5) data.push(time);
                if (stopedLocations[k]['speed'] <= 5) {
                  let dt = new Date(convertedTs).getTime();
                  dupData.push(dt);

                }

              }
            } catch (e) {
              console.log('err', e)
            }

          }
          // if (i == 0) {
          //   distance = dis;
          //   firstFlag = true;
          //   data[0] = stopedLocations[k];
          // }
          // else {
          //   if (dis <= distance) {
          //     distance = dis;
          //     if (firstFlag) data[0] = stopedLocations[k];
          //     else data.push(stopedLocations[k]);
          //     firstFlag = false;
          //   }
          // }
          i++;
        }
        // });
      }

      if (dupData.length > 0) {
        dupData = dupData.sort((a: any, b: any) => a - b);
        data = dupData.map((ts: any) => {
          let d = new Date(ts);
          let time = this.pad(d.getHours()) + ':' + this.pad(d.getMinutes()) + ':' + this.pad(d.getSeconds());
          return time;
        })
      }
      res(data);
      console.log('Data ', data);
    });
  }

  sleep(num: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, Number(num));
    });
  }

  pad(num: number) {
    return num > 9 ? num : '0' + num;
  }

  timestampToDate(timestamp: any, type = 'YMD', splited = '/') {
    const strl = (timestamp + '').length;
    const tms = strl > 10 ? timestamp : timestamp * 1000;
    const d = new Date(tms);
    let temp;
    // console.log('timestamp',timestamp)
    const h = this.pad(d.getHours());
    const m = this.pad(d.getMinutes());
    const s = this.pad(d.getSeconds());
    if (type == 'DMY') {
      temp =
        this.pad(d.getDate()) +
        splited +
        this.pad(d.getMonth() + 1) +
        splited +
        d.getFullYear();
    } else if (type == 'DMY H:M:S') {
      temp =
        this.pad(d.getDate()) + splited +
        this.pad(d.getMonth() + 1) + splited +
        d.getFullYear() + splited +
        h + splited + m + splited + s;
    }

    return temp;
  }

  async getReportData(data: any, reportDate: any) {
    this.showExcelDownload = false;
    this.routeStoppagesExcelData = [];
    let stopedLocations: any = [];
    this.routeStoppagesProcessData[reportDate] = [];
    let reportDateStartTs: any = 0;
    let reportDateEndTs: any = new Date().getTime()
    if (reportDate) {
      reportDateStartTs = new Date(new Date(reportDate).setHours(0, 0, 0)).getTime();
      reportDateEndTs = new Date(new Date(reportDate).setHours(23, 59, 59)).getTime();
    }
    let startPP = this.routeStoppages['pickuppoints'][0]['expectedTime'];
    let getTimsData = (gps: string) => {
      // let year = new Date().getFullYear() + ''; //.slice(0,2); 
      let date = gps.split(' ')[0].split('-').reverse().join('-');
      let dates = date.split('-');
      // dates[0] = year;
      if (dates[0].length === 2) dates[0] = '20' + dates[0];
      return new Date(dates.join('-') + ' ' + startPP).getTime();
    }
    for (let key in data) {

      let dataLatLngConversion: any = [];
      data[key].map((item: any) => {
        let itm = item['location'];
        item['lat'] = parseFloat(itm['lat']);
        item['lng'] = parseFloat(itm['lng']);
        let startTMS = getTimsData(item['gpsTimeStamp']);
        if ((item['unicTimeStamp']) > startTMS) {
          dataLatLngConversion.push(item);
        }
      })
      stopedLocations = [...stopedLocations, ...dataLatLngConversion];
    }

    let i = 1;
    // for (let pp in this.routeStoppages['pickuppoints']) {
    stopedLocations = stopedLocations.filter((item: any) => {
      if ((reportDateStartTs < item['unicTimeStamp']) && (item['unicTimeStamp'] < reportDateEndTs)) {
        return item;
      }
    })

    for (let pp = 0; pp < this.routeStoppages['pickuppoints'].length; pp++) {
      let ppLatLng = this.routeStoppages['pickuppoints'][pp]['pickuppointLocation'];
      let location = { lat: parseFloat(ppLatLng['lat']), lng: parseFloat(ppLatLng['lng']) };

      let nearLoca: any = await this.getNearestLocation(location, stopedLocations);
      // let JsonData: any = nearLoca.reverse();
      console.log(nearLoca)
      let lng = nearLoca.length;// > 3 ? 3 : nearLoca.length;
      let closestTime = '';
      let curETA = this.routeStoppages['pickuppoints'][pp]['expectedTime'] + ":00";
      if (lng > 0) {
        let convertedArray = nearLoca.map((timeString: any) => parseFloat(timeString.replace(/:/g, '')));
        let EtaTime = parseFloat(curETA.replace(/:/g, ''));
        closestTime = convertedArray.reduce(function (prev: any, curr: any) {
          return (Math.abs(curr - EtaTime) < Math.abs(prev - EtaTime) ? curr : prev);
        });
      }
      // const diffDays = (date:number, eta:number) => Math.ceil(((date - eta)/1000)/60);
      let tempObj = {};
      tempObj = {
        "S.No": i,
        "Date": reportDate,
        "Pickup Point Name ": this.routeStoppages['pickuppoints'][pp]['pickuppoint'],
        "Expected Time": this.routeStoppages['pickuppoints'][pp]['expectedTime'],
        // "Pikcup Co-ordinates": location['lat'] + ',' + location['lng'],


      };
      if (closestTime !== '') {
        let formattedTime = closestTime.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ':');
        tempObj['Reached Time'] = formattedTime;
        tempObj['Difference'] = this.getTimeDuration(this.routeStoppages['pickuppoints'][pp]['expectedTime'] + ":00", formattedTime);
        tempObj['Status'] = this.getTimeStatus(this.routeStoppages['pickuppoints'][pp]['expectedTime'] + ":00", formattedTime);
      } else {
        tempObj['Reached Time'] = '';
        tempObj['Difference'] = '';
        tempObj['Status'] = '';
      }

      i++;
      // this.routeStoppagesExcelData.push(tempObj);
      if (this.routeStoppagesProcessData[reportDate]) this.routeStoppagesProcessData[reportDate] = [...this.routeStoppagesProcessData[reportDate], tempObj];
      else this.routeStoppagesProcessData[reportDate] = [tempObj];
    }
    this.dateWiseExlStatus[reportDate] = true;
    // await this.sleep(3000);
    let checkAllDates = true;

    Object.keys(this.dateWiseExlStatus).map((key) => {
      if (!this.dateWiseExlStatus[key]) {
        checkAllDates = false;
        return;
      }
    });

    if (checkAllDates) this.showExcelDownload = true;
    // console.log('routeStoppagesExcelData',this.routeStoppagesExcelData)
    //routeStoppagesExcelData
  }
  isAfterComparisonTime(time: any, comparisonHour: any, comparisonMinute: any, comparisonSecond: any) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const comparisonTimeInSeconds = comparisonHour * 3600 + comparisonMinute * 60 + comparisonSecond;

    const currentTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
    return currentTimeInSeconds > comparisonTimeInSeconds;
  }
  getTimeDuration(EtaTime: any, GpsTime: any) {
    let today = new Date();
    let startTime: any = new Date(today.toDateString() + " " + EtaTime);
    let endTime: any = new Date(today.toDateString() + " " + GpsTime);
    let minutesDifference = Math.floor((endTime - startTime) / (1000 * 60));
    return Math.abs(minutesDifference);
  }

  getTimeStatus(EtaTime: any, GpsTime: any) {
    let splitEtaTime = EtaTime.split(":");
    let splitGpsTime = GpsTime.split(":");
    let genEtaTime = new Date();
    let genGpsTime = new Date();
    genEtaTime.setHours(splitEtaTime[0], splitEtaTime[1], 0, 0);
    genGpsTime.setHours(splitGpsTime[0], splitGpsTime[1], 0, 0);
    let retStatus = '';
    if (genEtaTime.getTime() === genGpsTime.getTime()) {
      retStatus = 'Ontime';
    } else if (genEtaTime.getTime() < genGpsTime.getTime()) {
      retStatus = 'Delay';
    } else if (genEtaTime.getTime() > genGpsTime.getTime()) {
      retStatus = 'Earlier';
    }
    return retStatus;
  }
  excelDownloadStoppageData() {
    this.routeStoppagesExcelData = [];
    console.log(this.routeStoppagesExcelData)
    if (Object.keys(this.routeStoppagesProcessData).length > 0) {
      let index = 1;
      let exlDataKey = Object.keys(this.routeStoppagesProcessData).sort((a: any, b: any) => new Date(a).getTime() - new Date(b).getTime())
      for (let r = 0; r < exlDataKey.length; r++) {
        let key = exlDataKey[r];
        let dateWiseData = this.routeStoppagesProcessData[key];
        for (let d = 0; d < dateWiseData.length; d++) {
          dateWiseData[d]['S.No'] = index;
          this.routeStoppagesExcelData.push(dateWiseData[d]);
          index++;
        }
      }

      // Object.keys(this.routeStoppagesProcessData).map((key) => {
      //   this.routeStoppagesExcelData = [...this.routeStoppagesExcelData, ...this.routeStoppagesProcessData[key]];
      // });
      // this.routeStoppagesExcelData = this.routeStoppagesExcelData.map((item: any, index: any) => {
      //   let val = { ...item };
      //   val['S.No'] = index + 1;
      //   return val;
      // })
    }
    let fileName = this.routeStoppages['routeName'] + '_route_pickup_details_' + this.timestampToDate(new Date().getTime(), 'DMY H:M:S', '_');
    if (this.routeStoppagesExcelData.length > 0) {
      this.excel.exportExcel(this.routeStoppagesExcelData, fileName);
    }
    this.showExcelDownload = false;
    this.routeStoppagesExcelData = [];
    this.routeStoppagesProcessData = {};
    this.dateWiseExlStatus = {};
    // console.log(this.routeStoppages)
    // console.log(fileName)
  }



  setValueOnService(data: any) {
    this.storage.set({ 'kind_of_report': data })
  }
  checkFlagValue = (event: any) => {
    // console.log(event);
    this.routeInfo = this.storage.get('routeInfo');
    this.storage.set({ 'HaltLog': event.HaltLog })
    let getFlag = this.storage.get('HaltLog');
    console.log('HaltLog')
    if (getFlag === true) {
      this.contentHeight = 80;
      this.filterDisplay = true;
    }
  }

  // styleObj=(val:number)=>{
  //   return {
  //     'left':3+'px',
  //     'height':val+'vh'
  //   }
  // }

  clearAll = (eve: any) => {
    this.contentHeight = 100;
    this.storage.set({ 'HaltLog': false });
    this.storage.remove('routeInfoStartDate');
    this.storage.remove('routeInfoEndDate');
    this.storage.remove('routeInfo');
    this.filterDisplay = false;
    this.getBack(eve);
    this.popover = false;
    this.haltFilter = false;



  }

  getTimeStamp(input: any) {
    var parts = input.trim().split(' ');
    var date = parts[0].split('-');
    var time = (parts[1] ? parts[1] : '00:00:00').split(':');

    // NOTE:: Month: 0 = January - 11 = December.
    var d = new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2]);
    return d.getTime() / 1000;
  }

  getDaysArray = (start: any, end: any) => {
    for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };
  emmitedvalueHandling(e: any) {
    // this.storage.set({ 'flag': 'route' })
    // console.log(e);
    this.dateArr = [];
    let dateobj = e;

    const daylist = this.getDaysArray(new Date(dateobj.startDate), new Date(dateobj.endDate));
    daylist.map((v) => {
      let d = v.toISOString().slice(0, 10)
      this.dateArr.push(d);

    })


    if (dateobj.startDate != null) {
      this.StartDateString = dateobj.startDate;
      this.storage.set({ 'routeInfoStartDate': dateobj.startDate })

      this.startDate = this.getTimeStamp(dateobj.startDate);
    }
    if (dateobj.endDate != null) {
      this.EndDateString = dateobj.endDate;
      this.storage.set({ 'routeInfoEndDate': dateobj.endDate })
      this.endDate = this.getTimeStamp(dateobj.endDate);
    }


  }
  datelength(num: number) {
    let str = num.toString();
    let len = str.length;
    return len;
  }
  datediffer(startDate: any, endDate: any) {
    let timedif;
    if (this.datelength(startDate) === 13 && this.datelength(endDate) === 13) {
      timedif = Math.abs(startDate - endDate);
    } else {
      timedif = Math.abs(startDate * 1000 - endDate * 1000);
    }
    // console.log(timedif);
    let dayDifference = Math.ceil(timedif / (1000 * 3600 * 24));
    return dayDifference;
  }


  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.storage.set({
      'routeInfo': '',
    })
    this.storage.remove('routeInfoEndDate')
    this.storage.remove('routeInfoStartDate')
    this.storage.set({
      'HaltLog': ''
    })
    this.showProgress = false;
    clearTimeout(this.cleartimeout_Drag); //clear timeout for draggable on destroy of componenet
  }


  initMap(): void {
    var mapOptions = {
      scrollwheel: true,
      zoom: 14,
      center: new google.maps.LatLng(28.7041, 77.1025),
      disableDefaultUI: true,
      zoomControl: true,
      //disableDefaultUI: true //disables default controls
    };
    this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);

  }


  // filterFunction() {
  //   this.RoutesGlobalFilterObj = {};
  //   if (this.RouteName) {
  //     Reflect.set(this.RoutesGlobalFilterObj, 'routeName', this.RouteName);
  //   }

  //   if (this.RouteTag) Reflect.set(this.RoutesGlobalFilterObj, 'tags', this.RouteTag);
  //   if (this.RouteType) Reflect.set(this.RoutesGlobalFilterObj, 'routeType', this.RouteType);
  //   this.getRoutes(true);

  // }
  filterFunction() {

    this.RoutesGlobalFilterObj = {};
    if (this.rName) {
      Reflect.set(this.RoutesGlobalFilterObj, 'routeName', this.rName);
    }

    if (this.rTag) Reflect.set(this.RoutesGlobalFilterObj, 'tags', this.rTag);
    if (this.rType) Reflect.set(this.RoutesGlobalFilterObj, 'routeType', this.rType);
    if (this.revertType) Reflect.set(this.RoutesGlobalFilterObj, 'revertFlag', 1);
    this.getRoutes(true);

  }
  toggleVisibility(e: any) {
    this.marked = e.target.checked;

    if (this.marked) {
      Reflect.set(this.RoutesGlobalFilterObj, 'revertFlag', 1);
      this.getRoutes(true);
    } else {
      this.RoutesGlobalFilterObj = {};
      this.getRoutes(true);
    }
  }


  //Get all org active routes.
  getRoutes(iscallback: boolean): void {

    this.showButton = false; this.list = false;
    this.menuList = false;
    if (!this.pageJump) this.isLoading = true; //Loader Var
    // setTimeout(()=>{
    //   this.isLoading = false;
    // },3000);
    let parent: any = this;
    if (iscallback) this.routes = [];
    this.tempArr = [];
    let routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          pageJump: this.pageJump
        }
      }
    };

    if (Object.keys(this.RoutesGlobalFilterObj).length) {
      routeObj['data']['filter'] = {};
      routeObj['data']['filter'] = this.RoutesGlobalFilterObj;
    }
    this.apiService.getRoutes(routeObj).then(response => {
      if (!this.isScrolled) this.initMap(); // initiliaze map.
      if (response) {
        if (response != '' && response != undefined && response != null) {
          if (response.response) {
            for (let route of response.response) {
              this.routes.push(route);
            }
            this.noMoredata = false;
          } else {
            this.noMoredata = true;
            this.pageJump = 0;
          }
        }
      };
      this.isLoading = false; //Loader Var
    }).catch(error => { });
  }

  //Get all org vehicles.
  getOrgVehicles(): void {
    var apiHeader = {
      "data": {
        "key": localStorage.getItem('scbToken')
      }
    }
    this.apiService.getOrgVehicles(apiHeader).then(response => {
      for (var index in response.response) {
        this.orgVehicles.push(response.response[index]);
      }
    }).catch(error => { });
  }



  //Showing route stoppages on click of route name.

  showRouteTimeline(routeParam: number, iscallback: boolean): void {
    this.showButton = true;
    // this.haltFilter = true;
    //this.bypassedRouteId = this.clickedRouteId

    let getLatestRouteID = this.storage.get('currentRouteID');

    if (routeParam) {
      this.clickedRouteId = routeParam;
    } else {
      this.clickedRouteId = getLatestRouteID;
    }

    this.pickupPointMarkers = [];
    //TODO: Get a better approach for filter Obj.
    var mCount = 0;
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          routeId: this.clickedRouteId,
          pickuppoint: this.globalFilterObj.pickUpPoint
        }
      }
    };
    if (this.showSettingsOverlay) this.hideSettingsOverlay();
    this.mapBounds = new google.maps.LatLngBounds();
    this.apiService.getRouteStoppages(routeObj).then(response => {

      if (response.status == 'success') {
        this.routeStoppages = [];
        this.globalFilterObj = {};
        this.routeStoppages = response.response;
        this.storage.set({ 'routePickup_points': this.routeStoppages });

        this.showExcelDownload = false;
        this.routeStoppagesExcelData = [];
        if (!iscallback) {
          this.clickedRouteId = routeParam;
          //Plotting stoppage location on map.
          var mCount = 0;
          if (this.routeStoppages['pickuppoints']) {
            let routestopees = [];
            for (var val of this.routeStoppages['pickuppoints']) {
              let mlatLng = new google.maps.LatLng(val.pickuppointLocation.lat, val.pickuppointLocation.lng);
              ++mCount;
              let marker = new google.maps.Marker({
                position: mlatLng,
                map: this._map,
                //animation: google.maps.Animation.DROP,
                label: {
                  text: '' + mCount,
                  color: 'white',
                },
                icon: {
                  url: "./.../../assets/webroot/images/map/pin-red.png",
                  //size: new google.maps.Size(64, 64),
                  scaledSize: new google.maps.Size(65, 65),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(20, 40),
                  labelOrigin: new google.maps.Point(32, 14)
                },
                mLatlng: mlatLng,
                data: val.pickuppoint,
                id: val.pickupId
              });
              routestopees.push({
                latlng: val.pickuppointLocation,
                addr: val.pickupAddress,
                id: val.pickupId,
                point: val.pickuppoint
              });

              var infowindow = new google.maps.InfoWindow();
              marker.addListener('mouseover', function (event: any) {
                infowindow.setContent('<p>' + this.data + '</p>');
                infowindow.open(this.map, this);
              })
              this.pickupPointMarkers.push(marker);
              this.mapBounds.extend(mlatLng);
            }
            this.storage.set({ stoppage: '' });

            this.storage.set({ stoppage: routestopees });
            this._map.fitBounds(this.mapBounds);
          }
          this.showTimeline = true;
          this.showingStoppageDetails = false; //hide the stoppage details directive
        }
      }
    })

  }
  revertRoot(vehId: any, routeStartTime: any, routeEndTime: any, routeId: any, routeName: any) {
    //alert(vehId+' '+routeStartTime+' '+routeEndTime);
    let data = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          revertFlag: 2,
          routeId: routeId,
          routeStartTime: routeStartTime,
          routeEndTime: routeEndTime,
          vehId: vehId
        }

      }

    };



    //console.log(studentObj);
    let parent = this;
    swal({
      title: 'Do you want to revert the default vehicle for Route No - ' + routeName + ' ?',
      // text: 'Do you want to revert?',
      icon: 'info',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: false,
    }).then(function (isConfirm: any) {
      if (isConfirm) {      //  swal('Not Assigned', 'Action aborted :)', 'error');
        parent.apiService.editRoute(data).then(response => {

          if (response.status == 'success') {
            // swal('Success', 'Reverted successfully :)', 'success');

            setTimeout(() => {
              swal.close();
            }, 4000);


            parent.routes = [];
            parent.tempArr = [];
            let routeObj = {
              data: {
                key: localStorage.getItem('scbToken'),
                extra: {
                  pageJump: parent.pageJump
                }
              }
            };

            if (Object.keys(parent.RoutesGlobalFilterObj).length) {
              routeObj['data']['filter'] = {};
              routeObj['data']['filter'] = parent.RoutesGlobalFilterObj;
            }
            parent.apiService.getRoutes(routeObj).then((response: any) => {
              if (!parent.isScrolled) parent.initMap(); // initiliaze map.
              if (response) {
                if (response != '' && response != undefined && response != null) {
                  if (response.response) {
                    for (let route of response.response) {
                      parent.routes.push(route);
                    }
                    parent.noMoredata = false;
                  } else {
                    parent.noMoredata = true;
                    parent.pageJump = 0;
                  }
                }
              };
              parent.isLoading = false; //Loader Var
            }).catch((error: any) => { });
          } else if (response.status == 'partial') {
            swal('warning', response.message + ' :)', 'warning');
          } else {
            swal('Not Assigned', 'Action aborted :)', 'error');
          }

        });
      } else {
        //swal('Cancelled', 'Action aborted :)', 'error');
      }

    })






  }
  //Hide route timeline directive , showing route stoppages.
  getBack($event: any): void {

    let currentRouteId;
    currentRouteId = this.storage.get('currentRouteID');
    //console.log('********',currentRouteId);
    //  if(currentRouteId){/
    //     this.globalservice.removeAll();
    //  }
    this.pageJump = 0;
    if (Object.keys(this.RoutesGlobalFilterObj)) {
      // keeping filter values applied for routetypes selected
      let val = this.RoutesGlobalFilterObj.routeType;
      this.RoutesGlobalFilterObj = {};
      this.RoutesGlobalFilterObj.routeType = val
    }

    this.authGaurd.checkToken();
    this.showTimeline = $event;
    this.showStoppageUploadform = false; //hide the stoppage upload form.
    this.showingStoppageDetails = false; //hide the stoppage details directive
    this.clearMapMarkers(this.pickupPointMarkers); //clear pickup point markers.

    this.getRoutes(true);
  }

  showRouteEdit(event: any) { //function to show editRoute form from routeTimeline
    this.showingEditRouteForm(event.routeId);
  }

  /* Add route functions start*/

  //show add route form directive.
  showingAddRouteForm() {

    this.authGaurd.checkToken();
    if (this.showSettingsOverlay) this.hideSettingsOverlay(); //if settings overlay is being shown,hide it.
    setTimeout(() => {
      this.addRouteForm = true;
      this.showingStoppageDetails = false; //hide the stoppage details directive
    }, 1)
  }

  //Hide route form directive.
  hideRouteCreateForm($event: boolean) {
    this.addRouteForm = $event;

    this.addRouteForm = false;
    // this.showTimeline=true;
    //this.getRoutes(true);


  }



  createRoute($event: any) {
    this.apiService.createRoute($event).then(response => {
      if (response.status == 'success') {
        swal({
          title: 'Success',
          text: 'Route Added Successfully',
          icon: 'success',
        });
        this.addRouteForm = false; /*hide add route form, get back to route list.*/
        this.showTimeline = true;
        this.getRoutes(true);
      } else {
        swal('Failure!', this.globalUtils.getErrorMsg(response.ec), 'error');
      }
    }).catch(error => { });
  }


  /* Add route functions end*/


  /*Edit route functions start*/
  showingEditRouteForm(clickedRouteId: any) {
    let routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeId: clickedRouteId
        }
      }
    };
    this.hideSettingsOverlay();
    this.apiService.getRoutes(routeObj).then(response => {
      let parent: any = this;
      if (response) {
        this.routeInfoDetails = response.response;
        this.routeInfoDetails = this.routeInfoDetails[0];
        this.showTimeline = false;
        this.showStoppageForm = false;
        this.showingStoppageDetails = false;
        this.showEditPickupPointForm = false;
        this.showRouteEditForm = true;
        console.log(this.routeInfoDetails)
      }
    }).catch(error => { });
  }

  editRoute($event: any) {
    // console.log($event, $event.data.form.removeVeh);

    const routeId = $event.data && $event.data.form && $event.data.form.routeId ? $event.data.form.routeId : '';
    let apiData = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routeId: routeId
        }
      }
    }

    if ($event.data.form.removeVeh) {
      this.apiService.editRoute($event).then(response => {
        if (response.status == 'success') {
          this.getRoutes(true);

        } else {
          // swal('Failure',this.globalUtils.getErrorMsg(response.ec), 'error');
        }
      }).catch(error => { });

    } else {
      this.apiService.editRoute($event).then(response => {

        if (response.status == 'success') {
          swal({
            title: 'Success',
            text: 'Route Information Updated Successfully',
            icon: 'success',
          });
          swal("Success", "Route Information Updated Successfully", "success").done();
          setTimeout(() => {
            swal.close();
          }, 2000);

          this.getRoutes(true);
        } else {
          swal('Failure', 'vehicle already assigned to this timing', 'error');

          this.loading = true
          // setTimeout(() => {
          //   this.loading = false
          //   this.routeTimeFailure = response.status
          //   // console.log(this.routeInfoDetails)
          //   this.misMatchData = response.data
          //   this.endTimeDiff = this.misMatchData.endTimeDiff
          //   this.startTimeDiff = this.misMatchData.startTimeDiff
          //   this.routeStartTime = this.misMatchData.routeStartTime
          //   this.routeEndTime = this.misMatchData.routeEndTime


          // }, 2000);




        }
      }).catch(error => { });
    }

    setTimeout(() => {

      this.apiService.editRoute(apiData).then((res) => {
      });

    }, 1000);

  }

  // Hide route edit directive.
  hideEditDirective($event: boolean) {
    this.showRouteEditForm = $event;


    this.showTimeline = true;
  }


  closeModal() {
    this.routeTimeFailure = false; // Hide the modal when the close button is clicked
  }


  deleteRoute(deleteRouteId: any): void { /*new code after angular6 migraton */
    const parent = this;
    let routeObj = {
      formData: {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            routeId: deleteRouteId
          }
        }
      },
      functionCallback: 'deleteOrgRoute'
    };
    this.userOperationData = routeObj;
    swal({
      title: 'Are you sure?',
      text: 'This route will be removed',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        // swal({
        //   title: 'Success!',
        //   text : 'Route Removed from Organization',
        //   icon : 'success'
        // }).then(function() {
        parent.deleteOrgRoute();
        //});
      } else {
        // swal('Cancelled', 'Action aborted:)', 'error');
      }
    });
  }

  // Delete route.
  deleteOrgRoute() {
    let routeObj = this.userOperationData.formData;

    this.apiService.deleteRoute(routeObj).then(response => {
      let result = response;
      if (result.status == 'success') {
        this.getRoutes(true);
      }
    }).catch(error => {
      console.log('Error', error);
    });
  }
  /* Delete route functions end */

  /* Stoppage details functions start*/

  showStoppageDetails($event: any) {
    this.showButton = true;
    let parent = this;
    let stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeId: this.clickedRouteId,
          pickupId: $event.pickupId
        }
      }
    }
    this.clearMapMarkers(this.pickupPointMarkers);
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      parent.stoppageInfo = response.response;
      this.showTimeline = false;
      this.showStoppageUploadform = false;
      this.showStoppageForm = false;
      this.showingStoppageDetails = true;
      this.clickedStoppageId = $event.pickupId;
    });
  }

  hideStoppageDetails($event: any) { // hiding stoppage details directive.
    this.showingStoppageDetails = $event;
    this.showTimeline = true;
    this.showRouteTimeline(this.clickedRouteId, false);  // plotting clicked route stoppages , callback value set to false.
  }

  /* Stoppage details functions end*/


  /*pickup point functions start */

  showUploadForm($event: boolean) { // showing pickup point excel upload directive.
    this.showStoppageUploadform = $event;
    this.showTimeline = true;
    this.showingStoppageDetails = false; // hide the stoppage details directive
    this.clearMapMarkers(this.pickupPointMarkers);
  }

  hideUpload($event: any) { // hiding pickup point excel upload directive.

    this.showStoppageUploadform = $event;
    this.showTimeline = true;
    this.showingStoppageDetails = false; // hide the stoppage details directive
    this.showRouteTimeline(this.clickedRouteId, false);
  }

  showStoppageDir($event: boolean) { // showing directive for adding stoppage by form
    this.showStoppageUploadform = false;
    this.showTimeline = true;
    this.showStoppageForm = $event;
    this.showingStoppageDetails = false; // hide the stoppage details directive
  }

  hideAddPickupFormDir($event: any) { // hiding directive for adding stoppage by form
    this.showStoppageUploadform = $event;
    this.showStoppageForm = false;
  }

  createPickupPoint($event: any) {
    this.apiService.createPickUpPoint($event).then(response => {
      if (response.status == 'success') {
        swal('Success', 'PickUp point added Successfully', 'success', {

        });
        setTimeout(() => { swal.close(); }, 2000);
        this.showRouteEditForm = false;
        this.showStoppageUploadform = false; // hiding pickup form directive.
        this.showStoppageForm = false; // hiding upload pickup points directive.

        //clearing reference pickup point markers on create pickup point form.
        if (this.pickupRefMarkers.length) {
          this.clearMapMarkers(this.pickupRefMarkers);
          this.pickupRefMarkers = [];
        }

        this.showRouteTimeline(this.clickedRouteId, false);
      } else {

        let getErrorMsg = response.response;
        let apiResponseMessage: any = [] = getErrorMsg.duplicated;
        if (apiResponseMessage[1].ETA) {
          swal({
            title: 'Failure',
            text: 'ETA time is beyond than route time' + getErrorMsg.duplicated[1].ETA,
            icon: 'error',
          });
        } else {
          swal('Failure', 'Failed try again with valid inputs', 'error');
        }
      }
    }).catch(error => { });
  }

  setPickupPtMarkers($event: any) {
    this.pickupRefMarkers = [...$event];
  }



  removePickUpPoint(pickupDetails: any) { /*code after migartion to angular6 */
    const parent = this;
    this.userOperationData = {
      formData: pickupDetails,
      functionCallback: 'deleteRoutePickupPoint'
    };
    swal({
      title: 'Are you sure?',
      text: 'This Pickup point will be Removed',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        // swal({
        //   title: 'Success!',
        //   text : 'Route Removed from Organization',
        //   icon : 'success'
        // }).then(function() {
        parent.deleteRoutePickupPoint();
        //});
      } else {
        // swal('Cancelled', 'Action aborted:)', 'error');
        // setTimeout( () => { swal.close();}, 1000);
      }
    });
  }

  deleteRoutePickupPoint(): void {
    let routeObj = this.userOperationData.formData;
    // this.showSuccessDialog = true;
    this.apiService.deletePickupPoint(routeObj).then(response => {
      // this.showDialog = false;
      if (response.status == 'success') {
        this.routeStoppages = [];
        // this.showUploadSuccess = true;
        // this.showUploadFailure = false;
        this.clearMapMarkers(this.pickupPointMarkers);
        this.showRouteTimeline(this.clickedRouteId, false);  // callback to update the route stoppages.
      }
    }).catch(error => { });
  }

  showEditPickUpFormDir($event: boolean): void {

    let stoppageObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeId: this.clickedRouteId,
          pickupId: this.clickedStoppageId
        }
      }
    }
    this.apiService.getStoppageDetails(stoppageObj).then(response => {
      if (response) {
        this.pickupDetails = response.response;
        this.showingStoppageDetails = false;
        this.showEditPickupPointForm = $event;
      }
    });
  }

  hideEditPickupFormDir($event: any) {
    this.showingStoppageDetails = $event;
    this.showEditPickupPointForm = false;
  }

  editPickupPoint($event: any): void {
    this.apiService.editPickUpPoint($event).then(response => {
      if (response.status == 'success') {

        swal('Success', 'Pickup Information Updated Successfully', 'success');
        setTimeout(() => { swal.close(); }, 2000);
        this.showStoppageDetails({ event: true, pickupId: response.pickupId }); // function callback after pickUpPoint edit.
        this.showingStoppageDetails = true;
        this.showEditPickupPointForm = false;
      } else {
        swal('Failure', this.globalUtils.getErrorMsg(response.ec), 'error');
      }
    }).catch(error => { });
  }

  stoppageFilters($event: any) {
    this.globalFilterObj.pickUpPoint = $event.filterText;
    this.showRouteTimeline($event.routeId, false);
  }

  /*  pickpup point functions end */

  actionGrantStatus(userResponse: any) {
    if (userResponse) { // pressed yes for delete operation.
      let functionCallback = this.userOperationData.functionCallback;
      this[functionCallback]();
    } else { }
    this.userOperationData = {};
  }

  scrollTop() {
    $('#routeDetails_id').animate({ scrollTop: 0 }, 'fast');
  }

  // hide global settings overLay.
  hideSettingsOverlay() {
    this.showSettingsOverlay = false;
    setTimeout(() => {
      this.initMap();
    }, 1);
  }

  onScroll($event: any) {
    if (($event.target.scrollTop + $event.target.offsetHeight) >= $event.target.scrollHeight) {
      if (this.noMoredata == false) {
        this.pageJump++;
        this.isScrolled = true;
        this.getRoutes(false);
      }
    }
    //$(window).scrollTop() + window.innerHeight >= $(window).height()
  }

  clearMapMarkers(markerArr: any) {
    for (var id in markerArr) {
      // console.log(markerArr[id])
      markerArr[id].setMap(null);
    }
  }

  closeModule() {
    this.controlStudentModule = false;
  }

  studentControl(studObj: any) {
    //console.log(studObj);
    this.studentInfo = studObj;

    this.controlStudentModule = true;

  }

  assignRouteStudent(routeId: any, pickupId: any, stuId: any) {

    let arrayValues: any = [];

    arrayValues['routes'] = {};
    arrayValues['routes'][routeId] = {};
    arrayValues['routes'][routeId][pickupId] = [stuId];


    let studentObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routes: arrayValues['routes']
        }
      }

    };
    //console.log(studentObj);
    let parent = this;
    swal({
      title: 'Are you sure?',
      text: 'This pickup point assign to this Student',
      icon: 'info',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: false,
    }).then(function (isConfirm: any) {
      if (isConfirm) {      //  swal('Not Assigned', 'Action aborted :)', 'error');
        parent.apiService.AssignMember(studentObj).then(response => {

          if (response.status == 'success') {
            swal('Success', 'Assigned successfully :)', 'success');
          } else if (response.status == 'partial') {
            swal('warning', response.message + ' :)', 'warning');
          } else {
            swal('Not Assigned', 'Action aborted :)', 'error');
          }

        });
      } else {
        //swal('Cancelled', 'Action aborted :)', 'error');
      }

    })
  }

  // private popOvers(){
  /* working draggable screen */
  iframe_video(param: any) {
    this.showIframe = false;

    this.iframe_head_rname = param.routeName
    this.iframe_head_vehname = param.vehNo

    let isValid = Object.keys(param).length ? true : false

    if (isValid) {
      this.apiService.getVehicleCameraLiveStream({
        data: {
          key: localStorage.getItem('scbToken'),
          filter: {
            vehNo: [param.vehNo]
          }
        }
      }).then(res => {
        // isValid  = res.status.toLowerCase() === "failure" ? false : true;
        isValid = res.response[param.vehNo] === false ? false : true;
        // isValid && (this.showIframe=true);

        if (isValid) {
          this.showIframe = false;
          this.showIframe = true;
          setTimeout(() => {
            // res.response = "http://streaming.placer.in/808gps/open/player/video.html?lang=en&devIdno=918128452&jsession=b66be376-c5d0-4183-83bc-e056bde800c4";
            let iframe = ` <iframe style="" id="real_iframe" scrolling="no" frameborder="0" height="300" width="670" src="${res.response[param.vehNo]}"></iframe>`
            document.getElementById('real_frame1').innerHTML = iframe;
            document.getElementById('alert').style.zIndex = '999999';
            // document.getElementById('real_iframe').setAttribute('height','250');
            // document.getElementById('real_iframe').setAttribute('width','650');

            this.dragEvent(); // start draggable event only iframe gets loaded

          }, 500);

        } else {
          swal('No Devices Available', 'for ' + this.iframe_head_rname + ' - ' + this.iframe_head_vehname, 'warning');
        }

      });

    } else;
    // }

  }

  animate_back() {

    this.showIframe = false;
    !this.showIframe && clearTimeout(this.cleartimeout_Drag); // on false clear timeout
  }

  dragEvent() {
    $("#alert").draggable({
      containment: $('body')
    });

    this.cleartimeout_Drag = setTimeout(() => { $("#alert").draggable({ containment: $('body') }); this.dragEvent(); }, 500)

    return false;
  }
  //end of draggblescreen
  // route time extended




  routeTimeExtend = new FormGroup({
    myRadio: new FormControl(),
  });

  editMultiRoute() {
    this.addTimes()

    let data = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: [
          {
            routeName: this.routeInfoDetails.routeName, routeId: this.routeInfoDetails.routeId, routeStartTime: this.routeInfoDetails.routeStartTime,
            routeEndTime: this.routeInfoDetails.routeEndTime
          },
          {
            routeName: this.misMatchData.routeName, routeId: this.misMatchData.routeId, routeStartTime: this.result, routeEndTime: this.resultEndTime,
            timing: this.endTimeDiff, operator: this.misMatchData.operator
          }
        ]

      }
    }

    this.apiService.editMultiRoute(data).then(response => {
      if (response.status == "success") {

        swal('Success', 'Assigned successfully :)', 'success');
      } else if (response.status == "failure") {
        swal('Failure', 'Invalid Time', 'failure');
      }
      this.routeIDDetails = response.routeId;

      this.closeModal()

    });
  }


  onSubmit() {
    const fg = this.routeTimeExtend.get('myRadio');
    this.valueRoute = fg.value;
    if ((this.valueRoute == this.misMatchData.routeId)) {
      this.submitted = true;
      const parent = this;
      let data = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            routeId: this.misMatchData.routeId,
            vehId: this.routeInfoDetails.vehId,
            removeVeh: 'yes'
          }
        }
      };
      swal({
        title: 'Are you sure?',
        text: 'This Vehicle will be unlinked from this route',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true,
      }).then(function (isConfirm: any) {
        if (isConfirm) {
          parent.apiService.editRoute(data).then(response => {
            if (response) {
              //this will redirect back to Dashboard after api response is succes or failure
              // if (type === 'same') {
              // } else {
              parent.hideEditRouteForm.emit(false);


              window.location.reload();

            }
          }).catch(error => { });

        } else {

        }
      });
    }
    if ((this.valueRoute == 'increaseRouteTime')) {
      this.editMultiRoute()

    }
  }



  addTimes() {
    let obj = {
      routeName: 'Live Route Drop',
      routeStartTime: this.routeStartTime,
      routeEndTime: this.routeEndTime,
      startTimeDiff: this.startTimeDiff,
      endTimeDiff: this.endTimeDiff,
    }
    let d = new Date();
    let [h, m] = obj['routeStartTime'].split(':').map(Number);
    let [eh, em] = obj['routeEndTime'].split(':').map(Number);

    let [diff_h, diff_m] = obj['endTimeDiff'].split(':').map(Number);

    const totalMinutes1 = h * 60 + m;
    const totalMinutes2 = diff_h * 60 + diff_m;
    const totalMinutes3 = eh * 60 + em;
    // Add the total minutes
    const totalMinutes = totalMinutes1 + totalMinutes2;
    const totalendTimeMintes = totalMinutes3 + totalMinutes2;
    // Convert back to hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    // end time
    const hoursEnd = Math.floor(totalendTimeMintes / 60);
    const minutesEnd = totalendTimeMintes % 60;
    // Format the result as HH:MM
    this.result = `${this.pad(hours)}:${this.pad(minutes)}`;
    this.resultEndTime = `${this.pad(hoursEnd)}:${this.pad(minutesEnd)}`;

  }




}


