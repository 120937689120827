/**
 * @Date:   2018-03-12T16:17:12+05:30
 * @Last modified time: 2018-05-09T12:54:23+05:30
 */

import { Component, Input, Output } from '@angular/core';
import { ApiService } from './../../../services/ApiService';
import { authGaurd } from './../../../services/auth-guard.service';
import { GlobalUtils } from './../../../services/global-utils';
import { globalService } from "./../../../services/global.service";
import { StorageService } from "./../../../services/storage.service";
import { COM } from './../../../services/com.services';
import { concat } from 'rxjs';
import { RouterModule, Routes, Router } from '@angular/router';

interface availableReportGroups {
    commonReportsGroup?: boolean;
    movementReportsGroup?: boolean;
    callReportsGroup?: boolean;
    otherReportsGroup?: boolean;
    attendanceReportsGroup?: boolean;
    analyticsReportGroup?: boolean;
    faceAttendanceReportGroup?: boolean
}

// If any new report added, look-up must b e updated accordingly.
const APIKeysLookup = {
    tracking: 'movementReportsGroup',
    rfid: 'commonReportsGroup',
    alertlog: 'callReportsGroup',
    others: 'otherReportsGroup',
    attendance: 'attendanceReportsGroup',
    analytics: 'analyticsReportGroup',
    faceAttendance: 'faceAttendanceReportGroup'

}

const reportIconLookup = {
    alertlog: 'glyphicon glyphicon-phone',
    tracking: 'glyphicon glyphicon-road',
    others: 'glyphicon glyphicon-info-sign',
    rfid: 'fa fa-id-card',
    attendance: 'fa fa-id-badge',
    analytics: 'fa fa-bar-chart',
    faceAttendance: 'fa fa-id-badge'
}

const reportNameLookUp = {
    tracking: 'Tracking',
    rfid: 'RFID',
    alertlog: 'Calls',
    others: 'Others',
    attendance: 'Attendance',
    analytics: 'Analytics',
    faceAttendance: 'Face Attendance',


}

const reportGroupLookup = {
    movementReportsGroup: 'tracking',
    commonReportsGroup: 'rfid',
    callReportsGroup: 'alertlog',
    otherReportsGroup: 'others',
    attendanceReportsGroup: 'attendance',
    analyticsReportGroup: 'analytics',
    faceAttendanceReportGroup: 'faceAttendance'


}

const reportGroupCallbackFunc = {
    tracking: 'initMovementReportGroup',
    rfid: 'initCommonReportGroups',
    alertlog: 'initCallReportGroup',
    others: 'initOtherReportGroup',
    attendance: 'initAttendanceReportGroup',
    analytics: 'initAnalyticsReportGroup',
    faceAttendance: 'initFaceAttendanceReportGroup'
}

@Component({
    selector: '',
    templateUrl: './movementReport.component.html',
    styles: [`
        .viewReportBtn {
        }
        div.fixedHeight{
            height: 582px;
        }

        /*  report tab */
        /*div.report-tab-container{
          z-index: 10;
          background-color: #ffffff;
          padding: 0 !important;
          border-radius: 4px;
          -moz-border-radius: 4px;
          border:1px solid #ddd;

          -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
          box-shadow: 0 6px 12px rgba(0,0,0,.175);
          -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
          background-clip: padding-box;
          opacity: 0.97;
          filter: alpha(opacity=97);
        } */
        div.report-tab-menu{
          padding-right: 0;
          padding-left: 0;
          padding-bottom: 0;
        }
        a.list-group-item {
            cursor:pointer;
            border: none;
            color:#BDC9CE;
        }
        div.report-tab-menu div.list-group>a.active,
        div.report-tab-menu div.list-group>a.active .glyphicon,
        div.report-tab-menu div.list-group>a.active .fa{
          background: none;
          background-image: none;
          color: #2CC6E8;
        }
        /*
        div.report-tab-menu div.list-group{
          margin-bottom: 0;
        }
        div.report-tab-menu div.list-group>a{
          margin-bottom: 0;
        }
        div.report-tab-menu div.list-group>a .glyphicon,
        div.report-tab-menu div.list-group>a .fa {
          color: #5A55A3;
        }
        div.report-tab-menu div.list-group>a:first-child{
          border-top-right-radius: 0;
          -moz-border-top-right-radius: 0;
        }
        div.report-tab-menu div.list-group>a:last-child{
          border-bottom-right-radius: 0;
          -moz-border-bottom-right-radius: 0;
        }

        div.report-tab-menu div.list-group>a.active:after{
          content: '';
          position: absolute;
          left: 100%;
          top: 50%;
          margin-top: -13px;
          border-left: 0;
          border-bottom: 13px solid transparent;
          border-top: 13px solid transparent;
          border-left: 10px solid #5A55A3;
        }
        */
        div.report-tab-content{
          background-color: #ffffff;
          /* border: 1px solid #eeeeee; */
          padding-left: 20px;
          padding-top: 10px;
        }
        .fa-id-card{
            font-size:1.4em !important;
        }
                    @-moz-document url-prefix() {
                #displaytext.form-control,select.form-control {
                    padding-right: 0px;
                    background-image: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='14px' height='14px' viewBox='0 0 1200 1000' fill='rgb(51,51,51)'> <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/> </svg>") !important;
                    background-repeat: no-repeat;
                    background-position: calc(100% - 7px) 50%;
                    -moz-appearance: none;
                    appearance: none;
                }
            
            }
        a.list-group-item{
            padding: 5px 0px !important;
        }
       

        `],
})


export class MovementReportComponent {
    private avaibaleReportsInGroup: any;
    private loggedMemberInfo: any;
    public availableOrgReports: any;
    public isLoading: boolean; //Loader Var
    public availableClasses: any;
    public availableSections: any[];
    public userReportControls: any;
    public userRFIDControls: any;

    public vehid: string = this.storageservice.get('vehicleId');
    public vehName: string = this.storageservice.get('vehicleName');
    public report: string = this.storageservice.get('report');

    public reportGroups: availableReportGroups = {
        commonReportsGroup: false,
        movementReportsGroup: false,
        callReportsGroup: false,
        otherReportsGroup: false,
        attendanceReportsGroup: false,
        analyticsReportGroup: false,
        faceAttendanceReportGroup: false


    }
    appReports: any = {};
    face_attendance: any = {};
    constructor(
        private apiService: ApiService,
        public authGaurd: authGaurd,
        public globalService: globalService,
        public storageservice: StorageService,
        public com: COM, private router: Router
    ) {
        this.availableOrgReports = [];
        this.availableClasses = {};
        this.availableSections = [];
        this.isLoading = true;
        let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
        this.com.comAction.subscribe((e: any) => {
            if (e.lable === 'OrgReports') {

                this.userReportControls = Object.assign(e.data, userInfo.reports);
            }
        })

    }

    ngOnInit() {
        this.getConfiguredReports();
        this.authGaurd.checkToken();

        this.dashBoardLogMove();

        // this.com.comAction.next( {
        //      lable: 'userReportControl',
        //      data: this.userReportControls
        // });

    }


    dashBoardLogMove() {

        setTimeout(() => {
            if ((this.storageservice.get('vehicleId') != '') || (this.storageservice.get('vehicleName') != '') || (this.storageservice.get('report') != '')) {

                // this.initReportSection(this.availableOrgReports[''].reportGroupInitFunc);
                // this.initReportSection('initMovementReportGroup');
                this.initReportSection('initMovementReportGroup')
            }

        }, 500);



    }



    initReportSection(reportFunc: string) {
        if (reportFunc === 'initAnalyticsReportGroup') {
            this.storageservice.set({
                'selectedReport': reportFunc,

            })
            this.router.navigate(['/analytics/vehicle-reach-insight']);

        } else {
            this[`${reportFunc}`]();
        }

    }

    initCommonReportGroups() {
        // console.log(' this.loggedMemberInfo', this.loggedMemberInfo)
        // this.avaibaleReportsInGroup = Object.assign({}, Reflect.get(this.loggedMemberInfo.reports,reportGroupLookup['commonReportsGroup']))
        this.avaibaleReportsInGroup = this.loggedMemberInfo.reports[reportGroupLookup['commonReportsGroup']];
        this._initReportGroups({ commonReportsGroup: true });
        // console.log('TEST',this.avaibaleReportsInGroup);
    }

    initCallReportGroup() {
        this.avaibaleReportsInGroup = Object.assign({}, Reflect.get(this.loggedMemberInfo.reports, reportGroupLookup['callReportsGroup']))

        this._initReportGroups({ callReportsGroup: true });
    }
    initAnalyticsReportGroup() {
        this.avaibaleReportsInGroup = Object.assign({}, Reflect.get(this.loggedMemberInfo.reports, reportGroupLookup['analyticsReportGroup']))
        this._initReportGroups({ analyticsReportGroup: true });

    }
    initMovementReportGroup() {
        this.avaibaleReportsInGroup = Object.assign({}, Reflect.get(this.loggedMemberInfo.reports, reportGroupLookup['movementReportsGroup']))
        this._initReportGroups({ movementReportsGroup: true });
    }

    initOtherReportGroup() {
        // this.avaibaleReportsInGroup = Object.assign({},Reflect.get(this.loggedMemberInfo.reports,reportGroupLookup['otherReportsGroup']));
        // this._initReportGroups({otherReportsGroup:true});
        this.avaibaleReportsInGroup = this.loggedMemberInfo.reports[reportGroupLookup['otherReportsGroup']];
        this._initReportGroups({ otherReportsGroup: true });

    }

    initAttendanceReportGroup() {
        // console.log(this.loggedMemberInfo.reports);
        // this.avaibaleReportsInGroup = this.loggedMemberInfo.reports[reportGroupLookup['AttendanceReportsGroup']];
        this.avaibaleReportsInGroup = { ...this.loggedMemberInfo.reports[reportGroupLookup['attendanceReportsGroup']] }
        this._initReportGroups({ attendanceReportsGroup: true });

    }
    // faceAttendanceReportGroup
    initFaceAttendanceReportGroup() {
        // console.log(this.loggedMemberInfo.reports);
        this.avaibaleReportsInGroup = { ...this.loggedMemberInfo.reports[reportGroupLookup['faceAttendanceReportGroup']] }
        this._initReportGroups({ faceAttendanceReportGroup: true });

    }

    public currrentUserInfo: any = [];
    getConfiguredReports() {
        this.isLoading = true;
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }


        let userIn = localStorage.getItem('userInfo')
        if (userIn) {
            let userInfo = JSON.parse(userIn);
            if (userInfo && userInfo.reports) {
                Object.keys(userInfo && userInfo.reports).forEach((key) => {
                    Object.keys(userInfo.reports[key]).forEach((key2) => {
                        if (this.currrentUserInfo[key2] === undefined) {
                            this.currrentUserInfo[key2] = [];
                        }
                        this.currrentUserInfo[key2].push(userInfo.reports[key][key2]);
                    });
                });
            }
        }
        // console.log(this.currrentUserInfo);
        this.apiService.getCurrentOrgDetails(apiHeader).then(response => { //console.log(response);
            let result = response;

            let ifTbs = response.response.name.includes('The British School');
            // this.storageservice.set({});

            this.storageservice.set({ stbs: false });
            if (ifTbs) {
                this.storageservice.set({ stbs: ifTbs });
            }
            // console.log('response.response',response.response)
            this.loggedMemberInfo = { ...response.response };
            let availableReports = this.loggedMemberInfo.reports;
            if ('classLists' in this.loggedMemberInfo) {
                this.availableClasses = {
                    classes: [...this.loggedMemberInfo.classLists],
                    sections: [... this.loggedMemberInfo.SectionLists]
                };
            }
            if (result.response && result.response.reports && result.response.reports.appreports
                && result.response.reports.appreports.appReports == undefined
            ) {
                this.appReports = result.response.reports.appreports.appReports

            }

            if (result.response && result.response.reports && result.response.reports.attendance
                && result.response.reports.attendance.face_attendance
            ) {
                this.face_attendance = result.response.reports.attendance.face_attendance

            }

            for (let reportInfo in availableReports) {
                let reportsPerm = availableReports[reportInfo];
                let isReportSubscribed: boolean;

                if (typeof (reportsPerm) === 'object') {
                    isReportSubscribed = this.isReportSubscribed(reportsPerm);
                } else {
                    isReportSubscribed = availableReports[reportInfo];
                }
                // console.log('######### : ',reportNameLookUp[reportInfo]);

                if (isReportSubscribed) {
                    let obj = {
                        reportDispname: reportNameLookUp[reportInfo],
                        reportName: reportInfo,
                        reportGroupIcon: reportIconLookup[reportInfo],
                        reportGroupName: APIKeysLookup[reportInfo],
                        reportGroupInitFunc: reportGroupCallbackFunc[reportInfo]
                    }
                    Object.assign(obj, { isReportSubscribed: isReportSubscribed });
                    this.availableOrgReports.push(obj);
                }
            }

            this.isLoading = false;
            this.initReports(this.availableOrgReports[0]['reportGroupInitFunc']);
            // this.initCommonReportGroups();

        }).catch(error => { })
    }

    initReports(str: string) {
        // console.log('str : ',str);
        return this[`${str}`]();
    }

    _initReportGroups({ commonReportsGroup = false, movementReportsGroup = false, faceAttendanceReportGroup = false, analyticsReportGroup = false, callReportsGroup = false, otherReportsGroup = false, attendanceReportsGroup = false }: availableReportGroups = {}): void {
        this.reportGroups.commonReportsGroup = commonReportsGroup;
        this.reportGroups.movementReportsGroup = movementReportsGroup;
        this.reportGroups.callReportsGroup = callReportsGroup;
        this.reportGroups.otherReportsGroup = otherReportsGroup;
        this.reportGroups.attendanceReportsGroup = attendanceReportsGroup;
        this.reportGroups.analyticsReportGroup = analyticsReportGroup;
        this.reportGroups.faceAttendanceReportGroup = faceAttendanceReportGroup;

    }

    isReportSubscribed(param: any): boolean {
        let subscriptionStatus = false;
        for (let report in param) { //console.log(this.currrentUserInfo[report][0]);   
            if (param[report] || this.currrentUserInfo && this.currrentUserInfo[report] && this.currrentUserInfo[report][0]) {
                subscriptionStatus = true;
            }
        }
        return subscriptionStatus;
    }

}
