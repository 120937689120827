import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ApiService } from './../../services/ApiService';
import { COM } from './../../services/com.services';
import { ExcelService } from './../../services/excel.service';
import { StorageService } from "./../../services/storage.service";
import { KDTreeService } from "./../../services/kdtree.service";
import * as XLSX from 'xlsx'
declare var $: any; declare var alasql: any;;

@Component({
    selector: 'all-route-download',
    templateUrl: './all-route-download.html',
    // styleUrls: ['./student-route-management.css']
})
export class allRouteDownloadComponent {
    public formControl: any;


    routesDetails: any;
    routeID: any;
    pickuppoints: any;

    chartData: any;
    earlier: any;
    delay: any;
    noTimestamp: any = [];
    ontime: any;
    todayDate: Date;
    routeName: any = [];
    routeData: any = {}
    public pickuppointDetails: any;

    vehId: any;
    routeId: any;
    trackData: any;
    filterForm: any;

    date_ts: any;
    totalNoTime: any;
    noTimestampPerc: number;
    earlierPerc: any;
    delayPerc: any;
    ontimePerc: any;
    startDataFrom: void;
    endDateTo: any;
    todaydate: string | null = null
    loading = true;
    dateWiseExlStatus: {};
    showExcelDownload: boolean;
    public routeStoppages: any = {};
    bypassedRouteId: any;
    route: any;
    orgName = localStorage.getItem('orguserName');
    excelService: any;
    constructor(public storage: StorageService,
        private apiService: ApiService,
        public storageservice: StorageService,
        private KDTree: KDTreeService,
        private router: Router,
        private excel: ExcelService,
        private com: COM,

    ) {

    }

    public routeStoppagesExcelData: any = [];
    public routeStoppagesProcessData: any = {};
    public fromDate: any;
    public toDate: any;
    options = ['', 'Morning', 'Afternoon', 'Evening'];
    public selectedOption: any;
    public maxDate: any;
    public maxDate1: any;
    public isButtonDisabled: any = false;
    public countdown: any = 60;
    public intervalId: any;
    public title = 'School';
    public routes: any = {};
    public today = new Date();
    public hasError: any = [];
    public callCount = 0;
    totalRoute: any;
    currentRoute: any;
    processRoute: any;
    remainingRoute: any;
    dates: any;
    result: any = [];
    mergedData: any = [];

    isNextProcess = false;
    async ngOnInit(): Promise<void> {
        await this.startProcess().catch((error: any) => { throw error });

    }

    navigate(url: string) {
        this.router.navigate(['/' + url, {}]);
    }





    async assignDate() {
        // this.isNextProcess = true;
        this.hasError = [];
        this.totalRoute = 0;
        this.currentRoute = 0;
        this.processRoute = 0;
        this.remainingRoute = 0;
        this.dates = 0;
        this.routes = {};
        if (this.callCount <= 5) {
            this.callCount++
            let startDate = new Date(this.fromDate).getTime();
            let endDate = new Date(this.toDate).getTime();
            let timeDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
            if (startDate > endDate) this.hasError.push("Invalid date format.");
            else if (timeDiff > 5) this.hasError.push("A maximum of five days is allowed.");
            else await this.startProcess().catch((error: any) => { throw error });
        } else {
            this.isButtonDisabled = true;
            this.startCountdown();
        }
    }
    startCountdown(): void {
        this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
            } else {
                this.isButtonDisabled = false;
                clearInterval(this.intervalId);
            }
        }, 1000);
    }
    async startProcess() {
        this.routes = {};
        let routeDetails = await this.routeDetails().catch((error: any) => { throw error });
        this.totalRoute = routeDetails.length;
        //Loop route Data...
        let routeCound = 1;
        for (let routeDetail of routeDetails) {

            this.currentRoute = routeDetail['routeName'];

            let routeID: string = routeDetail['routeId'];
            //Get pickup point Data...
            let pickuppointDetails = await this.pickupPointDetails(routeID).catch((error: any) => { throw error });


        }
        // this.mergeData();


    }
    getKeys(obj: any): string[] {
        return Object.keys(obj);
    }
    formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }



    getLastWorkingDays() {
        try {
            const workingDays: any = [];
            let sDate = new Date(this.fromDate);
            let eDate = new Date(this.toDate);
            while (sDate <= eDate) {
                const dayOfWeek = sDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                    let splitDate = new Date(sDate).toISOString().split('T')[0];
                    workingDays.push(splitDate);
                }
                sDate.setDate(sDate.getDate() + 1);
            }

            console.log(this.fromDate)
            return workingDays;


        } catch (error) {
            throw error;
        }
    };
    async routeDetails() {
        let returnData: any = [];
        let pageJump: any = 0;

        while (pageJump != null) {
            let routeQuery = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    extra: {
                        pageJump: pageJump
                    },
                    projections: ["routeId", "routeName", "routeStartTime", "routeEndTime", "routeType", "tags", "vehId"]
                }
            }
            //Replace your code structure with a "routeView()" function name.
            let result: any = await this.apiService.getRoutes(routeQuery).catch((error: any) => { throw error })
            if (result["status"] == "success") {
                if (result["response"] != null) {
                    returnData = [...returnData, ...result["response"]];
                    this.routeData = returnData
                    pageJump++
                } else {
                    pageJump = null
                }
                console.log(this.routeData)
            }
            else pageJump = null;
        }
        return returnData;
    }
    pickUpData: { routeId: any; expectedTime: any; pickuppoint: any; }[] = [];
    async pickupPointDetails(routeID: string) {
        let routeQuery = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    routeId: routeID
                }
            }
        }
        //Replace your code structure with a "veiwPickupPoint()" function name.
        let result: any = await this.apiService.getRouteStoppages(routeQuery).catch((error: any) => { throw error })
        if (result["status"] == "success") {
            const routeData: any = result.response.pickuppoints || [];
            this.routeStoppages = [];
            console.log(routeData)
            // for (let routeItem of routeData) {
            //     this.routeStoppages.push({

            //         routeId: routeItem.routeId,
            //         expectedTime: routeItem.expectedTime,
            //         pickuppoint: routeItem.pickuppoint,
            //     })
            // }
            // this.excelDownloadStoppageData()
        }


    }



    // async pickupPointDetails(routeID: string) {
    //     while (pageJump != null) {
    //     let routeQuery = {
    //         data: {
    //             key: localStorage.getItem('scbToken'),
    //             filter: {
    //                 // routeId: routeID
    //             }
    //         }
    //     }
    //     //Replace your code structure with a "veiwPickupPoint()" function name.
    //     let result: any = await this.apiService.getRouteStoppages(routeQuery).catch((error: any) => { throw error })
    //     if (result.status == "success")
    //         this.routeStoppages = [];
    //     this.routeStoppages = result.response;
    //     pageJump++
    //     this.storage.set({ 'routePickup_points': this.routeStoppages });
    //     console.log(this.routeStoppages)
    //     this.routeStoppagesExcelData = []
    // }


    mergeData() {
        this.mergedData = this.routeData.map((route: {
            routeType: any; routeId: any; routeName: any;
        }) => {

            const matchingPickups = this.routeStoppages.pickuppoints.filter((pickup: { routeId: any; }) => pickup.routeId === route.routeId);

            return matchingPickups.map((pickup: { pickuppoint: any; expectedTime: any; }) => ({
                routeName: route.routeName,
                pickuppoint: pickup.pickuppoint,
                expectedTime: pickup.expectedTime,

            }));
        }).flat();
        // this.excel.exportExcel(this.mergedData, fileName);
    }
    // pickUpData = [
    //     {
    //         "routeId": "GclOwKieuLTgqYPXhaWt2635891724155702",
    //         "expectedTime": "17:42",
    //         "pickuppoint": "Le Royal Meridian",
    //         "pickuppointLocation": { "lat": 13.0075494, "lng": 80.2069558 },
    //         "pickupAddress": "2, Venu St, Guindy, Chennai, Tamil Nadu 600032, India",
    //         "pickupRadius": 1000,
    //         "status": "active",
    //         "smsAlert": true,
    //         "appAlert": true,
    //         "callAlert": true,
    //         "emailAlert": false,
    //         "pickupId": "YsFmRzKDvACrtjQhMXdu0568271724156022",
    //         "totalmember": 1
    //     },
    //     {
    //         "routeId": "GclOwKieuLTgqYPXhaWt2635891724155702",
    //         "expectedTime": "17:52",
    //         "pickuppoint": "Alandur court",
    //         "pickuppointLocation": { "lat": 12.9976877, "lng": 80.1917222 },
    //         "pickupAddress": "COMBINED COURT BUILDING, JUDICIAL COURT-ALANDUR, SH 48, Alandur, Chennai, Tamil Nadu 600016, India",
    //         "pickupRadius": 700,
    //         "status": "active",
    //         "smsAlert": true,
    //         "appAlert": true,
    //         "callAlert": true,
    //         "emailAlert": false,
    //         "pickupId": "FMVIxJvyzQbegBEUmwLG2643081724156080",
    //         "totalmember": 0
    //     },
    //     // more data...
    // ];
    excelDownloadStoppageData() {

        const pickUpData = this.routeStoppages.map((stoppage: any) => ({
            RouteId: stoppage.routeId,
            ExpectedTime: stoppage.expectedTime,
            PickupPoint: stoppage.pickuppoint,

        }));

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(pickUpData);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Route Stoppages');

        XLSX.writeFile(wb, 'route_stoppages.xlsx');
    }




    timestampToDate(timestamp: any, type = 'YMD', splited = '/') {
        const strl = (timestamp + '').length;
        const tms = strl > 10 ? timestamp : timestamp * 1000;
        const d = new Date(tms);
        let temp;
        // console.log('timestamp',timestamp)
        const h = this.pad(d.getHours());
        const m = this.pad(d.getMinutes());
        const s = this.pad(d.getSeconds());
        if (type == 'DMY') {
            temp =
                this.pad(d.getDate()) +
                splited +
                this.pad(d.getMonth() + 1) +
                splited +
                d.getFullYear();
        } else if (type == 'DMY H:M:S') {
            temp =
                this.pad(d.getDate()) + splited +
                this.pad(d.getMonth() + 1) + splited +
                d.getFullYear() + splited +
                h + splited + m + splited + s;
        }

        return temp;
    }


    async getNearestLocation(pickuppointLocation: any, dayTrackerLogs: any, expectedTime: any) {
        let data = [];
        for (let dayTrackerLog of dayTrackerLogs) {
            let location = dayTrackerLog["location"];
            if ((location['lat'] != null && !Number.isNaN(location['lat'])) && (location['lng'] != null && !Number.isNaN(location['lng']))) {
                let distance = this.calcCrow(pickuppointLocation['lat'], pickuppointLocation['lng'], location['lat'], location['lng']);
                if (distance <= 55) {
                    if (dayTrackerLog['gpsTimeStamp'] && dayTrackerLog['speed'] <= 5) {
                        let millisecond: any = new Date(dayTrackerLog['st']);
                        let reachedTime = this.pad(millisecond.getHours()) + ':' + this.pad(millisecond.getMinutes()) + ':' + this.pad(millisecond.getSeconds());
                        let difference = this.getTimeDuration(expectedTime, reachedTime);
                        if (60 >= difference) {
                            data.push({ reachedTime: reachedTime, difference: difference, ts: dayTrackerLog['st'] });
                        }
                    }
                }
            }
        }
        let returnData = {};
        if (data.length > 0) {
            data = data.sort((a, b) => {
                if (a.difference === b.difference) {
                    return a.ts - b.ts;
                }
                return a.difference - b.difference;
            });
            returnData = data[0];
        }
        return returnData;
    }

    pad(num: number) {
        return num > 9 ? num : '0' + num;
    }

    toRad(Value: any) {
        return Value * Math.PI / 180;
    }
    //This function alrady exists with kdtree.service file.
    calcCrow(lat1: any, lon1: any, lat2: any, lon2: any) {
        let R = 6371e3;  // meter
        let dLat = this.toRad(lat2 - lat1);
        let dLon = this.toRad(lon2 - lon1);
        var lat1: any = this.toRad(lat1);
        var lat2: any = this.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    getTimeDuration(EtaTime: any, GpsTime: any) {
        let today = new Date();
        let startTime: any = new Date(today.toDateString() + " " + EtaTime);
        let endTime: any = new Date(today.toDateString() + " " + GpsTime);
        let minutesDifference = Math.floor((endTime - startTime) / (1000 * 60));
        return Math.abs(minutesDifference);
    }

    getTimeStatus(EtaTime: any, GpsTime: any) {
        let splitEtaTime = EtaTime.split(":");
        let splitGpsTime = GpsTime.split(":");
        let genEtaTime = new Date();
        let genGpsTime = new Date();
        genEtaTime.setHours(splitEtaTime[0], splitEtaTime[1], 0, 0);
        genGpsTime.setHours(splitGpsTime[0], splitGpsTime[1], 0, 0);
        let retStatus = '';
        if (genEtaTime.getTime() === genGpsTime.getTime()) {
            retStatus = 'ontime';
        } else if (genEtaTime.getTime() < genGpsTime.getTime()) {
            retStatus = 'delay';
        } else if (genEtaTime.getTime() > genGpsTime.getTime()) {
            retStatus = 'earlier';
        }
        return retStatus;
    }

    sleep(num: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, Number(num));
        });
    }







}