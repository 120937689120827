/**
 * @Date:   2017-11-15T11:10:06+05:30
 * @Last modified time: 2018-04-30T12:50:04+05:30
 */



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/Rx';

import 'rxjs';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/map';


@Injectable()
export class ApiService {
  [x: string]: any;

  //private base = 'https://findyourschoolbus.in/api/index.php?path=';

  //public base = 'http://172.16.0.224/SchoolApi/School-Bus-API/';
  // private base = 'http://172.16.0.196/Group10/SchoolBus/Gitlab/API/v1/index.php?path=';
  // private base = 'http://localhost/group10/customers/schoolbus/GITLAB/api/app-raw/';
  // private base='https://findyourschoolbus.in/api/v2/';

  //public base = 'https://api-school.placer.in/api/v2/index.php?path='; // API URL using for Mobile APP

  public base = 'https://api-school.placer.in/api/v2/index.php?path=';
  // public base = "https://dev-school.mach2mach.com/api/v2/index.php?path=";
  private result: any;

  constructor(private http: HttpClient) {
    // this.getEnvironmentBase();
  }

  getEnvironmentBase() {
    const href = window.location.href;
    if (href.includes('13.213.3.113')) {
      //this.base = "http://13.213.3.113:8088/api/v2/index.php?path=";
      this.base = "http://13.213.3.113:8088/api/v2/index.php?path=";
    }
  }



  //Organization
  /*
      orgCreate(data){ var result:any;
        return  this.post('organization/create',data)
     }
  
  /*
  
   Client Side
  
   this.orgCreate({---data---}).then(response => {
                   result=response
                 })
                 .catch(error => {
                     console.log('Error while logging in', error)
                 });
  
  
     */
  orgAuthentication(data: any, loginType: any, apiURl = 'organization/orguserauthentication') {
    // var apiURl = '';
    // if(loginType == 'orgadmin'){
    //   apiURl = 'organization/orgadminauthentication';
    // }else if(loginType == 'admin'){
    //   apiURl = 'admin/adminauthentication'
    // }else{
    //   apiURl = 'organization/orguserauthentication';
    // }
    return this.post(apiURl, data);
  }

  orgCreate(data: any) {
    return this.post('admin/create', data);
  }

  orgUpdate(data: any) {
    return this.post('admin/edit', data);
  }

  orgFullUDetailsUpdate(data: any) {
    return this.post('admin/update_orgFullDetails', data);
  }

  orgSettingDetailsUpdate(data: any) {
    return this.post('admin/orgFullSync', data);
  }

  orgView(data: any) {
    // console.log(data);
    return this.post('admin/view', data);
  }

  orgDelete(data: any) {
    return this.post('admin/orgdelete', data);
  }

  orgUserCreate(data: any) {
    return this.post('admin/newuser', data);
  }

  orgUserUpdate(data: any) {
    return this.post('admin/updateuser', data);
  }

  orgUserView(data: any) {
    return this.post('admin/viewusers', data);
  }

  orgUserDelete(data: any) {
    return this.post('admin/deleteuser', data);
  }

  orgTrackerCreate(data: any) {
    return this.post('admin/newtracker', data);
  }

  orgTrackerUpdate(data: any) {
    return this.post('admin/updatetracker', data);
  }

  orgTrackerView(data: any) {
    return this.post('admin/viewtrackers', data);
  }
  orgTrackerdelete(data: any) {
    return this.post('admin/deletetracker', data);
  }

  addOrgHoliday(data: any) {
    return this.post('organization/create_holidays', data);
  }

  /*Holiday API starts*/
  getOrgHolidays(data: any) {
    return this.post('organization/view_holidays', data);
  }

  deleteOrgEvent(data: any) {
    return this.post('organization/delete_holidays', data);
  }

  editOrgHoliday(data: any) {
    return this.post('organization/edit_holidays', data);
  }

  addClassHoliday(data: any) {
    return this.post('organization/org_createclassholidays', data);
  }

  viewClassHoliday(data: any) {
    return this.post('organization/org_viewclassholidays', data);
  }

  deleteClassHoliday(data: any) {
    return this.post('organization/org_deleteclassholidays', data);
  }

  /*Holiday API ends*/

  createRoute(data: any) {
    return this.post('Routedetails/create_route', data);
  }

  getRoutes(data: any) {
    return this.post('Routedetails/route_view', data);
  }

  deleteRoute(data: any) {
    return this.post('Routedetails/route_delete', data);
  }

  editRoute(data: any) {
    return this.post('Routedetails/edit_route', data);
  }

  cloneRoute(data: any) {
    return this.post('Routedetails/clone_routes', data);
  }

  moveRoute(data: any) {
    return this.post('Routedetails/move_pickuppoints', data);
  }

  swapRoute(data: any) {
    return this.post('Routedetails/swap_routes', data);
  }

  getRouteStoppages(data: any) {
    return this.post('Routedetails/view_pickuppoints', data);
  }

  getStoppageDetails(data: any) {
    return this.post('Routedetails/view_pickuppoints', data);

  }

  createPickUpPoint(data: any) {
    return this.post('Routedetails/create_pickuppoint', data);
  }

  editPickUpPoint(data: any) {
    return this.post('Routedetails/edit_pickuppoint', data);
  }

  deletePickupPoint(data: any) {
    return this.post('Routedetails/delete_pickuppoint', data);
  }

  createMember(data: any) {
    return this.post('memberdetails/create_member', data);
  }

  viewMember(data: any) {
    return this.post('memberdetails/view_members', data);
  }

  editMember(data: any) {
    return this.post('memberdetails/edit_member', data);
  }

  deleteMember(data: any) {
    return this.post('memberdetails/delete_member', data);
  }

  AssignMember(data: any) {
    return this.post('Routedetails/assign_member', data);
  }
  deleteAssignedRoute(data: any) {
    return this.post('Routedetails/delete_assignedMember', data);
  }

  getVehicleCameraLiveStream(data: any) {
    return this.post('Routedetails/get_vehicle_livestream', data);
  }
  //route api
  getCurrentLocation(data: any) {
    return this.post('organization/org_trackers', data);
  }

  getOrgVehicles(data: any) { // gives only latest updated devices.
    return this.post('Routedetails/available_tracker', data);
  }

  getAllOrgDevices(data: any) {
    return this.post('organization/org_totalvehiclelist', data);
  }

  viewCount(data: any) {
    return this.post('organization/org_dashboardcounts', data);
  }

  viewAdminCount(data: any) {
    return this.post('admin/admin_dashboardcounts', data);
  }

  viewRouteHistory(data: any) {
    return this.post('Routedetails/get_updatetiming', data);
  }
  viewVehicleNotMoved(data: any) {
    return this.post('organization/org_vehiclenotmoved', data);
  }
  revertRouteHistory(data: any) {
    return this.post('Routedetails/revert_routetiming', data);
  }

  createNewOrgConfiguration(data: any) {
    return this.post('Routedetails/change_routetiming', data);
  }

  getCurrentOrgDetails(data: any) {
    // console.log('DATA',data)
    return this.post('organization/currentorg_view', data);
    // let rest =  this.post('organization/currentorg_view', data);
    // console.log('RESULT', rest);
    // return rest;
  }

  editOrgDetails(data: any) {
    return this.post('organization/currentorg_edit', data);
  }

  manageRouteConfigurations(data: any) {
    return this.post('Routedetails/config_routealerts', data);
  }

  timelineGraph(data: any) {
    return this.post('dashboard/get_activeroutes', data);
  }

  activeRouteDetails(data: any) {
    return this.post('dashboard/get_singleactiveroute', data);

  }

  /* Reports API start */

  getCommonReport(data: any) {
    return this.post('tracking/view_trackingdata', data);
  }

  getAllVehicleAllRouteReport(data: any) {
    return this.post('organization/org_routevehicledetails', data);
  }

  viewLastUpdatedStatus(data: any) {
    return this.post('organization/org_vehicleLastUpdates', data);
  }

  getRoutePickUpHistory(data: any) {
    return this.post('report/route_stopagereports', data);
  }

  getNewRoutePickupHistory(data: any) {
    return this.post('report/route_stopagereportsnew', data);
  }
  getNewCallMadeReport(data: any) {
    return this.post('Proxialert/get_callTriggeredRouteLogNew', data);
  }
  getCallMadeReport(data: any) {
    return this.post('Proxialert/get_callMadeReport', data);
  }

  getMemberPickupLog(data: any) {
    return this.post('report/member_stopagereports', data);
  }

  getMovementReport(data: any) {
    return this.post('tracking/view_trackingdata', data);
  }

  getDaySummary(data: any) {
    return this.post('tracking/daysummary_reports', data);
  }

  getPanicReport(data: any) {
    return this.post('report/panic_reports', data);
  }

  getGeneralRFIDReport(data: any) {
    return this.post('report/general_rfid_reports', data);
  }

  getRFIDRoutewiseCount(data: any) {
    return this.post('report/routewise_rfid_countreport', data);
  }

  getCustomRFIDReport(data: any) {
    return this.post('report/rfid_reports', data);
  }

  getCallAlertLog(data: any) {
    return this.post('Proxialert/get_allalertlog', data);
  }
  getAllPPET(data: any) {

    return this.post('Routedetails/getallppETA', data);
  }
  getAllPPReachedTime(data: any) {

    return this.post('Routedetails/getallPPReachTime', data);
  }
  getAllPPList(data: any) {
    return this.post('Routedetails/get_allroutewiseppts', data);
  }

  getCallALertLogCout(data: any) {
    return this.post('Proxialert/get_proxialertcounts', data);
  }

  getHaltDurationLog(data: any) {
    return this.post('tracking/view_haltduration', data);
  }

  getAppAlertLog(data: any) {
    return this.post('Proxialert/get_appalertlog', data);
  }

  getSmsAlertLog(data: any) {
    return this.post('Proxialert/get_smsalertlog', data);
  }

  getHistoryData(data: any) {
    return this.post('tracking/view_routetrackingdata', data);
  }

  getRfidAppPush(data: any) {
    return this.post('report/rfidappp_reports', data);
  }

  getTabAttendanceReport(data: any) {
    return this.post('report/tabattendance_reports', data);
  }

  /* Reports API end */

  /*Notifications API start */
  createNotification(data: any) {
    return this.post('organization/create_org_notification', data);
  }
  viewNotifications(data: any) {
    return this.post('organization/org_notification_view', data);
  }
  editNotification(data: any) {
    return this.post('organization/org_notification_edit', data);
  }
  deleteNotification(data: any) {
    return this.post('organization/org_notification_delete', data);
  }

  /*Notifications API @end */

  /*Org mobile configuration API starts*/
  getOrgMobiles(data: any) {
    return this.post('approval/user_registeredmobilelist', data);
  }

  getOrgEmails(data: any) {
    return this.post('approval/user_registeredemaillist', data);
  }

  validateEmails(data: any) {
    return this.post('approval/validating_useremail', data);
  }

  validateMobileNumber(data: any) {
    return this.post('approval/validating_mobilenumber', data);
  }

  verifyuserEnteredOtp(data: any) {
    return this.post('approval/user_otpvalidation', data);
  }

  verifyuserEnteredEmailOtp(data: any) {
    return this.post('approval/user_emailotpvalidation', data);
  }

  resendOtp(data: any) {
    return this.post('approval/user_otpresend', data);
  }

  deleteRegisteredMobile(data: any) {
    return this.post('approval/delete_registeredmobileno', data);
  }

  deleteRegisteredEmail(data: any) {
    return this.post('approval/delete_registeredemail', data);
  }
  /*Org mobile configuration API end*/

  /*campaign API start */

  createOrgCampaign(data: any) {
    return this.post('broadcasting/send_broadcastmessages', data);
  }

  viewOrgCampaigns(data: any) {
    return this.post('broadcasting/getCampaignList', data);
  }

  getAvailableCampaignTenplates(data: any) {
    return this.post('broadcasting/view_msgtemplate_org', data);
  }

  createCampaignTemplate(data: any) {
    return this.post('broadcasting/create_msgtemplate', data);
  }
  viewOrgMessageTemplateAdmin(data: any) {
    return this.post('broadcasting/view_msgtemplate_admin', data);
  }
  editCampaignTemplate(data: any) {
    return this.post('broadcasting/edit_msgtemplate', data);
  }

  deleteCampaignTemplate(data: any) {
    return this.post('broadcasting/delete_msgtemplate', data);
  }

  /*campaign API end */

  getparentAppDetails(data: any) {
    return this.post('admin/view_appinstalled_details', data);
  }

  updateorgPassword(data: any) {
    return this.post('organization/orguser_passwordchange', data);
  }

  getUserLogHistory(data: any) {
    return this.post('organization/orguser_loginhistory', data);
  }

  forgotPassword(data: any) {
    return this.post('organization/orguser_forgetpassword', data);
  }

  resetPassword(data: any) {
    return this.post('organization/orguser_updatenewpassword', data);
  }

  getVehicleId(data: any) {
    return this.post('organization/orgTrackerLists', data);
  }

  trackerLogHistory(data: any) {
    return this.post('admin/view_trackerhistory', data);
  }

  mobileAppAlertSettings(data: any) {
    return this.post('admin/viewedit_appsetting', data);
  }

  resetPasswordSysAdmin(data: any) {
    return this.post('admin/orgadmin_updatepassword', data);
  }

  viewAdminProfile(data: any) {
    return this.post('admin/view_adminuser', data);
  }
  /*Map Listener Services for Events */

  viewRouteWiseCallReport(data: any) {
    return this.post('Proxialert/get_callTriggeredRouteLog', data);
  }

  getCustomRFIDAttendenceReport(data: any) {
    return this.post('report/cummulative_clsattendance_reports', data);
  }

  getVehicleReachLog(data: any) {
    return this.post('Proxialert/get_vehicleppreachlog', data);
  }

  getStudentPickupLog(data: any) {
    return this.post('Proxialert/get_studentpickuplog', data);
  }

  otherOverspeedsLog(data: any) {
    return this.post('report/get_notificationreports', data);
  }
  markerAddListner() {

  }

  rfidSMSLogReports(data: any) {
    return this.post('report/rfidsms_reports', data);
  }

  notificationLog(data: any) {
    return this.post('report/get_notifications_send_reports', data);
  }

  vehicleBasedNotification(data: any) {
    return this.post('report/get_vehicle_based_notifications', data);
  }

  rfidStatusCheck(data: any) {

    return this.post('report/get_rfid_working_status', data);
  }
  /*Map Listener events ends here */

  addRole(data: any) {
    return this.post('organization/org_addrole', data);
  }

  listRoles(data: any) {
    return this.post('organization/org_viewrole', data);
  }

  editRole(data: any) {
    return this.post('organization/org_editrole', data);
  }
  deleterole(data: any) {
    return this.post('organization/org_deleterole', data);
  }

  newUser(data: any) {
    return this.post('organization/org_adduser', data);
  }

  listUsers(data: any) {
    return this.post('organization/org_viewuser', data);
  }
  updateUser(data: any) {
    return this.post('organization/org_edituser', data);
  }

  deleteUser(data: any) {
    return this.post('organization/org_deleteuser', data);
  }

  getBreakDownMsg(data: any) {
    return this.post('report/get_breakdownnotify_reports', data);
  }

  getadminReport(url: string, data: any) {
    return this.post(url, data);
  }
  // excel configuration-student_route_management
  getExcelconfigurationList(data: any) {
    return this.post('Organization/view_student_route_excelconfig', data);
  }
  getExcelconfigurationUpdate(data: any) {
    return this.post('Organization/edit_student_route_excelconfig', data);
  }

  getStudentRouteExcelUpload(data: any) {
    return this.post('Organization/student_route_excel_upload', data)
  }

  // sysadmin mobile-info student details

  getStudentInfoMobile(data: any) {
    return this.post('admin/view_studentinfo_details', data)
  }

  // route analytics
  getRoute(data: any) {
    return this.post('Organization/routeView', data)
  }
  // routetime extended_multiroute edit
  editMultiRoute(data: any) {
    return this.post('Routedetails/edit_multiroute', data)
  }

  // routeadhoc
  viewRouteAdhoc(data: any) {
    return this.post('Routedetails/viewAdhocRoutes', data)
  }
  createMultiRouteAdhoc(data: any) {
    return this.post('Routedetails/createAdhocMultiRoutes', data)
  }

  viewAdhocMultiRoutes(data: any) {
    return this.post('Routedetails/viewAdhocMultiRoutes', data)

  }

  createRouteAdhoc(data: any) {
    return this.post('Routedetails/createAdhocRoutes', data)
  }

  // all route download
  getallRroutePickupPoints(data: any) {
    return this.post('Routedetails/getall_route_pickupPoints', data)
  }
  // vehicle management create
  createVehicleTrack(data: any) {
    return this.post('Organization/create_org_trackers', data)
  }
  editVehicleTrack(data: any) {
    return this.post('Organization/edit_org_trackers', data)
  }
  viewVehicleTrack(data: any) {
    return this.post('Organization/view_org_trackers', data)
  }
  viewDeviationReport(data: any) {
    return this.post('report/route_deviation_reports', data)

  }
  // face attendance report
  faceAttendanceReport(data: any) {
    return this.post('faceapp/face_attendancereport', data)
  }

  // http://13.213.3.113:8088/api/v2/index.php?path=report/route_deviation_reports

  private get(url: any) {
    return this.http.get(this.base + url)
      .toPromise()
      .then((res: any) => {
        // console.log('res', res);
        return res.json();
      });
    // .map(res => res.json())
  }



  /*
      private post(url:any, params:any) {
  
      var data = JSON.stringify( params );
  
      let headers:any = new Headers({
      // 'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT' ,
      // 'Cache-Control':'no-cache',
    //   'Pragma':'no-cache'
      });
  
          let options = new RequestOptions({ headers: headers });
  
  
       return this.http.post(this.base + url , data , options)
                     .toPromise()
                     //.then(res => res.json())
                     .then( (res:any)=>{
                         let jsonData = res.json();
                         if(jsonData.response=="Authentication failed"){
                           window.location.href="/#login";
                        }
                         return jsonData;
                     })
                     .catch(res => {res.json()
                       let JSONdata = res.json();
                       if (JSONdata.response=="key Expired"){
                         window.location.href="/#login";
                       }
                       return JSONdata;
  
                     })
       }
       */


  // post(url:string,params:any) {

  //   const data = JSON.stringify( params );
  //   let headers:any = new HttpHeaders();
  //   headers = headers.set('Content-Type', 'application/json; charset=utf-8');
  //   return this.http.post(this.base + url , data , headers )
  //                  .toPromise()
  //                  .then( (res:any) => { 
  //                    if(res.status === 401 ){
  //                     window.location.href = '/#login';
  //                    }
  //                   return res;
  //               })
  //               .catch( (res:any) => {
  //                 if ( res.status === 401) {
  //                   window.location.href = '/#login';
  //                 }
  //                 return res;

  //               });
  // }

  post(url: string, params: any) {
    // console.log('post', + url, + params);
    const data = JSON.stringify(params);
    let headers: any = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(this.base + url, data, headers)
      .toPromise()
      .then((res: any) => {
        if (res.ec === 'SCB3' || res.ec === 'SCB2') {
          window.location.href = '#login';
        }
        // console.log('reeee',res);
        return res;
      })
      .catch((res: any) => {
        if (res.status === 401 && url === 'organization/orgadminauthentication') {
          window.location.href = '#login';
          localStorage.setItem("scbToken", '');
        }
        else if (res.status === 401 && url === 'admin/adminauthentication') {
          window.location.href = '#sysAdmin/admin-login';
          localStorage.setItem("adminToken", '');
        }
        return res;

      });


  }




  // Socket connection using push pin.//https://api-school.placer.in/api/v2/
  pushpin() {
    let headers: any = new Headers();
    let apiToken = encodeURI(localStorage.getItem('scbToken'));
    // console.log('connecting...');
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return new Promise((resolve, reject) => {
      let xhr: any = new XMLHttpRequest();
      let milliseconds = new Date().getTime();
      let url = 'https://api-school.placer.in/stream/channel.php?token=' + apiToken;
      xhr.open("GET", url, true);
      xhr.onprogress = function (e: any) {
        let resData = e.currentTarget.responseText;

        try {
          resolve(JSON.parse((resData)));
        } catch (e) {
          reject('error');
        }

      };
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          //   console.log("Complete = " + xhr.responseText);
        }
      };
      xhr.send();
    });
  }



}
