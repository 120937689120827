
import { Component, Input, Output } from '@angular/core';
import { ApiService } from '../../../services/ApiService';
import { chosenUtility } from '../../../services/chosen-utility/jq-chosen-utility.js';
import { bootstrapDateTime } from '../../../services/bootstrapDateTime/datePicker';
import { GlobalUtils } from '../../../services/global-utils';
declare const $: any;
import { DOCUMENT } from "@angular/platform-browser";
import { Objectkeys } from '../../../custom-pipes/objectkeys.pipe';
import { containsElement } from '@angular/animations/browser/src/render/shared';
import { IncrementDir } from '../../../pages/increment-index/inc';
import { setRootDomAdapter } from '@angular/platform-browser/src/dom/dom_adapter';
import { StorageService } from '../../../services/storage.service';
import { COM } from '../../../services/com.services';
import { Router } from '@angular/router';
import { RouteSwapConfig } from '../../configuration/route.swap.dir';
import { ApiCallDateFormatService } from "../../../services/api-call-date-format.service";
import { DatePipe } from '@angular/common';
import { from } from 'rxjs';
import * as XLSX from 'xlsx';

declare const Pickathing: any;
interface IAvailableReports {
    general: any,
    RFIDWithClass: any,
    RFIDWithMember: any,
    RFIDWithRoute: any,
    RFIDWithAttendance: any,
    RFIDWithSmsLog: any,
    RFIDStatusCheck: any,
    RFIDRouteWiseCount: any,
    RFIDAppPush: any,
}


@Component({
    selector: 'face-attendance-reports-section',
    templateUrl: './face-attendance-reports.html',
    styleUrls: ['./face-attendance-reports.css']
})
export class faceAttendanceReportsComponent {
    public rfidInfoEndDateError: boolean = false; // Added property
    public rfidInfoStartDateError: boolean = false; // Added property

    public displayErrorMessage: string = '';
    public reportSections = {
        memberwiseAttendance: false,
        routewiseAttendance: false,
        classwiseAttendance: false
    }
    public reportSectionsContent = {
        memberwiseAttendance: false,
        routewiseAttendance: false,
        classwiseAttendance: false
    }

    private reportLookUpTitle = {
        memberwiseAttendance: 'Memberwise Attendance',
        routewiseAttendance: 'Routewise Attendance',
        classwiseAttendance: 'Classwise Attendance'
    }

    private reportLookUpFunction = {

        memberwiseAttendance: 'initMemberwiseAttendanceReport',
        routewiseAttendance: 'initRoutewiseAttendanceReport',
        classwiseAttendance: 'initclasswiseAttendanceReport'
    }
    public parameter: any;
    public reportOptions: any = [];
    public datesMisMatchError: boolean;
    public errStartDate: boolean;
    public errEndDate: boolean;
    private selectedReport: any;
    selectedroute: any;
    selectedAttendanceType: string = '';
    userSelected: boolean;
    framedApiHeader: any;
    public class: any = [];
    public section: any = [];
    public choosedClass: string = "";
    public selectedClass: string = ""; // Added selectedClass property
    public choosedSection: string = "";
    public choosedStatus: string = "";
    public selectedSection: string = ""; // Added selectedSection property
    public isClassRequired: boolean = false;
    selectedMember: any;
    selectedRouteTransmit: any;
    dateRange: boolean;
    public errResponse: any;
    public OrgClasses: any[];
    public orgSections: any[];
    public chooseClassError: boolean = false;
    public classReportExcelDownloadLink: string = ''; // Added property
    public isLoading: boolean = false; // Added property
    public generalRfidLog: any[] = []; // Added property to fix the error
    private intermediateObj: any;
    public classwiseAttendance: any[] = []; // Added property to fix the error

    constructor(private chosenUtility: chosenUtility, private apiService: ApiService,
        private bootstrapDateTime: bootstrapDateTime, private globalUtils: GlobalUtils,
        public storage: StorageService, public dateChange: ApiCallDateFormatService) {

        this.datesMisMatchError = false;
        this.errStartDate = false;
        this.errEndDate = false;
        this.class = this.storage.get('classandsection');
        this.section = this.class.listofsections;
        this.class = this.class.listofclasses;
        this.selectedroute = [];
        this.selectedMember = [];
        this.OrgClasses = [];
        this.orgSections = [];
        this.isClassRequired = true;

    }

    // get all configured attendance report objects
    @Input('availableReports')
    set availableReports(para: any) {
        this.parameter = para;
    }
    // get all reports objects
    @Input('AlertReports')
    set AlertReports(data: any) {
        if (data !== undefined) this.AlertControls(data.attendance);
    }

    ngOnInit() {
        this.pickathpluginInit(); //inititaiating picakth plugin
        this.initChosen();
        this.getAvailableClasses();

    }

    // check and route based on user controls
    AlertControls(obj: any) {
        let report
        if (localStorage.getItem('userInfo')) {
            report = JSON.parse(localStorage.getItem('userInfo'));
        }

        let uLevel = JSON.parse(localStorage.getItem('userLevel'));

        if (uLevel === 1) {
            let lone = Object.assign(this.parameter);
            this.generateOptions(lone);
        } else if (uLevel === 2) {
            let alertControl = Object.assign(this.parameter, report.reports.attendance);
            this.generateOptions(alertControl);
        }

    }
    generateOptions(param: any) {

        for (let obj in param) {
            let data = {};
            if (param[obj]) {
                data['reportName'] = this.reportLookUpTitle[obj];
                data['value'] = obj;
                this.reportOptions.push(data);
            }
        }
    }

    changeEventFired = (variablename: string, value: any) => {
        this[variablename] = value;
        //console.log("check :",value,this[variablename]);
    }

    changeAttendance(event: Event) {
        const target = event.target as HTMLSelectElement;
        this.selectedAttendanceType = target.value;
    }
    initReportSection({
        memberwiseAttendance = false,
        routewiseAttendance = false,
        classwiseAttendance = false
    } = {}) {
        let providedParam = Object.getOwnPropertyNames(arguments[0]);
        let calleFunction = this.reportLookUpFunction[providedParam[0]];
        this.reportSections.memberwiseAttendance = memberwiseAttendance;
        this.reportSections.routewiseAttendance = routewiseAttendance;
        this.reportSections.classwiseAttendance = classwiseAttendance;
        this[`${calleFunction}`](calleFunction);

        this.initReportSectionContent({});
        this.resetAllValues();
    }

    initReportSectionContent({
        memberwiseAttendance = false,
        routewiseAttendance = false,
        classwiseAttendance = false
    } = {}) {
        this.reportSectionsContent.memberwiseAttendance = memberwiseAttendance;
        this.reportSectionsContent.routewiseAttendance = routewiseAttendance;
        this.reportSectionsContent.classwiseAttendance = classwiseAttendance;
    }


    resetAllValues() {

        this.errStartDate = false;
        this.errEndDate = false;
        this.datesMisMatchError = false;
        this.userSelected = false;
        // this.choosedClass="";
        // this.choosedSection="";
        this.dateRange = false;
        this.displayErrorMessage = '';
    }

    initMemberwiseAttendanceReport() {
        this.initJqUtils(undefined, 'memStartDate', 'memEndDate', undefined);
        this.resetAllValues();
        return true;
    }
    initRoutewiseAttendanceReport() {


        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        this.resetAllValues();

    }
    initclasswiseAttendanceReport() {
        this.initJqUtils(undefined, 'routeHistoryStartDate', 'routeHistoryEndDate', undefined);
        this.resetAllValues();
    }
    bindJqChangeEvt(ele: any) {
        let parent = this;
        $('#' + ele).change(function (e: any) {
            parent.selectedReport = $(this).val();
            let obj = {};
            obj[parent.selectedReport] = true;
            parent.initReportSection(obj);


        });
    }
    initChosen() {
        this.bindJqChangeEvt('demo-select');
    }
    getAvailableClasses() {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken')
            }
        }
        this.apiService.getCurrentOrgDetails(apiHeader).then(response => {
            if (response.response) {
                this.OrgClasses = response.response.classLists;
                this.orgSections = response.response.SectionLists;
            }
        });
    }



    getErrorResponse(event: any) {
        this.displayErrorMessage = event.hasOwnProperty('ec') ? this.globalUtils.getErrorMsg(event.ec) : '';

    }
    receiveDateObject() {
        let stDate = $('#routeHistoryStartDate').val();
        let endDate = $('#routeHistoryEndDate').val();
        return this.genericDateValidate(stDate, endDate);
    }









    oneTimeInitJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        let closeOnDateSelect: boolean;
        // console.log(pickerConfig);
        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            // timePicker = pickerConfig.timePicker;
            // timeFormat = pickerConfig.timeFormat;
            // closeOnDateSelect = true;
            // } else {
            timePicker = false;
            // timeFormat = 'Y-m-d H:i:s';
            timeFormat = 'd-m-Y ';

            closeOnDateSelect = true;
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
        }, 150);


    }


    initJqUtils(eleChosenId?: string, eleReportStartTime?: string, eleReportEndTime?: string, pickerConfig?: any) {
        let parent = this;
        let timePicker: boolean;
        let timeFormat: any;
        let closeOnDateSelect: boolean;

        if ((pickerConfig) && (Object.keys(pickerConfig).length)) {
            timePicker = pickerConfig.timePicker;
            timeFormat = pickerConfig.timeFormat;
            closeOnDateSelect = true;
        } else {
            timePicker = true;
            // timeFormat = 'Y-m-d H:i:s';
            timeFormat = 'd-m-Y H:i:s';

            closeOnDateSelect = true;
        }
        setTimeout(() => {
            if (eleChosenId) this.chosenUtility.initializeChosen(eleChosenId);
            if (eleReportStartTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportStartTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
            if (eleReportEndTime) {
                parent.bootstrapDateTime.datetime({
                    component: eleReportEndTime,
                    timepicker: timePicker,
                    format: timeFormat,
                    closeOnDateSelect: closeOnDateSelect
                });
            }
            $('#eventStartTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '00:00',
                timepicker: true,
            });
            $('#eventEndTime').datetimepicker({
                format: 'H:i',
                datepicker: false,
                defaultTime: '23:59',
                timepicker: true,
            });
        }, 150);


    }
    //Jquery util for change event end.

    /*Jquery util to get control value without ngModel*/
    getControlValue(eleId: any) {
        return $('#' + eleId).val();
    }


    pickathpluginInit() {
        return new Promise(function (resolve, reject) {

            setTimeout(() =>

            (new Pickathing('demo-select', true, {
                searchLabel: 'Search for options...'
            }), resolve(true)), 500);

        }).then(function (res1) {

            return res1 && new Promise((res2, reject) => {

                var srchIpBox: any = document.getElementsByClassName('Pickathing-dropdown');

                var span = document.createElement('span');
                var i = document.createElement('i');

                span.setAttribute('id', 'pickathSearchOption');

                i.setAttribute('class', 'fa fa-search');

                srchIpBox[0].appendChild(span);
                span.appendChild(i);

                span.style.position = 'absolute';
                span.style.top = '16px';
                span.style.right = '19px';
                span.style.zIndex = '999';
                res2(true);
            });

        }).then(function (result) {

            return result == true && new Promise((res3, reject) => {
                res3(true);
            });

        }).then(function (result) {
            var targetButton: any = document.getElementsByClassName('Pickathing-option');
            targetButton[0].style.display = "none";

        });
    }
    setUserSelections(userSelectedValue: any) {
        this.selectedroute = userSelectedValue.length ? userSelectedValue : [];
    }
    genericDateValidate(param1: string, param2: string) {




        let stDate: any = param1 ? (param1).split('-') : false;
        stDate = Array.isArray(stDate) ? `${stDate[1]}/${stDate[0]}/${stDate[2]}` : false;
        // string is used for checking the length of date in unix timestamp
        let stTimeStamp = stDate ? new Date(stDate).getTime() : 'false';
        let stTimeStampString = stTimeStamp ? stTimeStamp.toString() : 'false';

        let endDate: any = param2 ? (param2).split('-') : false;
        endDate = Array.isArray(endDate) ? `${endDate[1]}/${endDate[0]}/${endDate[2]}` : false;
        // string is used for checking the length of date in unix timestamp
        let endTimeStamp = endDate ? new Date(endDate).getTime() : 'false';
        let endTimeStampString = endTimeStamp ? endTimeStamp.toString() : 'false';

        return {
            stTimeStamp: stTimeStamp,
            endTimeStamp: endTimeStamp,
            stTimeStampString: stTimeStampString,
            endTimeStampString: endTimeStampString,

        }
    }


    choosenClass($event: any) {

        return $event.target.value;

    }
    choosenSection($event: any) {
        this.choosedSection = $event.target.value;
    }

    receiveDateObjectForDatewise() {
        let stDate = $('#datewiseStartDate').val();
        let endDate = $('#datewiseEndDate').val();
        return this.genericDateValidate(stDate, endDate);
    }


    receiveDateObjectForMemWise() {
        let stDate = $('#memStartDate').val();
        let endDate = $('#memEndDate').val();
        return this.genericDateValidate(stDate, endDate);
    }
    viewAttendanceReportValidator() {
        this.resetAllValues();
        let isValid: boolean;
        isValid = true;
        let obj = this.receiveDateObject();
        if (obj.stTimeStampString.length <= 9) {
            this.errStartDate = true;
            isValid = false;
        }
        // if (obj.endTimeStampString.length <= 9) {
        //     this.errEndDate = true;
        //     isValid = false;
        // }

        // if (obj.endTimeStamp < obj.stTimeStamp) {
        //     this.datesMisMatchError = true;
        //     isValid = false;
        // }

        if (!this.selectedroute.length) {
            this.userSelected = true;
            isValid = false;
        }

        return isValid;

    }


    viewRoutewise(sectionContentId: any) {
        this.resetAllValues();
        if (this.viewAttendanceReportValidator()) {
            this.framedApiHeader = {};



            // this.selectedRouteTransmit = this.selectedroute.length && this.selectedroute[0]['routeId'] ? this.selectedroute :false;

            this.framedApiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        fromDate: $('#routeHistoryStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        // fromTime :  $('#routeHistoryStartDate').val().split(' ')[1]+':00',
                        fromTime: '00:00:00',
                        toDate: $('#routeHistoryStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        // toTime :  $('#routeHistoryStartDate').val().split(' ')[1]+':00',  
                        toTime: '23:59:00',
                        routeId: this.selectedroute[0]['routeId']

                    },

                }
            }
            // if(this.choosedStatus) this.framedApiHeader.data.filter['boardedstatus'] = this.choosedStatus;
            let obj = {
                [sectionContentId]: true
            };

            this.initReportSectionContent(obj);

        };
    }




    validatorMemWise() {

        let isValid: boolean = true;
        let obj: any = this.receiveDateObjectForMemWise();
        if (obj.stTimeStampString.length <= 9) {
            this.errStartDate = true;
            isValid = false;
        }
        if (obj.endTimeStampString.length <= 9) {
            this.errEndDate = true;
            isValid = false;
        }

        if (obj.endTimeStamp < obj.stTimeStamp) {
            this.datesMisMatchError = true;
            isValid = false;
        }
        if (!this.selectedMember.length) {
            this.userSelected = true;
            isValid = false;
        }

        if ((obj.endTimeStamp - obj.stTimeStamp) / 1000 >= (5 * 86400)) {
            this.errStartDate = false;
            this.errEndDate = false;
            this.dateRange = true;
            isValid = false;
        }

        return isValid;
    }
    setSelectedStudent(selectedItem: any) {
        this.selectedMember = selectedItem.length ? selectedItem : [];
    }

    viewMemberwise(sectionContentId: any) {
        this.resetAllValues();
        if (this.validatorMemWise()) {
            this.framedApiHeader = {};

            this.framedApiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        fromDate: $('#memStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        fromTime: $('#memStartDate').val().split(' ')[1] + ':00',
                        toDate: $('#memEndDate').val().split(' ')[0].split('-').reverse().join('-'),
                        toTime: $('#memEndDate').val().split(' ')[1] + ':00',
                        memberId: this.selectedMember.length && this.selectedMember[0].memberId ? this.selectedMember[0].memberId : false,

                    },

                }
            }

            let obj = {
                [sectionContentId]: true
            }

            this.initReportSectionContent(obj);
        }
    }





    // classwise


    initiateClasswiseRifdInputValidator() { //validator by Deepak.
        let isValid: boolean = true;
        //console.log("checkingggggggggggguuu :",this.selectedClass);
        if (!this.selectedClass) {
            this.chooseClassError = true;
            isValid = false;
        } else {
            this.chooseClassError = false;
        }


        let ClassInfoEndDate = $('#routeHistoryEndDate').val();
        let ClassInfoStartDate = $('#routeHistoryStartDate').val();

        let dateObj: any = this.genericDateValidate(ClassInfoStartDate, ClassInfoEndDate);
        if (dateObj.endTimeStampString.length <= 10) {
            // if (!rfidClassInfoEndDate) {
            this.rfidInfoEndDateError = true;
            isValid = false;
        } else {
            this.rfidInfoEndDateError = false;
        }
        if (dateObj.stTimeStampString.length <= 10) {
            // if (!rfidClassInfoStartDate) {
            this.rfidInfoStartDateError = true;
            isValid = false;
        } else {
            this.rfidInfoStartDateError = false;
        }

        if (dateObj.stTimeStampString.length && dateObj.endTimeStampString.length) {

            // if (rfidClassInfoEndDate && rfidClassInfoStartDate) {
            if (dateObj.stTimeStamp > dateObj.endTimeStamp) {
                // if ($('#routeHistoryStartDate').val() > $('#routeHistoryEndDate').val()) {
                this.datesMisMatchError = true;
                isValid = false;
            } else {
                this.datesMisMatchError = false;
            }
        }

        return isValid;
    }

    viewClassWise(sectionContentId: any) {

        if (this.initiateClasswiseRifdInputValidator()) {
            let apiHeader = {
                data: {
                    key: localStorage.getItem('scbToken'),

                    form: {
                        fromDate: $('#routeHistoryStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        fromTime: $('#routeHistoryStartDate').val().split(' ')[1] + ':00',
                        toDate: $('#routeHistoryEndDate').val().split(' ')[0].split('-').reverse().join('-'),
                        toTime: $('#routeHistoryEndDate').val().split(' ')[1] + ':00',
                        memberClass: this.selectedClass,
                        memberSection: this.selectedSection || null // Added memberSection property
                    }
                }
            }

            if (this.selectedSection) {
                apiHeader['data']['form']['memberSection'] = this.selectedSection;


            }

            this.isLoading = true;
            this.intermediateObj = {};

            this.apiService.faceAttendanceReport(apiHeader).then(response => {
                if (response.status === 'success') {
                    // this.displayErrorMessage = '';
                    let routeRepo = response.hasOwnProperty('response') ? response.response : response;

                    if (!routeRepo.ec) {
                        let arr: any = [];
                        let obj: { date?: string;[key: string]: any } = {};
                        Object.keys(routeRepo).forEach((item: any, index) => {
                            let validFormat = Array.isArray(routeRepo[item]);
                            this.classwiseAttendance = routeRepo[item];
                            console.log(this.classwiseAttendance);

                            if (!validFormat) {

                                Object.keys(routeRepo[item]).forEach((val: any, key: any) => {
                                    let obj: { date?: string; rType?: string } = {};
                                    obj['date'] = item;


                                });

                            } else {
                                obj['date'] = item;
                                obj['status'] = 'absent';

                            }
                            arr.push(obj);

                        });

                        // this.memwiseAttendance = JSON.parse(JSON.stringify(arr));



                    }
                    this.intermediateObj = {
                        reportStartDate: $('#routeHistoryStartDate').val().split(' ')[0].split('-').reverse().join('-'),
                        reportEndDate: $('#routeHistoryEndDate').val().split(' ')[0].split('-').reverse().join('-'),
                        selectedClass: this.selectedClass
                    }
                    let obj = {
                        [sectionContentId]: true
                    }

                    this.initReportSectionContent(obj);
                } else {
                    this.isLoading = false;
                    // this.displayErrorMessage = this.globalUtils.getErrorMsg(response.ec);
                }
            }).catch(error => { });

        }
    }


    getdate(value: any) {
        console.log(value);

    }
    sanitizeObject(obj: any) {
        let tempObj = {}
        Object.keys(obj).forEach((item: any) => {
            if (obj[item] !== '') {
                tempObj[item] = obj[item]
            }
        })
        return tempObj;
    }


    classwiseExcel() {
        if (!this.classwiseAttendance || this.classwiseAttendance.length === 0) {
            alert("No data available to export!");
            return;
        }
        let excelData = this.classwiseAttendance.map((item, index) => {
            return {
                "Sl. No": index + 1,
                "Student ID": item.memberUID,
                "Student Name": item.memberName,
                "Class": item.memberClass,
                "Section": item.memberSection,
                "Route Name": item.routeName,
                "Attendance Status": item.attendancestatus === 'true' ? 'Present' : 'Absent',
                "Date": new Date(item.ts).toLocaleDateString("en-GB"), // Convert timestamp to readable date
                "Time": new Date(item.ts).toLocaleTimeString("en-GB")  // Convert timestamp to time
            };
        });
        console.log(excelData);

        // Create a worksheet
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Attendance Report': worksheet },
            SheetNames: ['Attendance Report']
        };
        const currentDate = new Date();

        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Generate Excel file and trigger download
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = `Class_wise_face_attendance_report_${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, filename);


        // saveAs(data, `Attendance_Report_${new Date().toISOString().split('T')[0]}.xlsx`);
    }
}





