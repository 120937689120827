import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { ApiService } from './../../services/ApiService';
import { authGaurd } from './../../services/auth-guard.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../services/excel.service';
import * as XLSX from 'xlsx'

declare var swal: any;

@Component({
  templateUrl: './org-global-settings.html',
  styles: [`
       .close:hover {
         opacity: 1;
       }
       .close:before, .close:after {
         position: absolute;
         content: ' ';
         height: 33px;
         width: 2px;
         background-color: #333;
       }
       .close:before {
         transform: rotate(45deg);
       }
       .close:after {
         transform: rotate(-45deg);
       }
       .overlayContainer{
           height: 555px;
           overflow-y: auto;
           position: absolute;
           background: white;
           z-index: 999;
           left: 100%;
           width: 900px;
        }
        .overlayTitleContainer{
           padding:1.2%;
        }
        .settingOverlayTitle{
           font-size: 30px;
           font-weight: 400;
        }

        .overlay-nav-btn {
           padding: 9px 12px;
           border: 1px solid rgba(0, 0, 0, 0.09);
           border-radius: 0px;
           color:#0096b9!important;
           float:left;
        }

        button.overlay-nav-btn.btn-secondary.calenderview.overLayActiveTab {
           border-top: 3px solid #0095b9;
           padding-top: 7px;
        }

        button.btn-secondary.calenderview.overlay-nav-btn:focus {
             outline: none;
        }

        .overlayConfigTitle {
             cursor:pointer;
        }
        .overlayActiveTitleBorder{
             border: 0.1px solid;
             height: 1px;
        }


        .search-box {
          display: inline-block;
          width: 100%;
          border-radius: 3px;
          padding: 4px 55px 4px 15px;
          position: relative;
          background: #fff;
          border: 1px solid #ddd;
          -webkit-transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
        }
        .search-box.hovered, .search-box:hover, .search-box:active {
          border: 1px solid #aaa;
        }
        .search-box input[type=text] {
          border: none;
          box-shadow: none;
          display: inline-block;
          padding: 0;
          background: transparent;
        }
        .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
          box-shadow: none;
        }
        .search-box .search-btn {
          position: absolute;
          right: 2px;
          top: 2px;
          color: #aaa;
          border-radius: 3px;
          font-size: 21px;
          padding: 5px 10px 1px;
          -webkit-transition: all 200ms ease-in-out;
          -moz-transition: all 200ms ease-in-out;
          transition: all 200ms ease-in-out;
        }
        .search-box .search-btn:hover {
          color: #fff;
          background-color: #8FBE00;
        }

        .overlayHistoryContent , .overlayNewConfigContent{
           padding:2%;
        }
        .overlayRouteHistory > tbody > tr > td{
           padding: 20px;
        }
        .overlayRouteLogRevert{
           border: 1px solid #607D8B;
           padding: 9px;
           cursor: pointer;
        }
        .tab-content{
           margin-top:4%;
        }
        .applyFilterBtn{
           padding: 1.5%;
           font-size: 14px;
        }
        .fixed-table-header{
           font-weight: 500;
           padding: 1%;
           table-layout: fixed;
        }
        .bg-info{
           background-color: #d9edf73b;
        }

        li.list-group-item{
           padding: 2% 4% 5% 4%;
        }
        label.otherConfigurationLabel{
           text-align:left;
        }
        .sourceRouteInfo , .newRouteFields{
           border: 1px solid #00000026;
           padding-top: 1%;
        }
        input.ng-invalid{
           border:1px solid #f4433661;
        }
        
        .custom-angular-control{
             width: 35%!important;
             height: 35px !important;
             color: #6b6b76 !important;
             border: 1px solid rgb(205, 205, 211);
             border-radius: 3px !important;
             background-color: #fff;
             text-align: center;
             outline: none;
        }
        .v-center{
            vertical-align:inherit;
        }

        .routeListContainer{
          max-height:350px;
          overflow-y:auto;
          overflow-x:hidden;
        }

.route-tag{
  height:45px !important;
}

       `],
})


export class orgGlobalConfiguration {
  public showSearchFilters: boolean = false;
  public showTimeConfiguration: boolean = true;
  public showAlertConfiguration: boolean = false;
  public showOtherConfiguration: boolean = false;
  public showHistoryTabContent: boolean = true; //showing history content by default.
  public showRouteMoveSettings: boolean = false;
  public showRouteSwapConfiguration: boolean = false;
  public flagSet: boolean = false;
  public notMovedData: any = [];
  public orgRoutes: any[];
  public newAlertConfigRoutes: any = [];
  public newConfigRoutes: any = [];
  //private selectedRoute:any;;
  public routeDelayedOptions: any;
  public routeDelayHours: any;
  public routeDelayComments: any;
  public expandedIndex: any = -1;
  public selectedRouteStoppages: any = [];
  public selectedRouteToCopy: any;
  public pickupPointsCopyList: any = [];
  public noMoredata: boolean;
  // public routePageJump: number = 0;
  public pageJump: number = 0;
  private selectedRoute: any;
  private RoutesGlobalFilterObj: any = {};
  private schoolRoutes: any = [];
  private baseRouteName: string;
  public clearRout: boolean = false;
  private baseRouteStartTime: any = '00:00';
  private baseRouteEndTime: any = '00:00';
  private baseRouteVehAssigned: any;
  private newRouteSelectedVehicle: string;
  private newClonedRouteName: string;
  private newRouteStartTime: any;
  private newRouteEndTime: any;
  private newRouteVehAssigned: any;
  private userInput: any = { pickupPointETA: {} };
  private routeCloneForm: any;
  private formValues: any = [];
  private dynamicValues: any = [];
  private vehAvailable: any = [1, 2, 3];
  private routeHistory: any = [];
  private vehicleNotMovedHistory: any = [];
  private pickUpNotifconfigs: any = [];
  private showPickupPoints: boolean = false;
  private showPickupPointsEta: boolean = false;
  private userOperationData: any = {};
  private newConfigFilterObj = {};
  public viewMore1: boolean = true;

  /*route swwap utils Starts here */

  /*route swwap utils Starts here */

  public showDialog: boolean = false;
  public showSuccessDialog: boolean = false;
  public routeRevertConfirmation: boolean = false;
  public showRouteRevertSuccess: boolean = false;
  public showRouteRevertFailure: boolean = false;
  public orgNewConfigConfirmation: boolean = false;
  public routeAlertsConfirmation: boolean = false;
  public showConfigCreationSuccess: boolean = false;
  public showconfigCreationFailure: boolean = false;
  public showRouteAlertSuccess: boolean = false;
  public showRouteAlertFailure: boolean = false;

  public configFilterObj: any = {};
  public configFilterRouteName: any;
  public configFilterRouteName1: any;
  public configFilterRouteStartTime: any;
  public configFilterRouteEndTime: any;
  public configFilterRouteTags: any;
  public createConfigError: boolean;
  public configObj: any = {};
  rType: any;
  public isLoading: boolean;
  private isScrolled: boolean = false;
  public limitOfRecords: number = 50;
  constructor(private apiService: ApiService, public fb: FormBuilder, public authGaurd: authGaurd, private datePipe: DatePipe, private excel: ExcelService) {
    this.authGaurd.checkToken();
    this.isLoading = true;

  }

  ngOnInit() {
    this.routeCloneForm = this.fb.group({});
    this.viewRouteHistory();
    this.processOrgRoutes(true);

    console.log(this.pageJump)
  }

  @Output() hideOverlay: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showAppDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  processOrgRoutes(isFirstApiCall: boolean) {

    if (isFirstApiCall) {
      this.orgRoutes = [];
      this.newConfigRoutes = [];
      this.newAlertConfigRoutes = [];
      // this.pageJump = 0;

    }
    var routeObj = {};

    if (this.selectedRoute == undefined && this.configFilterRouteTags == undefined) {
      // this.routePageJump=0;
      routeObj = {
        data: {
          key: localStorage.getItem('scbToken'),
          extra: {
            pageJump: this.pageJump,

          }
        }
      };

    } else if (this.selectedRoute == '' && this.configFilterRouteTags == '') {
      // this.routePageJump=0;
      routeObj = {
        data: {
          key: localStorage.getItem('scbToken'),
          extra: {
            pageJump: this.pageJump,


          }
        }
      };

    } else {
      routeObj = {
        data: {
          key: localStorage.getItem('scbToken'),
          filter: this.configFilterObj,
          extra: {
            pageJump: this.pageJump,


          },
        }

      };
    }


    this.apiService.getRoutes(routeObj).then(response => {
      //if (response) {
      //if(response!='' && response!=undefined && response!=null)  {
      if (response.response) {
        if (this.orgRoutes.length) {
          this.orgRoutes = [...this.orgRoutes, ...response.response]
        } else { this.orgRoutes = response.response; }
        if (this.newConfigRoutes.length) {
          this.newConfigRoutes = [...this.newConfigRoutes, ...response.response]
        } else {
          this.newConfigRoutes = response.response;
        }
        if (this.newAlertConfigRoutes.length) {
          this.newAlertConfigRoutes = [...this.newAlertConfigRoutes, ...response.response]

        } else {
          this.newAlertConfigRoutes = response.response;
        }

        // console.log(response.response)

        // this.orgRoutes = response.response



        // this.newConfigRoutes = response.response; //return;

        // this.newAlertConfigRoutes = response.response


        for (var index in this.newConfigRoutes) {
          var obj = {
            checked: false
          }
          //  Object.assign(this.newConfigRoutes[index], obj);
        }
        this.noMoredata = false;
      } else {
        this.noMoredata = true;
        // this.pageJump = 0;

      }
      //}
      //};
      this.isLoading = false; //Loader Var

    }).catch(error => { });

  }





  pagination(type: string) {
    this.newConfigRoutes = [];
    if (type == 'next') {

      this.pageJump++;
      console.log(this.pageJump)
    } else if (type == 'prev') {

      this.pageJump--;
    }
    this.processOrgRoutes(true);
    this.viewRouteHistory();

  }

  closeSettingsOverLay() {
    this.hideOverlay.emit(true);//hide settings overlay.
  }

  expandRow(index: number, routeId: any): void {
    if (index != this.expandedIndex) {
      var routeObj = {
        data: {
          key: localStorage.getItem('scbToken'),
          "filter": {
            "routeId": routeId
          }
        }
      };

      this.apiService.getRouteStoppages(routeObj).then(response => {
        if (response.status == 'success') {
          //console.log(this.newConfigRoutes.filter((route:any) => { return route.routeId == routeId }))
          var dummy = {
            routeId: routeId
          }
          this.selectedRouteStoppages = response.response.pickuppoints;
          Object.assign(this.selectedRouteStoppages, dummy);
        }
        if (this.pickUpNotifconfigs[routeId] == undefined) this.pickUpNotifconfigs[routeId] = [];
        this.pickUpNotifconfigs[routeId] = this.selectedRouteStoppages.slice();

        this.expandedIndex = index === this.expandedIndex ? -1 : index;
      }).catch(error => { });
    } else {
      this.expandedIndex = index === this.expandedIndex ? -1 : index;
    }

  }

  getRouteStoppages(routeId: any): void {
    if (this.pickUpNotifconfigs[routeId] == undefined) this.pickUpNotifconfigs[routeId] = [];

  }
  showhide(event: any) {
    //this.values += event.target.value + ' | ';
    //alert(event.target.value);
    if (event.target.value === '') {
      this.selectedRoute = '';
      this.viewMore1 = true;
    } else {
      this.viewMore1 = false;
    }
  }


  setSelectedRoute(selectedRoute: any) {
    if (selectedRoute.length) {
      this.selectedRoute = selectedRoute[0].routeName; //carrying forward  previously declared global var strategy.

      // this.filterFunction(selectedRoute);
      // this.populatePickUpPoints(this.selectedRoute);
    }
  }
  filterFunction() {

    this.RoutesGlobalFilterObj = {};

    if (this.rType == '') {
      this.configFilterRouteTags = '';
      // this.processOrgRoutes(true);
    } else {
      this.configFilterRouteTags = this.rType;
    }


  }
  getRoutes() {
    let parent: any = this;
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
      }
    };
    if (Object.keys(this.RoutesGlobalFilterObj).length) {
      routeObj['data']['filter'] = {};
      routeObj['data']['filter'] = this.RoutesGlobalFilterObj;
    }
    this.apiService.getRoutes(routeObj).then(response => {
      var parent: any = this;
      if (response) {
        this.schoolRoutes = response.response;

      };
    }).catch(error => { });
  }
  pushConfigs(pickUpId: any, alertType: any, status: any): void {
    //let routeId = this.selectedRouteStoppages.routeId;
    let routeStoppage = this.selectedRouteStoppages.filter((index: any) => index.pickupId == pickUpId).reduce((acc: any, curVal: any) => acc = curVal, {});
    let controlValue = (status.target.checked).toString();
    if (this.configObj[routeStoppage.pickupId]) {
      this.configObj[routeStoppage.pickupId][alertType] = controlValue;
    } else {
      let dummy = {
        pickupId: pickUpId
      }
      this.configObj[routeStoppage.pickupId] = dummy;
      Reflect.set(this.configObj[routeStoppage.pickupId], alertType, controlValue);
    }
  }

  manageRouteAlerts() {
    for (var index in this.configObj) {

    }
  }

  populatePickPointsToCopy() {
    //setting old routes info.
    let filteredOrgRoutesObj = this.orgRoutes.filter(route => {
      return route.routeId == this.selectedRouteToCopy
    });
    this.baseRouteName = filteredOrgRoutesObj[0].routeName;
    this.baseRouteStartTime = filteredOrgRoutesObj[0].routeStartTime;
    this.baseRouteEndTime = filteredOrgRoutesObj[0].routeEndTime;
    this.baseRouteVehAssigned = filteredOrgRoutesObj[0].vehNo;
    this.newRouteStartTime = '';
    this.newRouteEndTime = '';
    this.formValues = [];
    this.pickupPointsCopyList = [];
    //this.newRouteStartTime = this.baseRouteEndTime;
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        "filter": {
          "routeId": this.selectedRouteToCopy
        }
      }
    };
    let parent: any = this;
    this.apiService.getRouteStoppages(routeObj).then(response => {
      if (response.status == 'success') {
        this.pickupPointsCopyList = response.response.pickuppoints;
        this.buildForm();
        this.showPickupPoints = true; //show pickup points section after API response.
      }
    }).catch(error => { })
  }

  viewRouteHistory() {
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
        extra: {
          pageJump: this.pageJump,

        }
      }
    }
    let todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");


    this.apiService.viewVehicleNotMoved(apiHeader).then(response => {
      this.vehicleNotMovedHistory = response.response;

      for (var id in this.vehicleNotMovedHistory) {
        // console.log('aaa : ',this.vehicleNotMovedHistory[id].routeName,'  ',this.vehicleNotMovedHistory[id].routeTimeInfo)
        this.flagSet = false;
        if (this.vehicleNotMovedHistory[id].routeTimeInfo == null) {
          this.flagSet = true;
        }
        else {
          for (var id1 in this.vehicleNotMovedHistory[id].routeTimeInfo) {
            // console.log('bbbbbbbbbbb : ',this.vehicleNotMovedHistory[id].routeTimeInfo.date)
            if (todayDate != this.vehicleNotMovedHistory[id].routeTimeInfo.date) {
              this.flagSet = true;
            } else {

            }

            if (this.vehicleNotMovedHistory[id].routeTimeInfo.kms < 0.5) {
              this.flagSet = true;
            }
          }
        }

        if (this.flagSet) {


          let objFrame = {};
          objFrame['routeName'] = this.vehicleNotMovedHistory[id]['routeName'];
          objFrame['vehRegno'] = this.vehicleNotMovedHistory[id]['vehRegno'];

          this.notMovedData.push(objFrame);
          //   let obj = {
          //     routeName:this.vehicleNotMovedHistory[id].routeName
          //   }
          //    console.log(obj);
          //    this.notMovedData.push(obj)
          // console.log("inside");
        }


      }
    }).catch(error => { });

    this.apiService.viewRouteHistory(apiHeader).then(response => {
      this.routeHistory = response.response;
      for (var id in this.routeHistory) {
        let filteredOrgRoutesObj = this.orgRoutes.filter(route => {
          return route.routeId == this.routeHistory[id].routeId
        });
        let obj = {
          routeName: filteredOrgRoutesObj[0].routeName
        }
        Object.assign(this.routeHistory[id], obj);
      }
    }).catch(error => { });
  }

  searchTimeConfigs(userText: string) {

  }

  checkRevertPermission(updateTimeId: any) {
    let routeObj = {
      formData: {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            updateTimeId: updateTimeId
          }
        }
      },
      functionCallback: 'reverseRouteLog'


    };
    this.userOperationData = routeObj;
    this.routeRevertConfirmation = true;
    this.showDialog = true;
    // window.location.reload();

  }

  actionGrantStatus(userResponse: boolean) {
    if (userResponse) { //pressed yes for delete operation.
      let functionCallback = this.userOperationData.functionCallback;
      this[functionCallback]();
    } else { }
    //this.userOperationData = {};
  }

  //driver funtion to reset values when app dialog is hidden.
  getDialogVisibility(event: boolean) {
    this.userOperationData = {};
    this.routeRevertConfirmation = false; //hiding route rever confirmation message.
    this.orgNewConfigConfirmation = false; //hiding the new configuration confirmation.
    this.showDialog = false; //hiding app dialog.
  }

  //driver funtion to reset values when app success dialog is hidden
  getSuccessDialogVisibility(event: boolean) {
    this.showRouteRevertSuccess = false;
    this.showConfigCreationSuccess = false;
    this.showRouteRevertFailure = false;
    this.showconfigCreationFailure = false;
    this.configCreationFailureCount = 0;
    this.showSuccessDialog = false;
  }

  reverseRouteLog() {
    let apiHeader = this.userOperationData.formData;
    this.getDialogVisibility(false); //hide app confirmation dialog

    this.showSuccessDialog = true; //show success dialog.
    this.apiService.revertRouteHistory(apiHeader).then(response => {
      if (response.status == 'success') {
        this.showRouteRevertSuccess = true;
        this.showRouteRevertFailure = false;
      } else if (response.status == 'failure') {
        this.showRouteRevertFailure = true;

        this.showRouteRevertSuccess = false;
        const failureData = response.response.failed;
        this.downloadFailureExcel(failureData);
      }
    }).catch(error => {
      console.error('Error:', error);
    })
  }

  downloadFailureExcel(failureData: any) {



    if (Object.keys(failureData).length > 0) {
      let index = 1;
      let keys = Object.keys(failureData);
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          'S.No': index,
          'Route name': failureData[keys[i]].route,
          'New Start Time': failureData[keys[i]].new_StartTime,
          'New End Time': failureData[keys[i]].new_EndTime,
          'Route ID': failureData[keys[i]].routeid,
        }
        this.excelDownloadData.push(obj);
      }

    }
    let fileName = 'Revert  failed routes';
    if (this.excelDownloadData.length > 0) {
      this.excel.exportExcel(this.excelDownloadData, fileName);
    }


  }
  // revert all
  isAnyChecked(): boolean {

    return this.routeHistory.some((history: { isChecked: any; }) => history.isChecked);
  }

  // Submit selected items
  async submit() {
    const selectedItems = this.routeHistory.filter((history: { isChecked: any; }) => history.isChecked);
    console.log('Selected Items:', selectedItems);
    for (let item of selectedItems) {
      const formData = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            updateTimeId: item['updateTimeId']
          }
        }
      }

      this.getDialogVisibility(false); //hide app confirmation dialog

      this.showSuccessDialog = true; //show success dialog.
      const response = await this.apiService.revertRouteHistory(formData).catch(error => { this.showSuccessDialog = false; });

      if (response.status == 'success') {
        this.showRouteRevertSuccess = true;
        this.showRouteRevertFailure = false;
        // window.location.reload();
      } else {
        this.showRouteRevertFailure = true;
        this.showRouteRevertSuccess = false;
      }


    }

    // Here you can implement further logic, like sending selected items to a server
  }
  getNewConfigConfirmation() {
    var routeObj = {
      formData: {},
      functionCallback: 'createNewConfig'
    };
    this.userOperationData = routeObj;
    this.orgNewConfigConfirmation = true;
    this.showDialog = true;
    this.createConfigError = false;
  }

  validateNewConfig(selectedroutes: any, routeDelayedOptions: any, routeDelayHours: any, comments: any): boolean {
    let isValid: boolean = true;
    if (!selectedroutes.length) isValid = false;
    else if (routeDelayedOptions == '') isValid = false;
    else if (comments == '') isValid = false;

    if (routeDelayHours == '') isValid = false;
    else {
      if (!this.validateTime(routeDelayHours)) isValid = false;
    }
    return isValid;
  }

  selectAllAvailableRoutes(ele: any) {
    for (var newConfig of this.newConfigRoutes) {
      if (ele.target.checked) newConfig.checked = true;
      else newConfig.checked = false;
    }
  }
  public configCreationFailureCount: number = 0;
  public configCreationFailureData: any = '';
  createNewConfig() {
    this.getDialogVisibility(false); //hide app dialog.
    let selectedroutes = this.newConfigRoutes.filter((opt: any) => opt.checked).map((opt: any) => opt.routeId)
    if (this.validateNewConfig(selectedroutes, this.routeDelayedOptions, this.routeDelayHours, this.routeDelayComments)) {
      let apiHeader = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            routes: selectedroutes,
            operator: this.routeDelayedOptions,
            timing: this.routeDelayHours,
            comment: this.routeDelayComments
          }
        }
      }
      this.apiService.createNewOrgConfiguration(apiHeader).then(response => {
        if (response.status == 'success') {
          this.showConfigCreationSuccess = true;
          this.showconfigCreationFailure = false;
          this.configCreationFailureCount = 0;
          this.configCreationFailureData = [];
        } else {
          let data = response.response.failed;
          this.configCreationFailureData = data;
          this.excelDownloadStoppageData();
          let errors = Object.keys(data);
          if (errors && errors.length > 0) {
            this.configCreationFailureCount = Object.keys(data).length;
          }
          this.showconfigCreationFailure = true;
          this.showConfigCreationSuccess = false;
        }
        this.routeDelayComments = '';
        this.routeDelayedOptions = '';
        this.routeDelayHours = '';
        this.showSuccessDialog = true;
        this.viewRouteHistory(); //callback function to update route history list.
      }).catch(error => { })
    } else {
      this.createConfigError = true;
    }
  }
  public excelDownloadData: any = [];
  excelDownloadStoppageData() {
    if (Object.keys(this.configCreationFailureData).length > 0) {
      let index = 1;
      let keys = Object.keys(this.configCreationFailureData);
      for (let i = 0; i < keys.length; i++) {
        let obj = {
          'S.No': index,
          'Route name': this.configCreationFailureData[keys[i]].route
        }
        this.excelDownloadData.push(obj);
      }

    }
    let fileName = 'New time configuration failed routes';
    if (this.excelDownloadData.length > 0) {
      this.excel.exportExcel(this.excelDownloadData, fileName);
    }
  }
  //function to set all values in form builder.
  buildForm() {
    var diff = this.calculateTimeElapsed(this.baseRouteStartTime, this.newRouteStartTime);
    var operation = diff.operationType;
    diff = diff.diff;
    diff = diff.split(':');
    this.pickupPointsCopyList.forEach((point: any) => {
      let dt: any = new Date("January 1, 1970 " + point.expectedTime);
      //  if(diff.operationType > 0) console.log("test u");
      if (operation > 0) {
        dt.setHours(dt.getHours() - parseInt(diff[0]));
        dt.setMinutes(dt.getMinutes() - parseInt(diff[1]));
      } else {
        dt.setHours(dt.getHours() + parseInt(diff[0]));
        dt.setMinutes(dt.getMinutes() + parseInt(diff[1]));
      }


      dt = dt.toTimeString().split(' ');
      var hhMm = dt[0].split(':');
      hhMm = hhMm[0] + ':' + hhMm[1];
      this.formValues[point.pickupId] = [hhMm, [Validators.compose([Validators.required, minValueValidator(this.newRouteStartTime, this.newRouteEndTime)])]];
    });
    this.routeCloneForm = this.fb.group(this.formValues);
  }

  getAvailableVehicles() {
    let parent: any;
    if (((this.newRouteStartTime) && (this.newRouteEndTime))) {
      if (this.validRouteTimings()) {
        var apiHeader = {
          data: {
            key: localStorage.getItem('scbToken'),
            filter: {
              routeStartTime: this.newRouteStartTime,
              routeEndTime: this.newRouteEndTime
            }
          }
        }
        this.apiService.getOrgVehicles(apiHeader).then(response => {
          this.vehAvailable = response.response;
          this.buildForm();
        }).catch(error => { });
      } else {
        this.vehAvailable = [];
      }
    }

  }

  setFilterObj(): void {
    this.configFilterObj = {};
    this.newConfigRoutes = [];
    //      var regex = new RegExp("([0-1][0-9]|2[0-3]):([0-5][0-9])");

    // if (regex.test("zz:12")) {
    // alert(true);
    // } else {
    // alert(false);
    // }
    //  alert(this.selectedRoute);
    if (this.selectedRoute) this.configFilterObj.routeName = this.selectedRoute;

    if (this.configFilterRouteTags) this.configFilterObj.tags = this.configFilterRouteTags;

    this.processOrgRoutes(false);
    // this.getOrgRoutes();
  }

  //  getOrgRoutes():void{
  //   if(this.selectedRoute==undefined && this.configFilterRouteTags==undefined ){ 
  //     this.routePageJump=0;
  //   }else if(this.selectedRoute=='' && this.configFilterRouteTags==''){ 
  //     this.routePageJump=0;
  //   }

  //   var routeObj={};
  //    routeObj = {
  //      data: {
  //        key: localStorage.getItem('scbToken'),
  //        filter:  this.configFilterObj,
  //        extra: {
  //         pageJump: this.routePageJump
  //       },
  //      }

  //    };

  //    this.apiService.getRoutes(routeObj).then(response => {



  //      this.newConfigRoutes = response.response; //setting route for new configuration module.
  //      for(var index in this.newConfigRoutes){
  //        var obj = {
  //          checked:false
  //        }
  //        Object.assign(this.newConfigRoutes[index],obj);
  //      }
  //    }).catch(error => { });
  //  }

  validRouteTimings() {
    let flag = true;
    if (this.newRouteStartTime == '' || this.newRouteEndTime == '') {
      flag = false;
    } else if ((this.sliceAndJoin(this.newRouteStartTime) > this.sliceAndJoin(this.baseRouteStartTime)) && (this.sliceAndJoin(this.newRouteStartTime) < this.sliceAndJoin(this.baseRouteEndTime))) {
      flag = false;
    } else if ((this.sliceAndJoin(this.newRouteEndTime) > this.sliceAndJoin(this.baseRouteStartTime)) && (this.sliceAndJoin(this.newRouteEndTime) < this.sliceAndJoin(this.baseRouteEndTime))) {
      flag = false;
    }
    return flag;
  }

  validateTime(time: string): boolean {
    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(time);
    //alert(isValid);
    return isValid;
  }

  manageAlertConfiguration() {
    var routeConfig: any = [];
    var pckpts: any = [];
    for (var index in this.pickUpNotifconfigs) {
      //routeConfig[index] = [];
      pckpts = [];
      if (this.pickUpNotifconfigs[index].length) { //if pickup-points are modified.
        for (var id in this.pickUpNotifconfigs[index]) {
          var pckid = this.pickUpNotifconfigs[index][id].pickupId;
          if (this.configObj[pckid] != undefined) {
            //routeConfig[index][pckid] = this.configObj[pckid];
            //routeConfig[index].push(this.configObj[pckid]);
            pckpts.push(this.configObj[pckid]);
          }
        }
      }


      //  if(pckpts.length){ //push if any pickpoints modified for a route.
      var routeDetails = this.newConfigRoutes.filter((routeIndex: any) => {
        return routeIndex.routeId == index
      });

      routeDetails = routeDetails[0];
      let obj = {
        routeId: index,
        smsAlert: '' + routeDetails.smsAlert,
        appAlert: '' + routeDetails.appAlert,
        callAlert: '' + routeDetails.callAlert,
        pickuppoints: pckpts
      };
      routeConfig.push(obj);
      //}
    }
    this.expandedIndex = -1; //collapse all pickup points accrodion after API hit.

    var alertObj = {
      "data": {
        "key": localStorage.getItem('scbToken'),
        "form": {
          "routes": routeConfig
        }
      }
    }


    this.apiService.manageRouteConfigurations(alertObj).then(response => {
      this.pickUpNotifconfigs = [];
      this.configObj = [];
      //  this.processOrgRoutes(true); //callback to route view funtion.
    }).catch(error => { });
  }

  /*Route clone functions start here*/

  cloneRoute() {
    let pickupPtsArr = [];
    let formValues = this.routeCloneForm.value;
    let baseRouteDetails = this.orgRoutes.filter(route => {
      return route.routeId == this.selectedRouteToCopy
    });
    for (let index in formValues) {
      let pInfo = this.pickupPointsCopyList.filter(function (pickup: any) { return pickup.pickupId == index })[0];
      let obj = {
        cloningpickupId: index,
        expectedTime: formValues[index],
        pickuppoint: pInfo.pickuppoint,
        pickupRadius: pInfo.pickupRadius,
        pickupAddress: pInfo.pickupAddress,
        pickuppointLocation: pInfo.pickuppointLocation
      }
      pickupPtsArr.push(obj);
    }
    let apiHeader = {
      data: {
        key: localStorage.getItem('scbToken'),
        form: {
          routeName: this.newClonedRouteName,
          driverId: baseRouteDetails[0]['driverId'],
          routeStartTime: this.newRouteStartTime,
          routeEndTime: this.newRouteEndTime,
          routeAddress: baseRouteDetails[0]['routeAddress'],
          routeType: baseRouteDetails[0]['routeType'],
          routeStartLocation: baseRouteDetails[0]['routeStartLocation'],
          vehId: this.newRouteSelectedVehicle,
          pickuppoints: pickupPtsArr
        }
      }
    }
    //console.log(apiHeader);
  }

  /*Rote clone functions ends here*/

  /* Module utility functions starts */
  minMax(control: FormControl) {
    return (control.value >= '08:00') && (control.value <= '23:59') ? null : {
      minMax: true
    }
  }

  calculateTimeElapsed(startTime: any, EndTime: any): any {
    var operationType;
    var returnObj = {};
    var startDate: any = new Date("January 1, 1970 " + startTime);
    var endDate: any = new Date("January 1, 1970 " + EndTime);
    //console.log(startDate,endDate);
    var timeDiff = Math.abs(startDate - endDate);
    //If diff is positive then subtract pickup pts ETA.
    // else diff is added to the pickup pts ETA.
    var hh: any = Math.floor(timeDiff / 1000 / 60 / 60);
    if (hh < 10) {
      hh = '0' + hh;
    }
    timeDiff -= hh * 1000 * 60 * 60;
    var mm: any = Math.floor(timeDiff / 1000 / 60);
    if (mm < 10) {
      mm = '0' + mm;
    }
    timeDiff -= mm * 1000 * 60;
    var ss: any = Math.floor(timeDiff / 1000);
    if (ss < 10) {
      ss = '0' + ss;
    }
    returnObj = {
      operationType: startDate - endDate,
      diff: hh + ':' + mm + ':' + ss
    }

    return returnObj;
  }

  sliceAndJoin(param: any): any {
    param = param.split(':').join(''); //accept colon separated string.
    param = param.replace(/^0+/, ''); // remove leading zeroes.
    return parseInt(param);
  }

  onScroll($event: any) {
    if ((($event.target.scrollTop + $event.target.offsetHeight)) == $event.target.scrollHeight) {
      if (this.noMoredata == false) {

        this.isScrolled = true;
        //$event.srcElement.scrollTop = $event.srcElement.scrollTop-100;
        //  this.processOrgRoutes(false);
      }
    }

  }
  onScrollFliter($event: any) {
    if ((($event.target.scrollTop + $event.target.offsetHeight)) == $event.target.scrollHeight) {

      if (this.noMoredata == false) {

        this.isScrolled = true;
        //$event.srcElement.scrollTop = $event.srcElement.scrollTop-100;
        //  this.getOrgRoutes();
        // this.processOrgRoutes(false);
      }
    }

  }


  /* Module utility functions ends */

}

//custom validator.
export const minValueValidator = (min: number, max: number) => {
  return (control: FormControl) => {
    return ((control.value >= min) && (control.value <= max)) ? null : {
      minMax: true
    }
  };
};

