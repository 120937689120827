import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ApiService } from "../../../services/ApiService";
import * as XLSX from 'xlsx';


@Component({
    selector: 'route-wise-face-attendance',
    template: `

    <ng-container *ngIf="routeFaceattendance.length && !showLoader">
    <div class="panel panel-default">
       <div class="panel-heading">
          <div class="row mb5">
             <div class="reportHeading text-center mb5">RouteWise Face Attendance Report</div>
          </div>
          <div class="row mb5">
             <div class="col-md-6 text-left">
                <label class="control-label">Date : </label>
                <span class="reportStartDate"> {{selectedDate}}</span>
             </div>
             <div class="col-md-6 text-right">
                <label class="control-label">RouteName : </label>
                <span class="reportEndDate"> {{routeDetails}}</span>
             </div>
          </div>
          <div class="row mb5">
             <div class="col-md-4 text-left">
                <label class="control-label">Total Students : </label>
                <span class="reportStartDate"> {{selectedDate}}</span>
             </div>
             <div class="col-md-4 text-right">
                <label class="control-label">Marked Count : </label>
                <span class="reportEndDate"> {{routeDetails}}</span>
             </div>
             <div class="col-md-4 text-right">
                <label class="control-label">Unmarked Count : </label>
                <span class="reportEndDate"> {{routeDetails}}</span>
             </div>
          </div>
          <div class="row mb5">
             <!-- <div class="col-md-6 text-left">
                <label class="control-label">Date : </label>
                <span class="reportStartDate"> {{selectedDate}}</span>
             </div> -->
             <div class="col-offset-md-6 text-right" style="padding: 0px 15px;">
             
             <span class="excel-template-downalod-ref pull-right meager-e2e-download-update-stu-info-details"
            (click)="routewiseExcel()" style="cursor:pointer;"><a   class="meager-e2e-datewise-tab-attendance">
                    Download excel Report
                </a></span>
             </div>
          </div>
       </div>
      
        
       
       <div class="panel-body" style="max-height:444px">
    
       <div class="row">
       <div class="col-md-12 table-responsive">
           <table class="table table-bordered">
             <thead>
             <tr class="text-center">
             <th class="text-center">#</th>

                <th class="text-center">Student Id</th>
                <th class="text-center">Student Name</th>
                <th class="text-center">Class</th>
                <th class="text-center">Section</th>
                <th class="text-center">Attendance time and date</th> 
                <!-- <th colspan="2" class="text-center">
                <tr class="text-center"><td class="present" style="left:61px;!important">Present</td></tr>
                  <tr class="text-center">
                     <td>Boarded</td>
                     <td style="position:relative;left:30%">Deboarded</td>
                  </tr>
                </th> -->
             </tr>
             </thead>
             <tbody>
                <ng-container *ngFor="let item of routeFaceattendance;let n = index;">
                   <tr>
                      <td>{{n+1}}</td>
                      <td>{{item.memberUID}}</td>
                      <td>{{item.memberName}}</td>
                      <td>{{item.memberClass}}</td>
                      <td>{{item.memberSection}}</td>
                      <td >{{item.ts | date: 'dd-MM-yyyy HH:mm'}}</td>

                     
                  
                   </tr>
                </ng-container>
             </tbody>
          </table>
          </div>
          </div>
          <ng-template #validData>
          </ng-template>
          <ng-template #invalidData>
             <!--<span class="text-center"><i class="fa fa-times text-danger" aria-hidden="true"></i></span><br><span><br> - </span>       -->
             <div class="text-center" style="color:white">dummy</div>
             <div class="text-center">-</div>
             <div class="text-center" style="color:white">dummy</div>

          </ng-template>
       </div>
    </div>
 </ng-container>
 <!-- <ng-container *ngIf="!showLoader && !routewiseAttendance.length">
 <div class="panel panel-default">
 <div class="panel-heading">
 <div class="row mb5">
     <div class="reportHeading text-center mb5">RouteWise Attendance Report</div>
 </div>
 </div>
 <div class="panel-body">
 <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF"><i class="fa fa-frown-o"></i>&nbsp;No records found</h4>
 </div>
 </div>
 </ng-container> -->
 
 <ng-container *ngIf="routeFaceattendance.length==0">
 <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="row mb5">
                        <div class="reportHeading text-center mb5">Routewise Face Attendance Report</div>
                    </div>
                </div>
                <div class="panel-body">
                    <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
                        <i class="fa fa-frown-o"></i>&nbsp;No records found
                    </h4>
                </div>
            </div>
 </ng-container>
    `,
    styleUrls: ['./face-attendance-reports.css'],
})

export class RoutewiseFaceAttendanceComponent {
    public response: any;
    public routeRepo: any = {};
    public routewiseAttendance: any[];
    public selectedDate: any;
    public routeDetails: any;
    public apiHeader: any = {}
    public keyed: any = Object.keys;
    boardedCnt: number;
    deboardedCnt: number;
    notBoardedCnt: number;
    showCount: boolean;
    vehicleName: any;
    showLoader: boolean;
    validExcel: boolean = false;
    xlString: string = 'string';
    routeFaceattendance: any[];
    // variable declarations


    // end of variable declarations
    constructor(
        public api: ApiService
    ) {
        this.showCount = true;
        this.showLoader = true;
    }



    //business logics for display


    @Input('getRouteWiseData') // get api response in this variable
    set getRouteWiseData(val: any) {
        try {
            this.showLoader = true;
            this.valueResetter();
            this.apiHeader = val;
            this.selectedDate = val.data.form.fromDate.split('-').reverse().join('-');
            this.routeFaceattendance = [];
            this.getRouteAttendanceDataFromAPI();

        } catch (e) { }
    }
    // @Input('getRouteData')
    // set getRouteData(vals:any){
    //     setTimeout(() => {
    //         this.routeDetails = vals[0]['routeName'];

    //     }, 200);

    // }
    @Output() errorDetected: EventEmitter<any> = new EventEmitter();
    ngOnInit() {


    }
    valueResetter() {
        this.routeFaceattendance = [];
        this.apiHeader = {};
    }

    getRouteAttendanceDataFromAPI() {

        this.api.faceAttendanceReport(this.apiHeader).then((res: any) => {
            this.routeRepo = res.response;
            //   let routeRepo = res.response;

            let routeRepo = res.hasOwnProperty('response') ? res.response : res;


            if (!routeRepo.ec) {


                Object.keys(routeRepo).forEach((item: any) => {

                    this.routeFaceattendance = routeRepo[item];

                })
                this.showCount = false;
                this.errorDetected.emit({ ec: '' });
            } else {
                this.errorDetected.emit(routeRepo);

            }


        });

        this.showLoader = false;
        this.validExcel = true;
    }

    routewiseExcel() {
        if (!this.routeFaceattendance || this.routeFaceattendance.length === 0) {
            alert("No data available to export!");
            return;
        }
        let excelData = this.routeFaceattendance.map((item, index) => {
            return {
                "Sl. No": index + 1,
                "Student ID": item.memberUID,
                "Student Nmae": item.memberName,
                "Class": item.memberClass,
                "Section": item.memberSection,
                "Route Name": item.routeName,
                "Attendance Status": item.attendancestatus === 'true' ? 'Present' : 'Absent',
                "Date": new Date(item.ts).toLocaleDateString("en-GB"), // Convert timestamp to readable date
                "Time": new Date(item.ts).toLocaleTimeString("en-GB")  // Convert timestamp to time
            };
        });
        console.log(excelData);

        // Create a worksheet
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Attendance Report': worksheet },
            SheetNames: ['Attendance Report']
        };
        const currentDate = new Date();

        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Generate Excel file and trigger download
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = `Route_wise_face_attendance_report_${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, filename);


        // saveAs(data, `Attendance_Report_${new Date().toISOString().split('T')[0]}.xlsx`);
    }
}