import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { roleFormModule } from './../../../model/role-model';
import { Objectkeys } from './../../../custom-pipes/objectkeys.pipe';
import { ApiService } from './../../../services/ApiService';
import { COM } from './../../../services/com.services';

const swal = require('sweetalert');

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  public formControl: any;
  public Role: any = {};
  public roleControl: any;
  public orgReportStatus: any;
  public formError: any = {};

  private orgReportsStatus: any;
  public roleinfomenu: any;

  constructor(
    private router: Router,
    private roleModule: roleFormModule,
    private api: ApiService,
    private com: COM
  ) {

    this.roleControl = this.roleModule.jsonObj;
    this.orgReportsStatus = this.roleModule.orgReportsStatus;

    this.com.comAction.subscribe((e: any) => {
      if (e.lable === 'OrgReports') {
        this.orgReportStatus = e.data;

        if (this.orgReportsStatus['rfid']['RFIDRouteWiseCount'] == undefined) {
          this.orgReportsStatus['rfid']['RFIDRouteWiseCount'] = "RFID RouteWise Count";
        }
        if (this.orgReportsStatus['rfid']['RFIDAppPush'] == undefined) {
          this.orgReportsStatus['rfid']['RFIDAppPush'] = "RFID App Push";
        }
        if (this.orgReportsStatus['tracking']['vehcoordinate'] == undefined) {
          this.orgReportsStatus['tracking']['vehcoordinate'] = "Vehicle Co-ordinate Report";
        }
        if (this.orgReportsStatus['alertlog']['callEligibleReport'] == undefined) {
          this.orgReportsStatus['alertlog']['callEligibleReport'] = "Call Eligible Report";
        }
        if (this.orgReportsStatus['alertlog']['callProcessedAnalysReport'] == undefined) {
          this.orgReportsStatus['alertlog']['callProcessedAnalysReport'] = "Call Alert Analysis Report";
        }
        if (this.orgReportsStatus['alertlog']['appReportsLog'] == undefined) {
          this.orgReportsStatus['alertlog']['appReportsLog'] = "App Reports Log";
        }
        if (this.orgReportsStatus['tracking']['routeDeviation'] == undefined) {
          this.orgReportsStatus['tracking']['routeDeviation'] = "Route Deviation";
        }


        if (this.orgReportsStatus['others']['breakDownReport'] == undefined) {
          this.orgReportsStatus['others']['breakDownReport'] = "Break Down Report";
        }

        if (this.orgReportsStatus['attendance'] == undefined) {
          this.orgReportsStatus['attendance'] = {};
        }


        if (this.orgReportsStatus['attendance']['memberwiseAttendance'] == undefined) {
          this.orgReportsStatus['attendance']['memberwiseAttendance'] = "Memberwise Attendance";
        }
        if (this.orgReportsStatus['attendance']['routewiseAttendance'] == undefined) {
          this.orgReportsStatus['attendance']['routewiseAttendance'] = "Routewise Attendance";
        }
        if (this.orgReportsStatus['attendance']['datewiseAttendance'] == undefined) {
          this.orgReportsStatus['attendance']['datewiseAttendance'] = "Datewise Attendance";
        }

      }

    })
  }

  ngOnInit() {
    //this.getReports();
    let roleinfo = this.roleControl;
    this.roleinfomenu = roleinfo;
    if (this.roleinfomenu['menu'] && this.roleinfomenu['menu']['Dashboard'] == undefined) {
      this.roleinfomenu['menu']['Dashboard'] = true;
    }
  }



  navigate(url: string) {
    this.router.navigate(['/' + url, {}]);
  }
  addRole(formControl: any) {
    this.formError.name = false;
    if (formControl.valid) {

      let roleinfo = this.roleControl;

      let apiData = {
        data: {
          key: localStorage.getItem('scbToken'),
          form: {
            rolename: formControl.value.rName,
            roleinfo
          }
        }
      }

      this.api.addRole(apiData).then((res: any) => {

        if (res.status === 'success') {
          swal('New role added successfuly...!');
          setTimeout(() => {
            this.router.navigate(['/role-management', {}]);
          }, 300);

        } else {
          if (res.status === 'failure') {
            if (res.ec == 'SCB76') {
              swal('User Role was already added');
            }
          }
        }

      });

    } else {

      if (formControl.value.rName == '' || formControl.value.rName == undefined) {
        this.formError.name = true;
      }
    }

  }
  change(str: any, sub: string, val: any) {

    if (str[sub][val] === true) {
      str[sub][val] = false;
    } else {
      str[sub][val] = true;
    }

  }

  changeReports(str: any, sub: string, val: any) {

    if (str['reports'][sub][val] === true) {
      str['reports'][sub][val] = false;
    } else {
      str['reports'][sub][val] = true;
    }

  }



}
