/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: pickupForm.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-08T16:02:46+05:30
 * @Copyright: Group10 Technologies
 */



import {
  Component, ElementRef, AfterViewInit,
  OnDestroy, ViewChild, Input, Output, EventEmitter, NgZone, Injectable
} from '@angular/core';

import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';

import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { decodeAddress } from './../../../services/decodeAddress.service';

import { StorageService } from './../../../services/storage.service';

declare var $: any;
declare var google: any;
@Component({
  selector: 'add-pickup-form',
  template: `
  <!--contents for add pickUpPoints div begins -->
      <div class="route-heading-container" style="padding: 2%;background: rgba(158, 158, 158, 0.09);min-height: 100px;">
        <div class="back-button pull-left grow meager-e2e-pickup-goback"  style="cursor:pointer;" (click)="hideAddPickupDir($event)" data-toggle="tooltip" data-placement="bottom" title="go back">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g class="nc-icon-wrapper" fill="#429db5"> <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path> </g> </svg>
        </div>
        <div class="row route-heading-tabs" style="padding-top:23px">
            <div class="list-heading" style="color:#01bcd4;font-size:27px;">{{RouteName}} <sup class="supClass">&nbsp;<i class="fa fa-clock-o mr5" aria-hidden="true"></i>{{routeTimings}}</sup></div>
            <div class="" style="font-size:18px;margin-left:38px;">Add Pickup Point </div>
        </div>
      </div>
      <div class="pickUpPointsList animated fadeIn routeDetails"  style="padding:10px;overflow-y:auto;max-height: 74vh;">
        <div class="add-point-form">
          <div class="form-group">
            <label class="control-label">Point Name<sup>*</sup></label>
            <input class="custom-form-control point-input-text meager-e2e-pickup-pname" [(ngModel)]="pointName" appAutofocus>
            <span class="text-danger meager-e2e-pickup-pname-errtxt" *ngIf="showPickupNameError">You should add pickup name</span>
          </div>
          <div class="form-group">
            <label class="control-label">Point Address<sup>*</sup></label>
            <textarea class="custom-form-control meager-e2e-pickup-pickupaddr" rows="3" id="locationField" (keyup.enter)="geocodeAddress()" (change)="geocodeAddress()" [(ngModel)]="inputAddress"></textarea>
            <span class="text-danger meager-e2e-pickup-pickupaddr-errtxt" *ngIf="showInputAddressError">You Should Add Pickup Location</span>
          </div>
          <div class="form-group">
            <label class="control-label">Point Radius (Metres)<sup>*</sup></label>
            <input class="custom-form-control point-input-text meager-e2e-pickup-prad" id="pointGeoRadius" type="number" min="100" step="50" [(ngModel)]="geoRadius" (change)="updateGeoradius()">
          <span class="text-danger meager-e2e-pickup-prad-errtxt" *ngIf="showPointRadiusError">You Should Add Point Radius</span>
          </div>

          <div class="form-group">
            <label class="control-label">Estimated Time Of Arrival (HH:MM)<sup>*</sup></label>
            <input atp-time-picker class="custom-form-control point-name point-input-text meager-e2e-pickup-ptime" [(ngModel)]="expectedTime" type="time">
            <span class="text-danger meager-e2e-pickup-ptime-errtxt" *ngIf="showRouteTimeError">You Should Follow (HH:MM) Format</span>
          </div>

       
            

          <div class="form-actions pull-right">
          <button class="webform-submit button-primary btn btn-primary form-submit meager-e2e-pickup-prad"
  (click)="createPickupPointObj()" [disabled]="isSubmitting">
  {{ isSubmitting ? 'Submitting...' : 'Submit' }}
</button>
          </div>
        </div>
      </div><!--Contents for add pickUpPoints div ends here -->

  `,
  styles: [`


    animation css */
    .grow { transition: all .2s ease-in-out; }
    .grow:hover { transform: scale(1.1); }

    .supClass {
      background: #337ab7;
      display: inline;
      padding: .2em .3em;
      font-weight: 700;
      font-size: 12px;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: .25em;
      margin-left: 4px;
      top: -0.9em;
    }


`  ],
})

@Injectable()
export class SchoolPickupFormDir {
  private _map: any;
  private _geoRadius: any;
  private _geoFillColor: any;
  private __draggedLatLng: any;
  private addPickup: boolean = true;
  public expectedTime: string;
  public showRouteTimeError: boolean;
  public showPickupNameError: boolean;

  public showInputAddressError: boolean;
  public showPointRadiusError: boolean;
  private mapObj: any;
  private routeCreateMarker: any;
  private pickupGeofence: any;
  public pointName: any;
  public RouteName: any;
  public routeStoppages: any;
  private mapBounds: any
  private pickupPointMarkers: any = [];
  public routeTimings: string;
  public studentId: string;
  public studentName: string;
  isSubmitting: boolean = false;


  constructor(
    private apiService: ApiService,
    private decodeAddress: decodeAddress,
    private globalservice: globalService,
    private storage: StorageService
  ) {
  }

  @Output() geoRadiusEM: EventEmitter<number> = new EventEmitter();
  @Output() inputAddressEM: EventEmitter<string> = new EventEmitter();
  @Output() hideAddPickupForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createPickupPoint: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitPickPtMarkers: EventEmitter<any> = new EventEmitter<any>();

  //@Output() geocodeAddress: EventEmitter<string> = new EventEmitter<string>();

  @Input('routeId') _routeId: any;

  @Input('routeStoppages')
  set name(routeStoppages: any) {

    let getRouteName = this.storage.get('newRouteName');

    this.RouteName = getRouteName;
  }

  @Input() inputAddress: any;
  @Input() geoRadius: any;
  @Input('mapObj')
  set map(map: any) {
    this.mapObj = map;
  }


  ngOnInit() {

    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.showRouteTimeline();

    this.routeTimings = localStorage.getItem('routeTimings');
    this.RouteName = this.storage.get('pickUprouteName');

    this.studentName = this.storage.get('studentName');




  }


  //Showing route stoppages..
  showRouteTimeline() {

    let getLatestRouteID = this.storage.get('currentRouteID');
    if (getLatestRouteID) {

      this._routeId = getLatestRouteID;

    } else {
      this._routeId = this._routeId;
    }
    var routeObj = {
      data: {
        key: localStorage.getItem('scbToken'),
        filter: {
          routeId: this._routeId,
        }
      }
    };
    this.mapBounds = new google.maps.LatLngBounds();

    this.apiService.getRouteStoppages(routeObj).then(response => {
      if (response.status == 'success') {
        this.routeStoppages = response.response.pickuppoints;
        if (response.response.pickuppoints) {
          let mCount = 0;
          for (let index in this.routeStoppages) {
            ++mCount;
            let stpg = this.routeStoppages[index];
            let mLatLng = new google.maps.LatLng(stpg.pickuppointLocation.lat, stpg.pickuppointLocation.lng);
            let marker = new google.maps.Marker({
              position: mLatLng,
              map: this.mapObj,
              icon: 'http://www.googlemapsmarkers.com/v1/+' + mCount + '+/0099FF/FFFFFF/0099FF/'
            });
            this.pickupPointMarkers.push(marker);
            this.mapBounds.extend(mLatLng);
          }
          this.mapObj.fitBounds(this.mapBounds);
        }
      }
      this.createRouteMarker();
    });
  }

  //function ends here

  createRouteMarker() {

    let mLatLng;
    if (this.routeStoppages) {
      var initLat = this.routeStoppages[0].pickuppointLocation.lat;
      var initLng = this.routeStoppages[0].pickuppointLocation.lng;
      var finalLat = this.routeStoppages[this.routeStoppages.length - 1].pickuppointLocation.lat;
      var finalLng = this.routeStoppages[this.routeStoppages.length - 1].pickuppointLocation.lng;
      var midLat = this.midpoint(initLat, initLng, finalLat, finalLng, 0.2);
      mLatLng = new google.maps.LatLng(midLat[0], midLat[1]);
    } else {
      mLatLng = new google.maps.LatLng(28.7041, 77.1025);
    }
    //Creating separate route marker.
    let parent: any = this;
    this.routeCreateMarker = new google.maps.Marker({
      position: mLatLng,
      map: this.mapObj,
      draggable: true,

    });
    this.pickupGeofence = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.mapObj,
      center: mLatLng,
      radius: 200,
      editable: true
    });
    this.routeCreateMarker.addListener('dragend', function (e: any) {
      parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
      parent.updateGeocirclePosition(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()));
    });
    this.bindMapClickEvt();
    this.geoRadius = 200; //setting default geo radius to 200m.
    google.maps.event.addListener(this.pickupGeofence, 'radius_changed', function () {
      parent.geoRadius = Math.round(parent.pickupGeofence.getRadius());
      $('#pointGeoRadius').focus();
      $('#pointGeoRadius').blur();
    });
    if (!this.routeStoppages) this.mapObj.panTo(mLatLng);
    else {
      this.mapBounds.extend(mLatLng);
      this.mapObj.fitBounds(this.mapBounds);
    }

  }

  midpoint(lat1: any, long1: any, lat2: any, long2: any, per: any): Array<number> { //calculates midpoint between 2 lat-lng.
    return [lat1 + (lat2 - lat1) * per, long1 + (long2 - long1) * per];
  }

  bindMapClickEvt() {
    let parent: any = this;
    this.mapObj.addListener('click', function (e: any) {
      var clickedLatLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      parent.routeCreateMarker.setPosition(clickedLatLng);
      google.maps.event.trigger(parent.routeCreateMarker, 'dragend', e);
    });
  }

  unbindMapClickEvt() {
    google.maps.event.clearListeners(this.mapObj, 'click');
  }

  codeAddressEM() {
    //  this.inputAddressEM.emit(this.inputAddress);
  }

  hideAddPickupDir($event: any) {
    this.hidePckPtMakers();
    this.routeCreateMarker.setMap(null);
    this.pickupGeofence.setMap(null);
    this.hideAddPickupForm.emit(true);
    this.unbindMapClickEvt();
    localStorage.setItem("routeTimings", '');
  }

  hidePckPtMakers(): void {
    this.pickupPointMarkers.forEach((index: any) => {
      index.setMap(null);
    })
  }

  validateTime(time: string) {

    //let time='20:22';
    let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test(time);
    return isValid;
  }


  validateUserInputs() {
    let isValid: boolean = true;

    if (!this.pointName) {
      this.showPickupNameError = true;
      isValid = false;
    } else {
      this.showPickupNameError = false;
    }

    if (!this.inputAddress) {
      this.showInputAddressError = true;
      isValid = false;
    } else {
      this.showInputAddressError = false;
    }

    if (!(this.validateTime(this.expectedTime))) {
      this.showRouteTimeError = true;
      isValid = false;
    } else {
      this.showRouteTimeError = false;
    }

    if (!this.geoRadius) {
      this.showPointRadiusError = true;
      isValid = false
    } else {
      this.showPointRadiusError = false;
    }
    //this.createPickupPointObj();
    return isValid;


  }

  //funtion to create pickup point for emission
  createPickupPointObj() {
    if (this.isSubmitting) return; // Prevent multiple clicks

    this.isSubmitting = true; // Disable button

    let tempArr = [];
    let pickupObj = {
      "routeId": this._routeId,
      "expectedTime": this.expectedTime,
      "pickuppoint": this.pointName,
      "pickupRadius": this.geoRadius,
      'pickupAddress': this.inputAddress,
      "pickuppointLocation": {
        "lat": this.routeCreateMarker.getPosition().lat(),
        "lng": this.routeCreateMarker.getPosition().lng()
      }
    };
    tempArr.push(pickupObj);

    if (this.studentId) {
      this.storage.set({
        'assignPickupPointToStudent': this.studentId,
        'pickuppointName': this.pointName
      });
    } else {
      this.storage.set({
        'assignPickupPointToStudent': '',
        'pickuppointName': ''
      });
    }

    if (this.validateUserInputs()) {
      var apiHeader = {
        "data": {
          key: localStorage.getItem('scbToken'),
          form: {
            pickuplist: tempArr
          }
        }
      };

      this.routeCreateMarker.setMap(null);
      this.pickupGeofence.setMap(null);
      this.emitPickPtMarkers.emit(this.pickupPointMarkers);
      this.createPickupPoint.emit(apiHeader);

      // Re-enable button after API call
      setTimeout(() => {
        this.isSubmitting = false;
      }, 2000); // Adjust time based on API response
    } else {
      this.isSubmitting = false; // Re-enable if validation fails
    }
  }

  geocodeAddress() {
    let address = this.inputAddress;
    //geocoding using custom service.
    var config = {
      'address': address
    };
    this.decodeAddress.geocode(config).then(response => {
      this.routeCreateMarker.setPosition(response['geometry'].location);
      this.pickupGeofence.setCenter(response['geometry'].location);
      this.mapObj.panTo(response['geometry'].location);
    }).catch(error => { });
  }

  reverseGeocodeAddress($event: any) {
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent: any = this;
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.decodeAddress.geocode(config).then(response => {
      this.inputAddress = response['formatted_address'];
      $('#locationField').focus();
      $('#locationField').blur();
    }).catch(error => {
      console.log('Error - ', error);
    });
  }

  updateGeocirclePosition(mLatlng: any) {
    this.pickupGeofence.setCenter(mLatlng);
  }
  updateGeoradius() {
    this.pickupGeofence.setRadius(Math.round(this.geoRadius));
  }
}

/*
Notes : npm install node-sass -g
*/
