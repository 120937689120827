
/*Auther:Deepak */
import {
  Component, OnDestroy,
} from '@angular/core';
import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { authGaurd } from './../../../services/auth-guard.service';
import { decodeAddress } from './../../../services/decodeAddress.service';
import { Router } from '@angular/router';
import { StorageService } from './../../../services/storage.service';

declare var $: any;
const swal = require('sweetalert');
declare var google: any;
export interface OrgMenu {
  name: string;
  shortName?: string;
  description?: string;
  uniqueKey: string | number;
  isExpanded?: boolean;
  status: 'completed' | 'processing' | 'failed';
  subMenu?: OrgMenu[];
  getStatus?: () => string;
  updateStatus?: (e: any, key: any) => void;
  getStatusCount?: (key: any) => number;
}


@Component({
  templateUrl: './viewOrgDetails.component.html',
  styles: [`

 `],
  styleUrls: ['./only_viewOrgDetails.min.css']
})
export class ViewOrgDetailsComponent implements OnDestroy {

  public addRemarks: boolean = false;
  public organization: any = {};
  public formError: any = {};
  public errorMesages: any = [];
  public clickMessage: any;
  private currentOrganization: any = this.globalService.getData().currentOrganization;
  public contactDetails: any = [];
  private contact: any = [];
  private ReportsConfig: any = [];
  private HolidayConfig: any = [];
  public userInfo: any = {};
  public viewTracker: any = {};
  public trackerInfo: any = {};
  public showTrackerForm: boolean = false;
  public showTemplateForm: boolean = false;
  private orgPlaceMarker: any;
  private _map: any;
  // public userFormParam:any={};
  public selectedUserid: any;
  public selectedTrackerId: any;
  public selectedTemplateId: any;

  public pageJump: number = 0;
  public srchPrimaryImei: any = "";
  public srchVehicleName: any = "";
  public srchBoxId: any = "";
  public srchRegNo: any = "";
  public srchSimNum: any = "";
  public srchUserName: any = "";
  public srchUserEmail: any = "";
  public srchTemplateTitle: any = "";
  private availableCampaignTemplates: any[];
  public limitOfRecords: number;
  public ShowReportConfiguration: boolean = true;
  public ShowHolidayConfigutation: boolean = false;
  public ShowClassSectionConfiguration: boolean = false;
  public ShowSessionConfiguration: boolean = false;

  memberInfoExcelLink: string;


  /*General Alerts */

  public smsAlert: boolean;
  public appAlert: boolean;
  public emailAlert: boolean;
  public rfidAlert: boolean;
  public callAlert: boolean;

  public localLanguage: boolean;

  /*Report Selection variables */

  private rfidReport: boolean;
  private classrfidReport: boolean;
  private memberrfidReport: boolean;
  private routerfidReport: boolean;
  private rfidappPush: boolean;
  private attendencefidReport: boolean;
  private haltReport: boolean;
  private haltDurationReport: boolean;
  private movementReport: boolean;
  private movementandhaltReport: boolean;
  private overspeedReport: boolean;
  private lowspeedReport: boolean;
  private betweentwospeedReport: boolean;
  private daysummaryReport: boolean;
  private vehcoordinateReport: boolean;
  private callalertmadelogReport: boolean;
  private routecallalertmadelogReport: boolean;
  public otherOverSpeedReport: boolean;
  public notificationAlert: boolean;
  public vehicleBasedNotificationAlert: boolean;
  public breakDownReport: boolean;
  public smsLogfidReport: boolean;
  public rfidStatusCheck: boolean;
  public routeWiseRfidCount: boolean;// krishnan
  //private smsalertmadelogReport: boolean;
  //private appalertmadelogReport: boolean;
  //private alertlogsummaryReport: boolean;
  private routehistorylogReport: boolean;
  private memberhistorylogReport: boolean;
  private callLogCount: boolean;
  private callMadeReport: boolean;
  private callEligibleReport: boolean;
  private callMadeEarly: boolean;
  private callMadeDelayed: boolean;
  private nrOnTime: boolean;
  private rOnTime: boolean;
  private callProcessedReport: boolean;
  private callProcessedAnalysReport: boolean;
  private panicReport: boolean;
  private notReachedReport: boolean;
  private vehiclelastupdateReport: boolean;
  private engineReport: boolean;
  private acReport: boolean;
  private accanddeccReport: boolean;
  private routevehiclemappedReport: boolean;
  private geofenceReport: boolean;
  public showLoading: boolean = false;
  public parentAppAlert: boolean;
  public ETAAppAlert: boolean;
  public cameraModuleView: boolean;
  public ParentAppAlertstatus: string;
  public ParentAppETAAlertstatus: string;


  public studentClassList: any = [];
  public classItems = '';
  public studentSectionList: any = [];
  public sectionItems = '';

  /*Report Selection variables Ends */

  /*Weekdays Section Instances */
  private Wsunday: boolean;
  private Wmonday: boolean;
  private Wtuesday: boolean;
  private Wwednesday: boolean;
  private Wthursday: boolean;
  private Wfriday: boolean;
  private Wsaturday: boolean;
  public downloadUrl: any;
  /*Weekdays Section Instances */

  public showAddNewUserForm: boolean = false;
  public showUpdateUserForm: boolean = false;

  public showAddTrackerForm: boolean = false;
  public showUpdateTrackerForm: boolean = false;

  public showAddTemplateForm: boolean = false;
  public showUpdateTemplateForm: boolean = false;

  public showTrackerLogReport: boolean = false;

  private apiToken: any = localStorage.getItem("adminToken");
  public defineSessions: any;
  public sessionlists: any;
  public routewise_attendance: any;
  public datewise_attendance: any;
  public memberwise_attendance: any;
  private studentRouteManagement: boolean;
  private routeAnalytics: boolean;
  private routeAdhoc: boolean;
  private vehicleManagement: boolean;

  private appReportsLog: boolean
  private routeDeviation: boolean
  private faceAttendance: boolean;
  private onLoadTs: number = Date.now();
  constructor(public apiService: ApiService,
    public globalService: globalService,
    public authGaurd: authGaurd,
    private decodeAddress: decodeAddress,
    private router: Router,
    private storage: StorageService
  ) {
    //functions calls will be made here for firsttime
    this.storage.set({
      'orgID': this.currentOrganization
    });

  }

  ngOnInit() {

    this.autoReload();

    this.authGaurd.checkAdminToken();
    this.viewDetails();
    setTimeout(() => { this.initMap() }, 500);

    this.downloadUrl = `${this.apiService.base}admin/download_assignedtrackers_details&key=${this.apiToken}&orgId=${this.currentOrganization}`;


    document.body.addEventListener("mouseover", this.update_onLoadTs, false);
  }

  ngOnDestroy(): void {
    this.componentClosed = true;
    document.body.removeEventListener("mouseover", this.update_onLoadTs, false)
  }


  update_onLoadTs() {
    this.onLoadTs = Date.now();
  }

  autoReload() {
    return;

    // const diffMs = Date.now() - this.onLoadTs;
    // var diffMins = Math.round(diffMs / 60000);
    // if (diffMins >= 2) {
    //   location.reload();
    // } else {
    //   setTimeout(() => {
    //     this.autoReload();
    //   }, 20 * 1000);
    // }
  }

  storeClass() {
    if (this.studentClassList.indexOf(this.classItems) == -1) {
      this.studentClassList.push(this.classItems);
    }
    this.classItems = '';
  }
  storeSection() {
    if (this.studentSectionList.indexOf(this.sectionItems) == -1) {
      this, this.studentSectionList.push(this.sectionItems);
    }
    this.sectionItems = '';
  }

  //function to remove the contact information from temporary array
  removeTag(i: number) {
    this.studentClassList.splice(i, 1);
  }
  removeSectionTag(i: number) {
    this.studentSectionList.splice(i, 1);
  }


  initMap(): void {
    // console.log(this.organization);
    if (this.organization.orgLocation) {
      let plotLat = parseFloat(this.organization.orgLocation.lattitude);
      let plotLong = parseFloat(this.organization.orgLocation.longitude);
      let mapOptions = {
        scrollwheel: true,
        zoom: 16,
        draggable: true,
        // center: new google.maps.LatLng(28.7041, 77.1025),
        center: new google.maps.LatLng(plotLat, plotLong),
        disableDefaultUI: false //disables default controls
      };
      this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
      this.orgPlaceMarker = new google.maps.Marker({
        position: new google.maps.LatLng(plotLat, plotLong),
        map: this._map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        icon: {
          url: './.../../assets/webroot/images/map/schoolMarker1.png',
          //size: new google.maps.Size(64, 64),
          scaledSize: new google.maps.Size(45, 45),
        },
      });
      this._map.panTo(new google.maps.LatLng(plotLat, plotLong))
      //add events for marker.
      let parent = this;
      this.orgPlaceMarker.addListener('dragend', function (e: any) {
        parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
      });
      this.bindMapClickEvt();
    } else {

      let mapOptions = {
        scrollwheel: true,
        zoom: 14,
        center: new google.maps.LatLng(28.7041, 77.1025),
        disableDefaultUI: true //disables default controls
      };
      this._map = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);
      this.orgPlaceMarker = new google.maps.Marker({
        position: new google.maps.LatLng(28.7041, 77.1025),
        map: this._map,
        draggable: true,
      });
      this._map.panTo(new google.maps.LatLng(28.7041, 77.1025))
      //add events for marker.
      let parent = this;
      this.orgPlaceMarker.addListener('dragend', function (e: any) {
        parent.reverseGeocodeAddress(new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()))
      });
      this.bindMapClickEvt();
    }

  }

  bindMapClickEvt() {
    let parent: any = this;
    this._map.addListener('click', function (e: any) {
      var clickedLatLng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      parent.orgPlaceMarker.setPosition(clickedLatLng);
      google.maps.event.trigger(parent.orgPlaceMarker, 'dragend', e);
    });
  }

  geocodeAddress() {
    let address = this.organization.dropPointName;
    //geocoding using custom service.
    var config = {
      'address': address
    };
    this.decodeAddress.geocode(config).then(response => {
      this.orgPlaceMarker.setPosition(response['geometry'].location);
      this._map.panTo(response['geometry'].location);

    }).catch(error => { });
  }
  reverseGeocodeAddress($event: any) {
    let geocoder = new google.maps.Geocoder();
    let address = $event;
    let parent: any = this;
    //reverse geocoding using custom service.
    var config = {
      'latLng': $event
    }
    this.decodeAddress.geocode(config).then(response => {
      this.organization.dropPointName = response['formatted_address'];
      $('#locationField').focus();
      $('#locationField').blur();
    }).catch(error => {
      console.log('Error - ', error);
    });
  }


  StatusToggle() {
    this.ParentAppAlertstatus = "Alerts Disabled"
  }


  viewDetails() {



    this.apiService.orgView({
      data: {
        key: this.apiToken,
        filter: {
          organizationId: this.currentOrganization
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        if (result.response[0] != null && result.response[0] != undefined && result.response[0] != '') {
          this.organization = result.response[0];
          this.parentAppAlert = this.organization.alertlock;
          this.ETAAppAlert = this.organization.etaAlert;
          this.cameraModuleView = this.organization.cameraModuleView;

          this.smsAlert = this.organization.smsAlert;
          this.appAlert = this.organization.appAlert;
          this.emailAlert = this.organization.emailAlert;
          this.callAlert = this.organization.callAlert;
          this.rfidAlert = this.organization.rfidAlert;
          this.localLanguage = this.organization.otherlang;

          if (this.parentAppAlert === true) {
            this.ParentAppAlertstatus = "Alerts Enabled"
          } else {
            this.StatusToggle();
          }
          if (this.ETAAppAlert === true) {
            this.ParentAppETAAlertstatus = "ETA Alerts Enabled";
          } else {
            this.ParentAppETAAlertstatus = "ETA Alerts Disabled"
          }
          if (this.organization.contactInformation != null &&
            this.organization.contactInformation != undefined &&
            this.organization.contactInformation != '') {

            this.contact = this.organization.contactInformation;
            this.contactDetails = $.map(this.contact, function (value: any, index: any) {
              return [value]
            });

          }
          if (this.organization.orgLocation) {
            this.organization.dropPointName = this.organization.orgLocation.locationAddress;
          }
          // console.log(this.organization.reports)
          if (this.organization.reports != null && this.organization.reports != undefined && this.organization.reports != '') {
            this.ReportsConfig = this.organization.reports;
            this.rfidReport = this.ReportsConfig.rfid.general;
            this.classrfidReport = this.ReportsConfig.rfid.RFIDWithClass;
            this.memberrfidReport = this.ReportsConfig.rfid.RFIDWithMember;
            this.routerfidReport = this.ReportsConfig.rfid.RFIDWithRoute;
            this.rfidappPush = this.ReportsConfig.rfid.RFIDAppPush;//srini
            this.attendencefidReport = this.ReportsConfig.rfid.RFIDWithAttendance;
            this.smsLogfidReport = this.ReportsConfig.rfid.RFIDWithSmsLog;
            this.rfidStatusCheck = this.ReportsConfig.rfid.RFIDStatusCheck;
            this.routeWiseRfidCount = this.ReportsConfig.rfid.RFIDRouteWiseCount;//krishnan actual RFIDRouteWiseCount previous  routeWiseRFIDCount
            this.haltReport = this.ReportsConfig.tracking.halt;
            this.haltDurationReport = this.ReportsConfig.tracking.haltDurationReport;
            this.movementReport = this.ReportsConfig.tracking.movement;
            this.movementandhaltReport = this.ReportsConfig.tracking.movementandhalt;
            this.overspeedReport = this.ReportsConfig.tracking.overspeed;
            this.lowspeedReport = this.ReportsConfig.tracking.lowspeed;
            this.betweentwospeedReport = this.ReportsConfig.tracking.betweentwospeed;
            this.daysummaryReport = this.ReportsConfig.tracking.daysummary;
            this.vehcoordinateReport = this.ReportsConfig.tracking.vehcoordinate;
            this.callalertmadelogReport = this.ReportsConfig.alertlog.callalertmadelog;
            this.routecallalertmadelogReport == this.ReportsConfig.alertlog.routecallalertmadelog;
            //this.smsalertmadelogReport = this.ReportsConfig.alertlog.smsalertmadelog;
            //this.appalertmadelogReport = this.ReportsConfig.alertlog.appalertmadelog;
            //this.alertlogsummaryReport = this.ReportsConfig.alertlog.alertlogsummary;
            this.routehistorylogReport = this.ReportsConfig.alertlog.routehistorylog;
            this.memberhistorylogReport = this.ReportsConfig.alertlog.memberhistorylog;
            this.callLogCount = this.ReportsConfig.alertlog.callLogCount;
            this.callMadeReport = this.ReportsConfig.alertlog.callMadeReport;

            this.callEligibleReport = this.ReportsConfig.alertlog.callEligibleReport;
            this.callMadeEarly = this.ReportsConfig.alertlog.callMadeEarly;
            this.callMadeDelayed = this.ReportsConfig.alertlog.callMadeDelayed;
            this.nrOnTime = this.ReportsConfig.alertlog.nrOnTime;
            this.rOnTime = this.ReportsConfig.alertlog.rOnTime;

            this.callProcessedReport = this.ReportsConfig.alertlog.callProcessedReport;
            this.callProcessedAnalysReport = this.ReportsConfig.alertlog.callProcessedAnalysReport;
            this.panicReport = this.ReportsConfig.others.panic;
            this.notReachedReport = this.ReportsConfig.analytics.notreached;
            this.vehiclelastupdateReport = this.ReportsConfig.others.vehiclelastupdate;
            this.engineReport = this.ReportsConfig.others.engine;
            this.acReport = this.ReportsConfig.others.ac;
            this.accanddeccReport = this.ReportsConfig.others.accanddecc;
            this.routevehiclemappedReport = this.ReportsConfig.others.routevehiclemapped;
            this.geofenceReport = this.ReportsConfig.others.geofence;
            this.otherOverSpeedReport = this.ReportsConfig.others.otherOverspeed;
            this.notificationAlert = this.ReportsConfig.others.notificationAlert;
            this.vehicleBasedNotificationAlert = this.ReportsConfig.others.vehicleBasedNotificationAlert;
            this.breakDownReport = this.ReportsConfig.others.breakDownReport;

            if (this.ReportsConfig['studentroutemanagement'] == undefined) {
              this.ReportsConfig.studentroutemanagement = {
                studentroutemanagement: false
              }
            }

            if (this.ReportsConfig.alertlog['appReportsLog'] == undefined) {
              this.ReportsConfig.alertlog.appReportsLog = {
                appReportsLog: false
              }
            }

            if (this.ReportsConfig.tracking['routeDeviation'] == undefined) {
              this.ReportsConfig.tracking.routeDeviation = {
                routeDeviation: false
              }
            }
            if (this.ReportsConfig['routeanalytics'] == undefined) {
              this.ReportsConfig.routeanalytics = {
                routeanalytics: false
              }
            }
            if (this.ReportsConfig['routeAdhoc'] == undefined) {
              this.ReportsConfig.routeAdhoc = {
                routeAdhoc: false
              }
            }
            if (this.ReportsConfig['vehicleManagement'] == undefined) {
              this.ReportsConfig.vehicleManagement = {
                vehicleManagement: false
              }
            }
            if (this.ReportsConfig['faceAttendance'] == undefined) {
              this.ReportsConfig.faceAttendance = {
                faceAttendance: false
              }
            }
            this.studentRouteManagement = this.ReportsConfig.studentroutemanagement.studentRouteManagement
            this.routeAnalytics = this.ReportsConfig.routeanalytics.routeAnalytics
            this.routeAdhoc = this.ReportsConfig.routeAdhoc.routeAdhoc
            this.vehicleManagement = this.ReportsConfig.vehicleManagement.vehicleManagement
            this.faceAttendance = this.ReportsConfig.faceAttendance.faceAttendance

            this.appReportsLog = this.ReportsConfig.alertlog.appReportsLog;
            this.routeDeviation = this.ReportsConfig.tracking.routeDeviation;

            if (this.ReportsConfig.hasOwnProperty('attendance')) {


              this.routewise_attendance = this.ReportsConfig.attendance.routewiseAttendance
              this.datewise_attendance = this.ReportsConfig.attendance.datewiseAttendance
              this.memberwise_attendance = this.ReportsConfig.attendance.memberwiseAttendance

            }


          }

          if (this.organization.weekdays != null && this.organization.weekdays != undefined && this.organization.weekdays != '') {
            this.HolidayConfig = this.organization.weekdays;
            this.Wsunday = this.HolidayConfig.sunday;
            this.Wmonday = this.HolidayConfig.monday;
            this.Wtuesday = this.HolidayConfig.tuesday;
            this.Wwednesday = this.HolidayConfig.wednesday;
            this.Wthursday = this.HolidayConfig.thursday;
            this.Wfriday = this.HolidayConfig.friday;
            this.Wsaturday = this.HolidayConfig.saturday;
          }

          if (this.organization.SectionLists != '' && this.organization.SectionLists != undefined) {
            this.studentSectionList = this.organization.SectionLists;
          }
          if (this.organization.classLists != '' && this.organization.classLists != undefined) {
            this.studentClassList = this.organization.classLists;
          }
          let sessionlists = response.response[0].schoolsessionLists

          this.defineSessions = [];

          this.defineSessions = sessionlists;
        }

      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }





  validator() {
    let errorFlag = false; this.errorMesages = [];


    //General Information

    //Set Name ------
    this.formError.OrganizationName = false;
    if (this.organization.name == "" || this.organization.name == undefined) {
      this.formError.OrganizationName = true;
      errorFlag = true; (this.errorMesages).push("Name Should not be empty");
    }
    //Set Category ------
    this.formError.OrganizationCategory = false;
    if (this.organization.category == "" || this.organization.category == undefined) {
      this.formError.OrganizationCategory = true;
      errorFlag = true; (this.errorMesages).push("Category Should not be empty");
    }
    //Set Address
    this.formError.OrganizationAddress = false;
    if (this.organization.address == "" || this.organization.address == undefined) {
      this.formError.OrganizationAddress = true;
      errorFlag = true; (this.errorMesages).push("Address Must be filled out");
    }

    //Set Area ------
    this.formError.OrganizationArea = false;
    if (this.organization.area == "" || this.organization.area == undefined) {
      this.formError.OrganizationArea = true;
      errorFlag = true; (this.errorMesages).push("Area should not be empty");
    }

    //Set City
    this.formError.OrganizationCity = false;
    if (this.organization.city == "" || this.organization.city == undefined) {
      this.formError.OrganizationCity = true;
      errorFlag = true; (this.errorMesages).push("City should not be empty");
    }

    //set state
    this.formError.OrganizationState = false;
    if (this.organization.state == "" || this.organization.state == undefined) {
      this.formError.OrganizationState = true;
      errorFlag = true; (this.errorMesages).push("State should not be empty");
    }

    //set Country
    this.formError.OrganizationCountry = false;
    if (this.organization.country == "" || this.organization.country == undefined) {
      this.formError.OrganizationCountry = true;
      errorFlag = true; (this.errorMesages).push("Country should not be empty");
    }

    //set Email
    this.formError.OrganizationEmail = false;
    if (this.organization.email == "" || this.organization.email == undefined) {
      this.formError.OrganizationEmail = true;
      errorFlag = true; (this.errorMesages).push("Email should not be empty");
    }

    this.formError.OrganizationLocation = false;
    if (this.organization.dropPointName == "" || this.organization.dropPointName == undefined) {
      this.formError.OrganizationLocation = true;
      errorFlag = true; (this.errorMesages).push("Location should not be empty");
    }

    this.formError.OrganizationStartTime = false;
    if (this.organization.orgStartTime == "" || this.organization.orgStartTime == undefined) {
      this.formError.OrganizationStartTime = true;
      errorFlag = true; (this.errorMesages).push("Start time should not be empty");
    }

    this.formError.OrganizationEndTime = false;
    if (this.organization.orgEndTime == "" || this.organization.orgEndTime == undefined) {
      this.formError.OrganizationEndTime = true;
      errorFlag = true; (this.errorMesages).push("End time should not be empty");
    }


    if (this.organization.orgEndTime && this.organization.orgStartTime) {
      if (this.organization.orgStartTime > this.organization.orgEndTime) {
        this.formError.OrganizationTimingMismatch = true
        errorFlag = true;
      } else {
        this.formError.OrganizationTimingMismatch = false;
      }
    }




    if (errorFlag == false) { this.formUpdate(); }
  }


  /*organization deatils update here **/
  formUpdate() {

    if (this.defineSessions && this.defineSessions.length == 0 || typeof this.defineSessions == 'string') {
      this.defineSessions = "clear"
    }

    this.apiService.orgUpdate({
      data: {
        key: this.apiToken,
        form: {
          name: this.organization.name,
          category: this.organization.category,
          address: this.organization.address,
          area: this.organization.area,
          city: this.organization.city,
          state: this.organization.state,
          country: this.organization.country,
          website: this.organization.website,
          email: this.organization.email,
          description: this.organization.description,
          orgStartTime: this.organization.orgStartTime,
          orgEndTime: this.organization.orgEndTime,
          status: 'active',
          callingURL: this.organization.callingURL,
          alertlock: this.parentAppAlert + "",
          etaAlert: this.ETAAppAlert + "",
          cameraModuleView: this.cameraModuleView + "",
          smsAlert: this.smsAlert + "",
          appAlert: this.appAlert + "",
          emailAlert: this.emailAlert + "",
          callAlert: this.callAlert + "",
          rfidAlert: this.rfidAlert + "",
          otherlang: this.localLanguage + "",
          contactInformation: {
            0: {
              name: this.contact[0].name,
              phone: this.contact[0].phone,
              email: this.contact[0].email,
              designation: this.contact[0].designation
            }
          },
          location: {
            lattitude: this.orgPlaceMarker.getPosition().lat(),
            longitude: this.orgPlaceMarker.getPosition().lng(),
            // lattitude:'28.4644317274505',
            // longitude:'77.1036194153443',

            locationAddress: this.organization.dropPointName
          },
          classLists: this.studentClassList,
          SectionLists: this.studentSectionList,
          reports: {
            rfid: {
              general: this.rfidReport,
              RFIDWithClass: this.classrfidReport,
              RFIDWithMember: this.memberrfidReport,
              RFIDWithRoute: this.routerfidReport,
              RFIDAppPush: this.rfidappPush,
              RFIDWithAttendance: this.attendencefidReport,
              RFIDWithSmsLog: this.smsLogfidReport,
              RFIDStatusCheck: this.rfidStatusCheck,
              RFIDRouteWiseCount: this.routeWiseRfidCount //krishnan
            },
            tracking: {
              movement: this.movementReport,
              halt: this.haltReport,
              haltDurationReport: this.haltDurationReport,
              movementandhalt: this.movementandhaltReport,
              overspeed: this.overspeedReport,
              lowspeed: this.lowspeedReport,
              betweentwospeed: this.betweentwospeedReport,
              daysummary: this.daysummaryReport,
              vehcoordinate: this.vehcoordinateReport,
              routeDeviation: this.routeDeviation,

            },
            alertlog: {
              callalertmadelog: this.callalertmadelogReport,
              routecallalertmadelog: this.routecallalertmadelogReport,
              //smsalertmadelog: this.smsalertmadelogReport,
              //appalertmadelog: this.appalertmadelogReport,
              //alertlogsummary: this.alertlogsummaryReport,
              routehistorylog: this.routehistorylogReport,
              memberhistorylog: this.memberhistorylogReport,
              callLogCount: this.callLogCount,
              callMadeReport: this.callMadeReport,
              callEligibleReport: this.callEligibleReport,
              callMadeEarly: this.callMadeEarly,
              callMadeDelayed: this.callMadeDelayed,
              nrOnTime: this.nrOnTime,
              rOnTime: this.rOnTime,
              callProcessedReport: this.callProcessedReport,
              callProcessedAnalysReport: this.callProcessedAnalysReport,
              appReportsLog: this.appReportsLog
            },
            analytics: {
              notreached: this.notReachedReport,

            },
            others: {
              panic: this.panicReport,
              vehiclelastupdate: this.vehiclelastupdateReport,
              engine: this.engineReport,
              ac: this.acReport,
              accanddecc: this.accanddeccReport,
              routevehiclemapped: this.routevehiclemappedReport,
              geofence: this.geofenceReport,
              otherOverspeed: this.otherOverSpeedReport,
              notificationAlert: this.notificationAlert,
              vehicleBasedNotificationAlert: this.vehicleBasedNotificationAlert,
              breakDownReport: this.breakDownReport
            },
            attendance: {
              routewiseAttendance: this.routewise_attendance,
              datewiseAttendance: this.datewise_attendance,
              memberwiseAttendance: this.memberwise_attendance,

            },
            studentroutemanagement: {
              studentRouteManagement: this.studentRouteManagement,


            },
            routeanalytics: {
              routeAnalytics: this.routeAnalytics,


            },
            routeAdhoc: {
              routeAdhoc: this.routeAdhoc,


            },
            vehicleManagement: {
              vehicleManagement: this.vehicleManagement,


            },

            faceAttendance: {
              faceAttendance: this.faceAttendance,


            },

          },
          weekdays: {
            sunday: this.Wsunday,
            monday: this.Wmonday,
            tuesday: this.Wtuesday,
            wednesday: this.Wwednesday,
            thursday: this.Wthursday,
            friday: this.Wfriday,
            saturday: this.Wsaturday
          },
          schoolsessionLists: this.defineSessions
        },
        filter: {
          organizationId: this.currentOrganization
        }
      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        swal("Success", "Oragnization Updated Successfully", "success", {
        });
        setTimeout(() => {
          swal.close();
          this.defineSessions = [];
          this.viewDetails()
        }, 2000);
      }
    })
      .catch(error => {
        // console.log('Error while creating organization', error);
        swal("Failure", "Error while updating organization", "error");
      });
  }
  /*organization deatils update code ends here */




  /*Table view for users and tracker begins here */
  filterUserFunction() {
    this.pageJump = 0;
    this.viewUsers();
    this.srchUserName = "";
    this.srchUserEmail = "";
  }

  clearUserFilterFunction() {
    this.srchUserName = "";
    this.srchUserEmail = "";
    this.viewUsers();
  }
  finalSessionShow($event: any) {
    this.defineSessions = [];
    this.defineSessions = $event.finalSession

  }
  viewUsers() {
    this.apiService.orgUserView({
      data: {
        key: this.apiToken,
        filter: {
          organizationId: this.currentOrganization,
          name: this.srchUserName,
          email: this.srchUserEmail
        },
        extra: {
          orderByDateCreated: -1,
          pageJump: this.pageJump
        }

      }
    }).then(response => {
      let result = response;
      if (result.status == "success") {
        this.userInfo = response.response;
      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }


  /*Delete Org Users */
  deleteOrgUser(userId: any, orgId: any) {
    const parent = this;
    var usrObj = {
      data: {
        key: this.apiToken,
        form: {
          userId: userId,
          organizationId: orgId
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'This user will be Removed from the organization',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.orgUserDelete(usrObj).then(response => {
          if (response) {
            parent.viewUsers();
          }
        }).catch(error => { });
      } else {
        //  swal('Cancelled', 'Action aborted :)', 'error');
      }
    });
  }

  filterFunction() {
    this.pageJump = 0;
    this.viewTrackers();
    this.srchPrimaryImei = "";
    this.srchRegNo = "";
    this.srchBoxId = "";
    this.srchSimNum = "";
    this.srchVehicleName = "";

  }
  public PImei: any;
  public TBoxId: any;
  public simNumber: any;
  public vehName: any;
  clearFilter() {  //function for clearing all the filters

    this.srchPrimaryImei = "";
    this.srchRegNo = "";
    this.srchBoxId = "";
    this.srchSimNum = "";
    this.srchVehicleName = "";
    this.viewTrackers();
  }
  clearFilterval() {
    this.PImei = '';
    this.TBoxId = '';
    this.simNumber = '';
    this.vehName = '';
    this.clearFilter();
  }

  viewTrackers() {


    this.apiService.orgTrackerView({
      data: {
        key: this.apiToken,
        filter: {
          organizationId: this.currentOrganization,
          imei: this.srchPrimaryImei,
          boxid: this.srchBoxId,
          simCard: this.srchSimNum,
          // travelsname: this.srchVehicleName,
          // regNo: this.srchRegNo
          regNo: this.srchVehicleName,

        },
        extra: {
          orderByDateCreated: -1,
          pageJump: this.pageJump
        }
      }
    }).then(response => {
      let result = response;
      if (result.status = "success") {
        this.trackerInfo = response.response;
      }
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }



  deleteOrgTracker(trackerId: any, orgId: any) {
    let parent = this;
    var trackerObj = {
      data: {
        key: this.apiToken,
        form: {
          trackerId: trackerId,
          organizationId: orgId
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'This tracker will be Removed from the organization',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.orgTrackerdelete(trackerObj).then(response => {
          if (response) {
            parent.viewTrackers();
          }
        }).catch(error => { });
      } else {
        //swal('Cancelled', 'Action aborted :)', 'error');
      }
    });
  }


  filterTemplateFunction = () => {
    this.pageJump = 0;
    this.getAvailableCampaignTemplatesAdmin();
    this.srchTemplateTitle = ""
  }
  /*ES6 Methods are newly added for adding the campaign templates for each organmizations*/

  getAvailableCampaignTemplatesAdmin = () => {
    this.apiService.viewOrgMessageTemplateAdmin({
      data: {
        key: this.apiToken,
        filter: {
          orgId: this.currentOrganization,
          templateTitle: this.srchTemplateTitle
        },
        extra: {
          orderByDateCreated: "-1",
          pageJump: this.pageJump
        }
      }
    }).then(response => {
      let result = response;
      if (result.status = "success")
        this.availableCampaignTemplates = response.response;
      if (this.pageJump == 0)
        this.limitOfRecords = this.availableCampaignTemplates.length;
    }).catch(error => {
      console.log('Error while logging in', error);
    });
  }



  deleteCampaignTemplate = (templateID: any): void => {
    const parent = this;
    let requestHeader = {
      data: {
        key: this.apiToken,
        form: {
          templateId: templateID,
          orgId: this.currentOrganization
        }
      }
    };
    swal({
      title: 'Are you sure?',
      text: 'This template will be Removed from the organization',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.apiService.deleteCampaignTemplate(requestHeader).then(response => {
          if (response.status == 'success') {
            parent.getAvailableCampaignTemplatesAdmin();
          }
        }).catch(error => { });
      } else {
        // swal('Cancelled', 'Action aborted :)', 'error');
      }
    });
  }

  /*@end ES6 methods campaign templates @end*/

  /*open directive empty*/

  userAddformEvent($event: any) {
    this.viewUsers();
    setTimeout(() => this.showAddNewUserForm = $event, 1000);
  }

  templateAddformEvent($event: any) {
    this.getAvailableCampaignTemplatesAdmin();
    setTimeout(() => this.showAddTemplateForm = $event, 1000);
  }

  trackerAddformEvent($event: any) {
    setTimeout(() => this.showAddTrackerForm = $event, 1000);
  }

  userUpdateFormEvent($event: any) {
    this.viewUsers();
    setTimeout(() => this.showUpdateUserForm = $event, 1000);
  }

  trackerUpdateFormEvent($event: any) {

    setTimeout(() => this.showUpdateTrackerForm = $event, 1000);
    this.viewTrackers();
  }

  templateUpdateFormEvent($event: any) {
    this.getAvailableCampaignTemplatesAdmin();
    setTimeout(() => this.showUpdateTemplateForm = $event, 1000);
  }

  passCurrentUserId(id: any) {
    this.selectedUserid = id;
  }

  passCurrentTrackerId(id: any) {
    this.selectedTrackerId = id;
  }

  passCurrentTemplateId(id: any) {
    this.selectedTemplateId = id;
  }
  /*open directive empty*/


  downloadMemberInfo() {
    this.memberInfoExcelLink = `${this.apiService.base}memberdetails/download_allmemberDetails&key=${this.apiToken}&orgId=${this.currentOrganization}`;
  }

  quickUpdateOrganization() {

    let parent = this;
    swal({
      title: 'Are you sure?',
      text: 'update organization',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.showLoading = true;
        let requestHeader = {
          data: {
            key: parent.apiToken,
            form: {
              orgId: parent.currentOrganization
            }
          }
        };

        parent.apiService.orgFullUDetailsUpdate(requestHeader).then(response => {
          if (response.status == 'success') {
            swal({
              title: 'Success!',
              text: 'Organization updated successfully',
              icon: 'success',
              timer: 2000
            })
            parent.showLoading = false;
          }
        }).catch(error => { });
      } else {
      }
    });


    // swal({
    //   title: "Are you sure?",
    //   text: "Want to update!",
    //   type: "warning",
    //   showCancelButton: true,
    //   confirmButtonClass: "btn-danger",
    //   confirmButtonText: "Yes, delete it!",
    //   cancelButtonText: "No, cancel plx!",
    //   closeOnConfirm: false,
    //   closeOnCancel: false
    // }).then(function (isConfirm: any) {   
    //   if (isConfirm) {

    //   } else {
    //     //swal("Cancelled", "Your imaginary file is safe :)", "error");
    //   }
    // });



  }

  public updateSyncObj: OrgMenu[] = [];
  public syncObj: any = [];
  public enableSyncProgress: boolean = false;
  public componentClosed = false;

  newQuickUpdateOrganization() {
    let parent = this;
    swal({
      title: 'Are you sure?',
      text: 'update organization',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        parent.updateSyncOrder();
      }
    });
  }

  async updateSyncOrder() {
    this.syncObj = [
      {
        name: 'Global Setting',
        shortName: 'GS',
        uniqueKey: 'GS',
        isExpanded: false,
        status: 'processing', //'completed' || 'processing' || 'failed',
        subMenu: [
          {
            name: 'Notification restriction',
            shortName: 'N',
            uniqueKey: 'GS-1',
            status: 'processing',
          },
          {
            name: 'Alert restriction',
            shortName: 'A',
            uniqueKey: 'GS-2',
            status: 'processing',
          },
          {
            name: 'Holiday',
            shortName: 'N',
            uniqueKey: 'GS-1',
            status: 'processing',
          },
        ],
      },
      {
        name: 'Route Information',
        shortName: 'RI',
        uniqueKey: 'RI',
        isExpanded: false,
        percentage: { total: 0, process: 0, percent: 0 },
        status: 'processing',
        subMenu: [
          {
            name: 'Route Information',
            shortName: 'RI',
            uniqueKey: 'RI-1',
            status: 'processing',
          },
          {
            name: 'Vehicle Information',
            shortName: 'VI',
            uniqueKey: 'RI-1',
            status: 'processing',
          },
          {
            name: 'Pickup points',
            shortName: 'PP',
            uniqueKey: 'RI-2',
            status: 'processing',
          },
        ],
      },
      {
        name: 'Member Information',
        shortName: 'M',
        uniqueKey: 'M',
        isExpanded: false,
        percentage: { total: 0, process: 0, percent: 0 },
        status: 'processing',
        subMenu: [
          {
            name: 'Student Information',
            shortName: 'SI',
            uniqueKey: 'M-1',
            status: 'processing',
          },
          {
            name: 'RFID',
            shortName: 'R',
            uniqueKey: 'M-2',
            status: 'processing',
          },
          {
            name: 'IMEI',
            shortName: 'IMEI',
            uniqueKey: 'MI-1',
            status: 'processing',
          },
        ],
      },
      // {
      //   name: 'Member IMEI',
      //   shortName: 'MI',
      //   uniqueKey: 'MI',
      //   isExpanded: false,
      //   status: 'processing',
      //   subMenu: [
      //     {
      //       name: 'IMEI',
      //       shortName: 'IMEI',
      //       uniqueKey: 'MI-1',
      //       status: 'processing', 
      //     },
      //   ],
      // },
    ];
    this.enableSyncProgress = true;
    let failureMsg = '';
    try {
      if (this.componentClosed) throw ({ message: 'closed.' })
      await this.syncSettingUpdate()
        .then(() =>
          this.updateOrgMenuStatus(0, 'completed', false)
        )
        .catch((err: any) => {
          this.updateOrgMenuStatus(0, 'failed', false);
          failureMsg = err.message ? err.message : 'Organization settings update failed';
        })

      if (this.componentClosed) throw ({ message: 'closed.' })
      await this.syncRouteUpdate()
        .then(() =>
          this.updateOrgMenuStatus(1, 'completed', false)
        )
        .catch((err: any) => {
          this.updateOrgMenuStatus(1, 'failed', false);
          failureMsg = err.message ? err.message : 'Route update failed';
        })

      if (this.componentClosed) throw ({ message: 'closed.' })
      let memberSyncCompleted = false;
      await this.syncMemberUpdate()
        .then(() =>
          memberSyncCompleted = true
        )
        .catch((err: any) => {
          this.updateOrgMenuStatus(2, 'failed', false);
          failureMsg = err.message ? err.message : 'Member information update failed';
        })
      if (memberSyncCompleted) {
        if (this.componentClosed) throw ({ message: 'closed.' })
        await this.syncMemberListUpdate()
          .then(() =>
            this.updateOrgMenuStatus(2, 'completed', false)
          )
          .catch((err: any) => {
            this.updateOrgMenuStatus(2, 'failed', false);
            failureMsg = err.message ? err.message : 'Member IMEI update failed';
          })
      }

      if (!this.componentClosed) {

        let completed = true;

        this.syncObj.map((menu: any) => {
          if (menu.status == 'failed') {
            this.updateFailure(failureMsg);
            completed = false;
          } else if (menu.status == 'processing') {
            completed = false;
          }
        })

        if (completed) this.updateSuccess();
      }
    } catch (err) {
      console.log(err);
    }

  }

  syncSettingUpdate() {
    return new Promise(async (resolve: any, reject: any) => {
      let requestHeader: any = {
        data: {
          key: this.apiToken,
          form: {
            orgId: this.currentOrganization
          }
        }
      };
      requestHeader['data']['extra'] = { settingSync: true };
      if (this.componentClosed) throw ({ message: 'closed.' })
      await this.syncApiUpdate(requestHeader).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  }
  syncRouteUpdate() {
    return new Promise(async (resolve: any, reject: any) => {
      let requestHeader: any = {
        data: {
          key: this.apiToken,
          form: {
            orgId: this.currentOrganization
          },
        }
      };
      let getRoutes: any = [];
      requestHeader['data']['extra'] = { getRoutes: true };

      if (this.componentClosed) throw ({ message: 'closed.' })
      await this.syncApiUpdate(requestHeader).then((res: any) => {
        if (res.routes && res.routes.length > 0) {
          this.syncObj[1].percentage.total = res.routes.length;
          getRoutes = this.splitArray(res.routes);
        }
      }).catch((err) => {
        reject({ message: 'Collecting route information failed', err: err });
      });
      if (getRoutes.length > 0) {
        for (let i = 0; i < getRoutes.length; i++) {
          requestHeader['data']['extra'] = { routeSync: true };
          requestHeader['data']['form']['routeId'] = getRoutes[i];
          if (this.componentClosed) throw ({ message: 'closed.' })
          await this.syncApiUpdate(requestHeader).then((res: any) => {
            this.syncObj[1].percentage.process = this.syncObj[1].percentage.process + getRoutes[i].length;
            if (((this.syncObj[1].percentage.process / this.syncObj[1].percentage.total) * 100) > 100) {
              this.syncObj[1].percentage.percent = 100;
            } else {
              this.syncObj[1].percentage.percent = (this.syncObj[1].percentage.process / this.syncObj[1].percentage.total) * 100;
            }
            if (i == getRoutes.length - 1) {
              resolve(res);
            }
          }).catch((err) => {
            reject({ message: 'Collecting route information failed', err: err });
          });
        }
      }
    })
  }

  syncMemberUpdate() {
    return new Promise(async (resolve: any, reject: any) => {
      let requestHeader: any = {
        data: {
          key: this.apiToken,
          form: {
            orgId: this.currentOrganization
          }
        }
      };
      requestHeader['data']['extra'] = { memberSync: true };
      if (this.componentClosed) throw ({ message: 'closed.' })
      await this.syncApiUpdate(requestHeader).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject({ message: 'Member information failed', err: err });
      });
    })
  }

  syncMemberListUpdate() {
    return new Promise(async (resolve: any, reject: any) => {
      let requestHeader: any = {
        data: {
          key: this.apiToken,
          form: {
            orgId: this.currentOrganization
          }
        }
      };
      let count = 1;
      while (count) {
        if (this.componentClosed) throw ({ message: 'closed.' })
        requestHeader['data']['extra'] = { memberListSync: true, page: count };
        await this.syncApiUpdate(requestHeader).then((res: any) => {
          if (count == 1 && res.totCnt) {
            this.syncObj[2].percentage.total = res.totCnt;
          }

          this.syncObj[2].percentage.process = this.syncObj[2].percentage.process + res.count;
          if (((this.syncObj[2].percentage.process / this.syncObj[2].percentage.total) * 100) > 100) {
            this.syncObj[2].percentage.percent = 100;
          } else {
            this.syncObj[2].percentage.percent = (this.syncObj[2].percentage.process / this.syncObj[2].percentage.total) * 100;
          }

          if (res.count == 0) {
            count = 0;
            resolve(res);
          } else {
            count++;
          }
        }).catch((err) => {
          count = 0;
          reject({ message: 'Member IMEI update failed', err: err });
        });
      }
    })
  }

  syncApiUpdate(obj: any) {
    return new Promise((res: any, rej: any) => {
      this.apiService.orgSettingDetailsUpdate(obj).then((response) => {
        if (res) res(response)
        else rej({})
      }).catch((err) => rej(err));
    })
  }

  splitArray(arr: any[], size: number = 10) {
    if (Array.isArray(arr)) {
      var subArrayCount = arr.length / size;
      var res = [];
      for (let i = 0; i < subArrayCount; i++) {
        var from = size * i;
        var to = (size * (1 + i));
        var sliced = arr.slice(from, to);
        res.push(sliced);
      }
      return res;
    } return arr;
  }

  updateSuccess() {
    let parent = this;
    swal({
      title: 'Success',
      text: 'Organization updated successfully.',
      icon: 'success',
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        // parent.enableSyncProgress = false;
        // parent.syncObj = [];
      }
    });
  }

  updateFailure(text: string = '') {
    let parent = this;
    swal({
      title: 'Organization update failed!',
      text: text,
      icon: 'warning'
    }).then(function (isConfirm: any) {
      if (isConfirm) {
        // parent.enableSyncProgress = false;
        // parent.syncObj = [];
      }
    });
  }

  updateOrgMenuStatus(index: number, s: any, key: any = false) {
    if (this.syncObj[index] && this.syncObj[index]['subMenu'].length > 0) {
      if (key) {
        let findex = this.syncObj[index]['subMenu'].findIndex((item: any) => item.uniqueKey == key);
        if (findex != -1) {
          this.syncObj[index]['subMenu'].status = s;
        }
      } else {
        this.syncObj[index]['subMenu'] = this.syncObj[index]['subMenu'].map((item: any) => { item.status = s; return item })
      }
    }
    this.getOrgMenuStatus(index);
  }

  getOrgMenuStatus(index: number) {
    this.syncObj[index].status = 'processing';
    let completed = true;
    this.syncObj[index]['subMenu'].map((menu: any) => {
      if (menu.status == 'failed') this.syncObj[index].status = 'failed';
      if (menu.status != 'completed') completed = false;
    })
    if (completed) this.syncObj[index].status = 'completed';
    return this.syncObj[index].status;
  }

  getOrgMenuStatusCount(index: number, key: string) {
    let count = 0;
    if (this.syncObj[index]['subMenu'].length > 0) {
      this.syncObj[index]['subMenu'].map((menu: any) => {
        if (menu.status == key) count++
      });
    } else {
      count = this.getOrgMenuStatus(index) == key ? count++ : count;
    }
    return count;
  }




}//View OrgDetails Methods Ends here @End done by Deepak
