import { Component } from '@angular/core';
import {
    ElementRef,
    AfterViewInit,
    Output, EventEmitter
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

import { globalService } from './../../../../services/global.service';
import { ApiService } from './../../../../services/ApiService';
import { chosenUtility } from './../../../../services/chosen-utility/jq-chosen-utility.js';
import { GlobalUtils } from './../../../../services/global-utils';

const swal = require('sweetalert');

@Component({
    //template: ``,
    templateUrl: 'add-new-vehicle.html',
    selector: 'add-new-vehicle',

    styles: [`
        .row{
            margin-left:0px;
            margin-right:0px
        }`]

})
export class AddNewVehicleManagement {

    public formError: any = {};
    public showForm: boolean = false;
    public trackerInfo: any = [];
    private currentTrackerId: string = '';
    private errorMesages: any = [];
    public clickMessage: any;
    public simCard1_provider: any;
    public simCard2_provider: any;


    constructor(
        //public router: Router,

        public globalService: globalService,
        public apiService: ApiService,
        private router: Router,
        private globalUtils: GlobalUtils
    ) {


        this.trackerInfo.vehicleInformation = [];


    }

    @Output() hideTrackerVehicleFormComponent: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {

    }

    hideForm() {
        this.hideTrackerVehicleFormComponent.emit(false);
    }

    /* funtion used to refine the date for general forms */
    dateRefine(arg: any) {
        if (arg != undefined) {
            let date = arg.date;
            return date;
        }
    }

    MobileNumberValidator(event: any) {
        event.srcElement.value = event.srcElement.value.replace(/[^\d].+/, "");

        if (event.srcElement.value != '') {
            //  console.log('t',event.srcElement.value);
            if ((event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        } else {
            // event.srcElement.value=0;
        }
    }




    //save tracker validator

    saveTrackerValidator() {

        let errorFlag = false; this.errorMesages = [];

        //tracker Information

        //set tracker




        //set Vehiclename
        this.formError.organizationTrackerVehiclename = false;
        if (this.trackerInfo.vehicleInformation.name == "" || this.trackerInfo.vehicleInformation.name == undefined) {
            this.formError.organizationTrackerVehiclename = true;
            errorFlag = true;
            (this.errorMesages).push("Vehiclename should not be empty");
        }

        //set Vehiclename
        this.formError.organizationTrackerType = false;
        if (this.trackerInfo.vehicleInformation.type == "" || this.trackerInfo.vehicleInformation.type == undefined) {
            this.formError.organizationTrackerType = true;
            errorFlag = true;
            (this.errorMesages).push("Type should not be empty");
        }


        //set Vehicle Registration Number
        this.formError.organizationTrackerRegistrationnumber = false;
        if (this.trackerInfo.vehicleInformation.regno == "" || this.trackerInfo.vehicleInformation.regno == undefined) {
            this.formError.organizationTrackerRegistrationnumber = true;
            errorFlag = true; (this.errorMesages).push("Registration number should not be empty");
        }

        if (errorFlag == true) { return false; } else { return true; }


    }


    /*tracker form save */

    trackerFormSave() {
        let parent: any = this;
        if (this.saveTrackerValidator() == true) {


            this.apiService.createVehicleTrack({
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {

                        vehicleInformation: {
                            name: this.trackerInfo.vehicleInformation.name,
                            type: this.trackerInfo.vehicleInformation.type,
                            make: this.trackerInfo.vehicleInformation.make,
                            regno: this.trackerInfo.vehicleInformation.regno,
                            ownerName: this.trackerInfo.vehicleInformation.ownerName,
                            ownerPhone: this.trackerInfo.vehicleInformation.ownerPhone,
                            ownerAddress: this.trackerInfo.vehicleInformation.ownerAddress,
                            model: this.trackerInfo.vehicleInformation.model,
                            manufactureYear: this.trackerInfo.vehicleInformation.manufactureYear,
                            purchasedYear: this.trackerInfo.vehicleInformation.purchasedYear,
                            color: this.trackerInfo.vehicleInformation.color,
                            fuel: this.trackerInfo.vehicleInformation.fuel,
                            engineNumber: this.trackerInfo.vehicleInformation.engineNumber,
                            chasisNumber: this.trackerInfo.vehicleInformation.chasisNumber,
                            insuranceCompany: this.trackerInfo.vehicleInformation.insuranceCompany,
                            insurancePolicyNumber: this.trackerInfo.vehicleInformation.insurancePolicyNumber,
                            // insuranceExpiryDate:this.trackerInfo.vehicleInformation.insuranceExpiryDate,
                            driverName: this.trackerInfo.vehicleInformation.driverName,
                            seatCapacity: this.trackerInfo.vehicleInformation.seatCapacity,

                            driverPhone: this.trackerInfo.vehicleInformation.driverPhone,
                            driverAddress: this.trackerInfo.vehicleInformation.driverAddress
                        },


                    }
                }

            }).then(response => {
                let result = response;
                if (result.status == "success") {
                    swal("Success", "Tracker Added Successfully", "success");
                    setTimeout(() => {
                        swal.close();
                    }, 2000);
                    this.hideForm();
                } else {
                    swal("Failure", this.globalUtils.getErrorMsg(response.ec), "error");
                }
            })
                .catch(error => {
                    console.log('Error while saving the data', error);
                });
        }
    }

    /*tracker form save ends here */





}