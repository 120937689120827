import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/ApiService';
import { StorageService } from "./../../../services/storage.service";
import { Observable } from 'rxjs';
import { KDTreeService } from "./../../../services/kdtree.service";
import { keyframes } from '@angular/animations';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { bootstrapDateTime } from './../../../services/bootstrapDateTime/datePicker';
import { Router } from '@angular/router';


declare const Highcharts: any;


@Component({
    providers: [bootstrapDateTime],
    selector: 'route-analytics-week-data',
    templateUrl: './route-analytics-week-data.html',
    styles: [`
.highcharts-data-label-connector {
    display:none;
  }

.date-picker-style{
    border: 1px solid #969696;
    background: white;
    border-radius: 5px;}

    .mt-4{
        margin-top:40px;
    }
    `]
})
export class routeAnalyticsWeekDataComponent implements OnInit {

    public formControl: any;
    public Role: any = {};
    public roleControl: any;
    public orgReportStatus: any;
    public formError: any = {};
    dateTS: string | number;
    duration: string;
    allRouteInfo: any;
    // routeDetails: any;
    sessionTypeList: string = "";
    morningRoute: any;
    noonRoute: any;
    eveningRoute: any;
    allSessionRoute: any
    routeInfosession: any;
    routePickup_points: any;
    routesDetails: any;
    routeID: any;
    pickuppoints: any;
    date: string;
    fromTime: string;
    toTime: string;
    chartData: any;
    earlier: any;
    delay: any;
    noTimestamp: any = [];
    ontime: any;
    todayDate: Date;
    routeName: any = [];
    routeData: any = {}
    pickuppointDetails: any;
    vehId: any;
    routeId: any;
    trackData: any;
    filterForm: any;
    com: any;
    route2: any;
    date_ts: any;
    totalNoTime: any;
    noTimestampPerc: any;
    earlierPerc: any;
    delayPerc: any;
    ontimePerc: any;
    startDataFrom: void;
    endDateTo: any;
    todaydate: string


    constructor(public storage: StorageService,
        private apiService: ApiService,
        public storageservice: StorageService,
        private KDTree: KDTreeService,
        private formBuilder: FormBuilder,
        private bootstrapDateTime: bootstrapDateTime,
        private router: Router,

    ) {
    }


    public fromDate: any;
    public toDate: any;
    options = ['', 'Morning', 'Afternoon', 'Evening'];
    public selectedOption: any;
    public maxDate: any;
    public maxDate1: any;
    public isButtonDisabled: any = false;
    public countdown: any = 60;
    public intervalId: any;
    public title = 'School';
    public routes: any = {};
    public today = new Date();
    public hasError: any = [];
    public callCount = 0;
    totalRoute: any;
    currentRoute: any;
    processRoute: any;
    remainingRoute: any;
    dates: any;
    result: any = [];
    isNextProcess = false;
    async ngOnInit(): Promise<void> {

        this.selectedOption = this.options[0];
        this.callCount++
        // this.fromDate = this.formatDate(this.today);
        // this.toDate = this.formatDate(this.today);
        // this.maxDate = this.formatDate(this.today);
        // this.maxDate1 = this.formatDate(this.today);
        await this.startProcess().catch((error: any) => { throw error });
        setTimeout(() => {

            this.callBarchart({
                'Reached Early': this.earlier,
                'Reached on time': this.ontime,
                'Timestamps N/A': this.noTimestamp,
                'Reached late': this.delay
            })
        })
        const today = new Date().toISOString().split('T')[0];
        this.todaydate = today;


    }




    async dateChange(e: any) {


        let startDate = this.bootstrapDateTime.getInputvalue('startDate') ? this.bootstrapDateTime.getInputvalue('startDate').trim() : '';
        let endDate = this.bootstrapDateTime.getInputvalue('endDate') ? this.bootstrapDateTime.getInputvalue('endDate').trim() : '';
        this.startDataFrom = startDate
        this.endDateTo = endDate
        console.log(this.endDateTo)
        console.log(this.startDataFrom)
        await this.startProcess().catch((error: any) => { throw error });
        this.callBarchart({
            'Reached Early': this.earlier,
            'Reached on time': this.ontime,
            'Timestamps N/A': this.noTimestamp,
            'Reached late': this.delay

        })
    }
    async sessionChange(e: any) {
        let sessionWiseRoute = e.target.value;
        this.selectedOption = sessionWiseRoute;
        await this.startProcess().catch((error: any) => { throw error });
        setTimeout(() => {
            this.callBarchart({
                'Reached Early': this.earlier,
                'Reached on time': this.ontime,
                'Timestamps N/A': this.noTimestamp,
                'Reached late': this.delay
            })
        })
    }
    navigate(url: string) {
        this.router.navigate(['/' + url, {}]);
    }



    async assignDate() {
        // this.isNextProcess = true;
        this.hasError = [];
        this.totalRoute = 0;
        this.currentRoute = 0;
        this.processRoute = 0;
        this.remainingRoute = 0;
        this.dates = 0;
        this.routes = {};
        if (this.callCount <= 5) {
            this.callCount++
            let startDate = new Date(this.fromDate).getTime();
            let endDate = new Date(this.toDate).getTime();
            let timeDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
            if (startDate > endDate) this.hasError.push("Invalid date format.");
            else if (timeDiff > 5) this.hasError.push("A maximum of five days is allowed.");
            else await this.startProcess().catch((error: any) => { throw error });
        } else {
            this.isButtonDisabled = true;
            this.startCountdown();
        }
    }
    startCountdown(): void {
        this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
            } else {
                this.isButtonDisabled = false;
                clearInterval(this.intervalId);
            }
        }, 1000);
    }
    async startProcess() {
        this.routes = {};
        let sumnoTimestamp = 0;
        let sumEralier = 0;
        let sumOntime = 0;
        let sumDelay = 0;
        let sumnoTimestampPerc = 0;

        let chartDataStatus: any = {}
        let statusObj: any = {};
        let getLastWorkingDays = this.getLastWorkingDays();
        this.dates = getLastWorkingDays;
        console.log(this.dates)
        let routeDetails = await this.routeDetails().catch((error: any) => { throw error });
        this.totalRoute = routeDetails.length;
        //Loop route Data...
        let routeCound = 1;
        for (let routeDetail of routeDetails) {
            // if (this.isNextProcess) {
            //   this.isNextProcess = false;
            //   break;
            // }
            this.currentRoute = routeDetail['routeName'];
            this.processRoute = routeCound;
            this.remainingRoute = this.totalRoute - routeCound;
            let walker = (routeDetail["tags"].join("")).toLowerCase();
            let tagType = this.selectedOption.toLowerCase();
            // if (!walker.includes("walker")) {
            if (walker.includes(tagType) && !walker.includes("walker")) {
                let fromTime = routeDetail["routeStartTime"] + ":00";
                let toTime = routeDetail["routeEndTime"] + ":00";
                let routeID: string = routeDetail['routeId'];
                //Get pickup point Data...
                let pickupPointDetails = await this.pickupPointDetails(routeID).catch((error: any) => { throw error });
                if ("pickuppoints" in pickupPointDetails) {
                    let pickuppoints = pickupPointDetails["pickuppoints"];
                    let vehId: any = [routeDetail['vehId']];
                    for (let workingDay of getLastWorkingDays) {
                        //Get day tracker Data...
                        let dayTrackerLog = await this.dayTrackerLog(vehId, workingDay, fromTime, toTime).catch((error: any) => { throw error });
                        //Analytics chart data Data...
                        let status = await this.analyticsChartData(pickuppoints, dayTrackerLog).catch((error: any) => { throw error });
                        //Object format
                        if (statusObj[workingDay] == undefined) statusObj[workingDay] = [];
                        let tempObj = {
                            serialNo: routeCound,
                            routeName: routeDetail["routeName"],
                            status: status
                        }
                        tempObj["status"]["earlierPerc"] = ((status["earlier"] / status["pickuppoints"].length) * 100).toFixed(0);
                        tempObj["status"]["ontimePerc"] = ((status["ontime"] / status["pickuppoints"].length) * 100).toFixed(0);
                        tempObj["status"]["delayPerc"] = ((status["delay"] / status["pickuppoints"].length) * 100).toFixed(0);
                        tempObj["status"]["noTimestampPerc"] = ((status["noTimestamp"] / status["pickuppoints"].length) * 100).toFixed(0);
                        // let percentage = (50 / 100) * 100;

                        statusObj[workingDay].push(tempObj)
                    }
                    //await this.sleep(1000);
                }
                this.routes = statusObj;
            }
            routeCound++
        }
        this.currentRoute = "Process completed."
        for (let chartData of this.routes[this.dates]) {
            if ("status" in chartData) {
                let chartDataStatus = chartData.status
                let dataStatus = chartDataStatus.noTimestamp
                sumnoTimestamp += chartDataStatus['noTimestamp'];
                sumEralier += chartDataStatus['earlier'];
                sumDelay += chartDataStatus['delay'];
                sumOntime += chartDataStatus['ontime'];
                this.noTimestamp = sumnoTimestamp
                this.earlier = sumEralier
                this.delay = sumDelay
                this.ontime = sumOntime
                console.log(chartDataStatus)
                sumnoTimestampPerc += chartDataStatus['noTimestampPerc'];

                this.noTimestampPerc = chartDataStatus['noTimestampPer']
                this.earlierPerc = chartDataStatus['earlierPerc']
                this.delayPerc = chartDataStatus['delayPerc']
                this.ontimePerc = chartDataStatus['ontimePerc']
                console.log(sumnoTimestamp)

            }

        }


    }
    getKeys(obj: any): string[] {
        return Object.keys(obj);
    }
    // formatDate(date: Date): string {
    //     const year = date.getFullYear();
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const day = date.getDate().toString().padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // }


    getLastWorkingDays() {
        try {
            const workingDays: any = [];
            let sDate = new Date(this.fromDate);
            let eDate = new Date(this.toDate);
            while (sDate <= eDate) {
                const dayOfWeek = sDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                    let splitDate = new Date(sDate).toISOString().split('T')[0];
                    workingDays.push(splitDate);
                }
                sDate.setDate(sDate.getDate() + 1);
            }

            console.log(this.fromDate)
            return workingDays;


        } catch (error) {
            throw error;
        }
    };
    async routeDetails() {
        let returnData: any = [];
        let pageJump: any = 0;

        while (pageJump != null) {
            let routeQuery = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    extra: {
                        pageJump: pageJump
                    },
                    filter: { tags: this.selectedOption },
                    projections: ["routeId", "routeName", "routeStartTime", "routeEndTime", "routeType", "tags", "vehId"]
                }
            }
            //Replace your code structure with a "routeView()" function name.
            let result: any = await this.apiService.getRoutes(routeQuery).catch((error: any) => { throw error })
            if (result["status"] == "success") {
                if (result["response"] != null) {
                    returnData = [...returnData, ...result["response"]];
                    pageJump++
                } else {
                    pageJump = null
                }
                console.log(this.selectedOption)
            }
            else pageJump = null;
        }
        return returnData;
    }

    async pickupPointDetails(routeID: string) {
        let routeQuery = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {
                    routeId: routeID
                }
            }
        }
        //Replace your code structure with a "veiwPickupPoint()" function name.
        let result: any = await this.apiService.getRouteStoppages(routeQuery).catch((error: any) => { throw error })
        if (result["status"] == "success") return result["response"];
        else return [];
    }

    async dayTrackerLog(vehId: string, date: string, fromTime: string, toTime: string) {
        let returnData: any = [];
        let pageJump: any = 0;


        while (pageJump != null) {
            let trackerQuery = {
                data: {
                    key: localStorage.getItem('scbToken'),
                    form: {
                        vehId: vehId,
                        fromDate: this.startDataFrom,
                        fromTime: fromTime,
                        toDate: this.endDateTo,
                        toTime: toTime,
                        speed: { halt: { value: 0 } }
                    },
                    extra: {
                        pageJump: pageJump
                    }
                }
            };
            //Replace your code structure with a "viewTrackingData()" function name.
            let result: any = await this.apiService.getCommonReport(trackerQuery).catch((error: any) => { throw error })

            if (result["status"] == "success") {
                if (result["response"] != null) {
                    returnData = [...returnData, ...result["response"]];
                    pageJump++
                } else {
                    pageJump = null
                }
            }
            else pageJump = null;
        }
        return returnData;
    }

    async analyticsChartData(pickuppoints: any, dayTrackerLog: any) {
        let returnData: any = {
            pickuppoints: [],
            earlier: 0,
            ontime: 0,
            delay: 0,
            noTimestamp: 0
        };
        for (let pickuppoint of pickuppoints) {
            returnData["pickuppoints"].push(pickuppoint["pickuppoint"])
            let pickuppointLocation = pickuppoint["pickuppointLocation"];
            let expectedTime = pickuppoint["expectedTime"] + ":00";
            let nearestLocationData = await this.getNearestLocation(pickuppointLocation, dayTrackerLog, expectedTime).catch((error: any) => { throw error });
            if ("reachedTime" in nearestLocationData) {
                let status = this.getTimeStatus(expectedTime, nearestLocationData["reachedTime"])
                returnData[status] += 1;
            } else {
                returnData["noTimestamp"] += 1;
            }
        }
        return returnData;
    }

    async getNearestLocation(pickuppointLocation: any, dayTrackerLogs: any, expectedTime: any) {
        let data = [];
        for (let dayTrackerLog of dayTrackerLogs) {
            let location = dayTrackerLog["location"];
            if ((location['lat'] != null && !Number.isNaN(location['lat'])) && (location['lng'] != null && !Number.isNaN(location['lng']))) {
                let distance = this.calcCrow(pickuppointLocation['lat'], pickuppointLocation['lng'], location['lat'], location['lng']);
                if (distance <= 55) {
                    if (dayTrackerLog['gpsTimeStamp'] && dayTrackerLog['speed'] <= 5) {
                        let millisecond: any = new Date(dayTrackerLog['st']);
                        let reachedTime = this.pad(millisecond.getHours()) + ':' + this.pad(millisecond.getMinutes()) + ':' + this.pad(millisecond.getSeconds());
                        let difference = this.getTimeDuration(expectedTime, reachedTime);
                        if (60 >= difference) {
                            data.push({ reachedTime: reachedTime, difference: difference, ts: dayTrackerLog['st'] });
                        }
                    }
                }
            }
        }
        let returnData = {};
        if (data.length > 0) {
            data = data.sort((a, b) => {
                if (a.difference === b.difference) {
                    return a.ts - b.ts;
                }
                return a.difference - b.difference;
            });
            returnData = data[0];
        }
        return returnData;
    }

    pad(num: number) {
        return num > 9 ? num : '0' + num;
    }

    toRad(Value: any) {
        return Value * Math.PI / 180;
    }
    //This function alrady exists with kdtree.service file.
    calcCrow(lat1: any, lon1: any, lat2: any, lon2: any) {
        let R = 6371e3;  // meter
        let dLat = this.toRad(lat2 - lat1);
        let dLon = this.toRad(lon2 - lon1);
        var lat1: any = this.toRad(lat1);
        var lat2: any = this.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    getTimeDuration(EtaTime: any, GpsTime: any) {
        let today = new Date();
        let startTime: any = new Date(today.toDateString() + " " + EtaTime);
        let endTime: any = new Date(today.toDateString() + " " + GpsTime);
        let minutesDifference = Math.floor((endTime - startTime) / (1000 * 60));
        return Math.abs(minutesDifference);
    }

    getTimeStatus(EtaTime: any, GpsTime: any) {
        let splitEtaTime = EtaTime.split(":");
        let splitGpsTime = GpsTime.split(":");
        let genEtaTime = new Date();
        let genGpsTime = new Date();
        genEtaTime.setHours(splitEtaTime[0], splitEtaTime[1], 0, 0);
        genGpsTime.setHours(splitGpsTime[0], splitGpsTime[1], 0, 0);
        let retStatus = '';
        if (genEtaTime.getTime() === genGpsTime.getTime()) {
            retStatus = 'ontime';
        } else if (genEtaTime.getTime() < genGpsTime.getTime()) {
            retStatus = 'delay';
        } else if (genEtaTime.getTime() > genGpsTime.getTime()) {
            retStatus = 'earlier';
        }
        return retStatus;
    }

    sleep(num: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, Number(num));
        });
    }








    callBarchart(chartData = {
        'Reached Early': this.earlier,
        'Reached on time': this.ontime,
        'Timestamps N/A': this.noTimestamp,
        'Reached late': this.delay
    }) {
        Highcharts.chart('barChart', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Weekly Report'
            },

            xAxis: {
                categories: [
                    '15.07.2024',
                    '16.07.2024',
                    '17.07.2024',
                    '18.07.2024',
                    '19.07.2024'
                ],
                crosshair: true
            },
            yAxis: {
                min: 0,

            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                data: [
                    {
                        name: "Reached Early -" + [this.earlier],
                        y: chartData["Reached Early"], //this.routeDetails.length,
                        color: "#6CE2EB",
                        legendDesc: "1 Shipment"
                    },
                    {
                        name: "Reached on time -" + [this.ontime],
                        y: chartData["Reached on time"],
                        color: "#41B8D5",
                        legendDesc: "2 Shipments"
                    },
                    {
                        name: "Timestamps N/A -" + [this.noTimestamp],
                        y: chartData["Timestamps N/A"],
                        color: "#2F5F98",
                        legendDesc: "3-5 Shipments"
                    },
                    {
                        name: "Reached late -" + [this.noTimestamp],
                        y: chartData["Reached late"],
                        color: "#2D8BBA",
                        legendDesc: ">5 Shipments"
                    }
                ],
            }],
            exporting: {
                enabled: false
            }
        });


    }


}


