/**
 * @Date:   2018-04-24T11:51:53+05:30
 * @Project: School-Bus
 * @Filename: map.dir.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-04-25T13:45:22+05:30
 * @Copyright: Group10 Technologies
 */

import { Observable, Subscription } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from './../../../store/index';
import { TrackerData_Load } from './../../../actions/tracker-data';
import { VehicleListLoad, VehicleListUpdate } from './../../../actions/vehicle-data';

import { MessageService } from './../../../services/message.service';


import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, Renderer, HostListener } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { globalService } from './../../../services/global.service';
import { StorageService } from './../../../services/storage.service';
import { ApiService } from './../../../services/ApiService';
import { animateMarker } from './../../../services/markerAnimate';
import './markerClusterer.js';
import { map } from 'rxjs/operators';

// import { } from '@types/googlemaps';
/// <reference types="googlemaps" />

declare var io: any;
declare var MarkerClusterer: any;
declare var MarkerWithLabel: any;
declare var google: any;
@Component({
  selector: 'map-panel',
  template: `
      <div class="row map map-div-align" id="mapContainer"></div>
      <div class="halt-log"></div>
  `,
  styles: [`
    .map-div-align {
    }
    .map{
      background-color:lightgrey;
    }

`  ],
})
export class MapDir {

  @Input() mapRefreshBeat: number;

  @Input('mapHeight')
  set changeHeight(mapH: number) { // console.log('-=-=-',mapH);
    document.getElementById('mapContainer').style.height = mapH + 'vh';
    this.mapRefresh();
  }


  @Input('clickedVehicleId')
  set vehicleIdWithMapPan(vehId: any) {

    this.focusVehicle = vehId;
    this.getVehCurrentLocation(vehId);
  }

  @Input('mapSocket')
  set mapSocketFunc(e: any) {
    // this.mapUpdatePosition(e);
  }



  @Output() emitNewNotif: EventEmitter<any> = new EventEmitter<any>();


  // public __mapHeight: string;
  private allDevices: any = [];
  private orgDevices: any = []; // devices containing current location.
  private globalMapObj: any;
  public schoolLat: any;
  public schoollngg: any;
  private mapBounds: any;
  private deviceMarkers: any = [];
  private deviceMarkerClusters: any;
  private globalSocketObj: any;
  private socketConnectionPermission: boolean;
  private focusVehicle: string;
  public clearBeat: any;
  private busIcon: any = {
    url: './../../../webroot/images/pin-icons/map-markers/testImage1.png',
  };


  subscription: Subscription;

  public markerInfoContent: any = []; public markerVehInfo: any = []; public markerVehLatLng: any = []; public focus_info_windows: any = [];

  geo = {
    /**
     * Calculate the bearing between two positions as a value from 0-360
     *
     * @param lat1 - The latitude of the first position
     * @param lng1 - The lnggitude of the first position
     * @param lat2 - The latitude of the second position
     * @param lng2 - The lnggitude of the second position
     *
     * @return int - The bearing between 0 and 360
     */
    bearing: function (lat1: any, lng1: any, lat2: any, lng2: any) {
      const dlng = this._toRad(lng2 - lng1);
      const y = Math.sin(dlng) * Math.cos(this._toRad(lat2));
      const x = Math.cos(this._toRad(lat1)) * Math.sin(this._toRad(lat2)) - Math.sin(this._toRad(lat1)) * Math.cos(this._toRad(lat2)) * Math.cos(dlng);
      const brng = this._toDeg(Math.atan2(y, x));
      return ((brng + 360) % 360);
    },

    /**
      * Since not all browsers implement this we have our own utility that will
      * convert from degrees into radians
      *
      * @param deg - The degrees to be converted into radians
      * @return radians
      */
    _toRad: function (deg: any) {
      return deg * Math.PI / 180;
    },

    /**
     * Since not all browsers implement this we have our own utility that will
     * convert from radians into degrees
     *
     * @param rad - The radians to be converted into degrees
     * @return degrees
     */
    _toDeg: function (rad: any) {
      return rad * 180 / Math.PI;
    },
  };

  //  @select('trackerData') public trackerData$: Observable<any>;
  @select('vehicleData') public vehicleData$: Observable<any>;

  markers: any = {};
  markers_info: any = {};

  constructor(public apiService: ApiService,
    public storage: globalService,
    public animateMarker: animateMarker,
    private cdr: ChangeDetectorRef,
    private ngRedux: NgRedux<AppState>,
    public messageService: MessageService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer,
    public storageSrv: StorageService
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      // console.log('#-#',  message.text);
      const device = message.text;
      device['deviceId'] = this.storage.data.vehicleIds[device.imei];
      // console.log('#-#', device );
      if (device['deviceId'] !== undefined) {
        this.mapUpdatePosition({ data: device });
      }
    });

    router.events.subscribe((val: any) => {
      // console.log(val.url);
      if (val.url !== '/client-dashboard') {
        // clearTimeout(this.clearBeat);
        this.clearMapBeat();
      }
    } /*whatever*/);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // setTimeout( () => {
    //  this.mapBeat();
    // }, 5000 );
    // this.haltrepofunc();
    this.getSchoolLatlngg();

    this.getSchoolLatlngg();


    // Important
    window.onfocus = (ev) => {
      // console.log("gained focus");
      this.mapBeat();
    };

    window.onblur = (ev) => {
      // console.log("lost focus");
      this.clearMapBeat();
    };


  }
  getSchoolLatlngg() {

    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken'),
        projections: ["orgLocation"],
      }
    };

    this.apiService.getCurrentOrgDetails(apiHeader).then(response => {

      this.schoolLat = response.response.orgLocation.lattitude;
      this.schoollngg = response.response.orgLocation.lnggitude;
      // this.allDevices = response.response;
      // this.getCurrentLocation();
    }).catch(error => { });
  }
  clearMapBeat() {
    clearTimeout(this.clearBeat);
  }

  private lastMouseEventTime: number = Date.now();

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.lastMouseEventTime = Date.now();
  }

  checkMouseEvent() {
    const currentTime = Date.now();
    const timeDifference = currentTime - this.lastMouseEventTime;
    if (timeDifference > 60000) { // 60000 milliseconds = 1 minute
      this.mapBeat();
      console.log('No mouse event for more than a minute');
      // Perform any action you want here
    }
  }


  mapBeat() {
    // clearTimeout(this.clearBeat);
    this.clearMapBeat();
    // console.log("map beat")
    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken')
      }
    };

    const getCurrentLocParam = apiHeader;

    this.apiService.getCurrentLocation(getCurrentLocParam).then(response => {
      (response.response).forEach((itm: any) => {
        const device = itm;
        device['deviceId'] = this.storage.data.vehicleIds[device.imei];
        // console.log('#-#', device );
        if (device['deviceId'] !== undefined && device['deviceId'] !== 'undefined') {


          this.storage.data.vehicleIds[itm['imei']] = itm['deviceId'];
          itm['VehicleNo'] = this.storage.data['vehicleLists'][itm['imei']];

          this.mapUpdatePosition({ data: device });
          try {
            if (itm['routeWithTime']) {
              let ascTiming: any = Object.keys(itm['routeWithTime']).map((time: any) => itm['routeWithTime'][time]);
              if (ascTiming.length > 0) {
                ascTiming = ascTiming.sort((a: any, b: any) => parseFloat(a.replace(':', '.')) - parseFloat(b.replace(':', '.')));
                itm['routeAssigned'] = [];
                ascTiming.map((item: any) => {
                  Object.keys(itm['routeWithTime']).map((name: any) => {
                    if (itm['routeWithTime'][name] == item)
                      itm['routeAssigned'].push(name)
                  });
                })
              }
            }
          } catch (err) {
            console.log(err);
          }

          setTimeout(() => {
            this.ngRedux.dispatch({
              type: VehicleListUpdate,
              payload: itm
            });
          }, 100);

        }
      });

    }).catch((err) => {
      // console.log('error');
    });

    this.clearBeat = setTimeout(() => { this.mapBeat(); }, 10 * 1000);

  }

  ngAfterViewInit() {
    this.initMap();
    this.getAllDevices();
    setTimeout(() => { this.mapBeat(); }, 50000);
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();

  }

  mapRefresh() {
    google.maps.event.trigger(this.globalMapObj, 'resize');
  }

  @HostListener('click') onMouseOver() {
    // let part = this.el.nativeElement.querySelector('.card-text');
    // this.renderer.setElementStyle(part, 'display', 'block');
    this.haltrepofunc();
  }


















  async initMap_old() {
    const mapOptions = {
      scrollwheel: true,
      zoom: 12,
      maxZoom: 17,
      center: new google.maps.LatLng(28.7041, 77.1025),
      disableDefaultUI: false,
      mapId: this.focusVehicle['vehId'] || 123,

    };

    this.globalMapObj = new google.maps.Map(document.getElementById('mapContainer'), mapOptions);

    this.globalMapObj.fitBounds = () => { return this.mapBounds };

    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.globalMapObj);


    this.globalMapObj.addListener('center_changed', () => {
      if (this.focusVehicle !== undefined) {
        const look_device_itm: any = (this.ngRedux.getState().vehicleData).filter((item: any) => {
          return item.deviceId === this.focusVehicle['vehId'];
        });

        const mLatlng_upd = new google.maps.LatLng(look_device_itm[0].location.lat, look_device_itm[0].location.lng);
        const date_month = new Date(look_device_itm[0].gpsTimestamp).toLocaleDateString('en-US');
        const date_day = new Date(look_device_itm[0].gpsTimestamp).toLocaleTimeString('en-US');
        const deviceLastUpdated = date_month + ' ' + date_day;

        const html = `<b>${look_device_itm[0].VehicleNo}</b><br/>
                    Updated on: ${deviceLastUpdated}<br/>
                    Speed: ${look_device_itm[0].speed} Km/h <br>
                    <a class="pointer" id="halt_${this.focusVehicle['vehId']}">Halt Report</a>
                    &nbsp; <a class="pointer" id="movement_${this.focusVehicle['vehId']}">Movement Report</a>`;

        const markerContent = document.createElement("div");
        markerContent.innerHTML = this.getTransparentMarker();

        let marker_focus;
        try {
          marker_focus = new google.maps.marker.AdvancedMarkerElement({
            position: mLatlng_upd,
            zIndex: 99999,
            content: markerContent,
            map: this.globalMapObj
          });
        } catch (e) { }

        const infoWindow = new google.maps.InfoWindow({
          content: html
        });

        infoWindow.addListener('domready', () => {
          document.getElementById('halt_' + this.focusVehicle['vehId']).addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Halt Log',
              vehicleId: this.focusVehicle['vehId'],
              vehicleName: look_device_itm[0].VehicleNo,
            });
            window.location.href = '#/movementReport';
          });

          document.getElementById('movement_' + this.focusVehicle['vehId']).addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Movement Log',
              vehicleId: this.focusVehicle['vehId'],
              vehicleName: look_device_itm[0].VehicleNo,
            });
            window.location.href = '#/movementReport';
          });
        });

        google.maps.event.addListener(infoWindow, 'closeclick', () => {
          this.focusVehicle = undefined;
          if (this.focus_info_windows !== undefined) {
            this.focus_info_windows.forEach((windowObj: any) => {
              windowObj.close();
            });
          }
        });

        infoWindow.open(this.globalMapObj, marker_focus);
        this.focus_info_windows.push(infoWindow);
      }
    });
  }

  async initMap() {

    const { Map } = await google.maps.importLibrary("maps");

    const mapOptions = {
      scrollwheel: true,
      zoom: 12,
      maxZoom: 17,
      center: new google.maps.LatLng(28.7041, 77.1025),
      disableDefaultUI: false,
      mapId: 'school-map',
    };



    this.globalMapObj = new Map(document.getElementById('mapContainer'), mapOptions);
    setTimeout(() => {
      this.globalMapObj.setCenter(new google.maps.LatLng(this.schoolLat, this.schoollngg));
    }, 2 * 1000);

    if (google.maps && google.maps.TrafficLayer) {
      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(this.globalMapObj);
    } else {
      console.error('Google Maps TrafficLayer is not available. Ensure the Google Maps library is loaded correctly.');
    }


    this.globalMapObj.addListener('center_changed', () => {
      if (this.focusVehicle !== undefined) {
        const look_device_itm: any = (this.ngRedux.getState().vehicleData).filter((item: any) => {
          return item.deviceId === this.focusVehicle['vehId'];
        });

        const mLatlng_upd = new google.maps.LatLng(look_device_itm[0].location.lat, look_device_itm[0].location.lng);
        const date_month = new Date(look_device_itm[0].gpsTimestamp).toLocaleDateString('en-US');
        const date_day = new Date(look_device_itm[0].gpsTimestamp).toLocaleTimeString('en-US');
        const deviceLastUpdated = date_month + ' ' + date_day;

        const html = `<b>${look_device_itm[0].VehicleNo}</b><br/>
                    Updated on: ${deviceLastUpdated}<br/>
                    Speed: ${look_device_itm[0].speed} Km/h <br>
                    <a class="pointer" id="halt_${this.focusVehicle['vehId']}">Halt Report</a>
                    &nbsp; <a class="pointer" id="movement_${this.focusVehicle['vehId']}">Movement Report</a>`;

        const markerContent = document.createElement("div");
        markerContent.innerHTML = this.getTransparentMarker();

        let marker_focus;
        try {
          marker_focus = new google.maps.marker.AdvancedMarkerElement({
            position: mLatlng_upd,
            zIndex: 99999,
            content: markerContent,
            map: this.globalMapObj
          });
        } catch (e) { }

        const infoWindow = new google.maps.InfoWindow({
          content: html
        });

        infoWindow.addListener('domready', () => {
          document.getElementById('halt_' + this.focusVehicle['vehId']).addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Halt Log',
              vehicleId: this.focusVehicle['vehId'],
              vehicleName: look_device_itm[0].VehicleNo,
            });
            window.location.href = '#/movementReport';
          });

          document.getElementById('movement_' + this.focusVehicle['vehId']).addEventListener('click', () => {
            this.storageSrv.set({
              report: 'Movement Log',
              vehicleId: this.focusVehicle['vehId'],
              vehicleName: look_device_itm[0].VehicleNo,
            });
            window.location.href = '#/movementReport';
          });
        });

        google.maps.event.addListener(infoWindow, 'closeclick', () => {
          this.focusVehicle = undefined;
          if (this.focus_info_windows !== undefined) {
            this.focus_info_windows.forEach((windowObj: any) => {
              windowObj.close();
            });
          }
        });

        infoWindow.open(this.globalMapObj, marker_focus);
        this.focus_info_windows.push(infoWindow);
      }
    });




  }




  async renderDevices(paramDevices: any) {

    let parent = this;
    this.mapBounds = new google.maps.LatLngBounds();

    for (const device of paramDevices) {
      if (device.location) {
        const mLatlng = new google.maps.LatLng(device.location.lat, device.location.lng);
        let marker;

        if (device.currentRouteAssigned == '') {
          // marker = new google.maps.Marker({
          //   position: mLatlng,
          //   draggable: false,
          //   zIndex: google.maps.Marker.MAX_ZINDEX + 1,
          //   icon: this.getMarkerImgPath(device, 0),
          //   map: this.globalMapObj,
          //   index: device.deviceId,
          // });

          const location = { lat: device.location.lat, lng: device.location.lng }
          this.markerCreator(location, device, { title: device.VehicleNo });
        } else {
          let distCalc = this.distance(this.schoolLat, this.schoollngg, device.location.lat, device.location.lng);

          if (distCalc > 100000) {
            // marker = new MarkerWithLabel({
            //   position: mLatlng,
            //   draggable: false,
            //   zIndex: google.maps.Marker.MAX_ZINDEX + 1,
            //   icon: this.getMarkerImgPath(device, 0),
            //   map: this.globalMapObj,
            //   index: device.deviceId,
            //   labelContent: device.currentRouteAssigned,
            //   labelAnchor: new google.maps.Point(30, 30),
            //   labelClass: "map-marker-label",
            // });

            const location = { lat: device.location.lat, lng: device.location.lng }
            this.markerCreator(location, device, { title: device.VehicleNo });


          } else {

            // marker = new google.maps.Marker({
            //   position: mLatlng,
            //   draggable: false,
            //   zIndex: google.maps.Marker.MAX_ZINDEX + 1,
            //   icon: this.getMarkerImgPath(device, 0),
            //   map: this.globalMapObj,
            //   index: device.deviceId,
            // });

            const location = { lat: device.location.lat, lng: device.location.lng }
            this.markerCreator(location, device, { title: device.VehicleNo });

          }
        }

        this.mapBounds.extend(mLatlng);
        this.deviceMarkers.push(marker);

        const infoWindowContent = '<div class="window-content-container">' + device.location.lat + '</div>';
        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent
        });

        try {
          if (device.deviceId) {
            infoWindow.addListener('domready', () => {
              let haltDevEl = document.getElementById('halt_' + device.deviceId);
              if (haltDevEl) {
                haltDevEl.addEventListener('click', () => {
                  this.storageSrv.set({
                    report: 'Halt Log',
                    vehicleId: device.deviceId,
                    vehicleName: deviceDetails.VehicleNo,
                  });
                  window.location.href = '#/movementReport';
                });
              }
              let movementDevEl = document.getElementById('movement_' + device.deviceId);
              if (movementDevEl) {
                movementDevEl.addEventListener('click', () => {
                  this.storageSrv.set({
                    report: 'Movement Log',
                    vehicleId: device.deviceId,
                    vehicleName: deviceDetails.VehicleNo,
                  });
                  window.location.href = '#/movementReport';
                });
              }
              let movementAndHaltEl = document.getElementById('movementandhalt_' + device.deviceId);
              if (movementAndHaltEl) {
                movementAndHaltEl.addEventListener('click', () => {
                  this.storageSrv.set({
                    report: 'Movement & Halt log',
                    vehicleId: device.deviceId,
                    vehicleName: deviceDetails.VehicleNo,
                  });
                  window.location.href = '#/movementReport';
                });
              }
            });
          }
        } catch (e) { }

        let deviceDetails = this.allDevices.filter(function (index: any, value: any) { return index.deviceId === device.deviceId; });
        deviceDetails = deviceDetails[0];

        if (deviceDetails.VehicleNo !== undefined && deviceDetails.VehicleNo !== 'undefined') {
          const date_month_initial = new Date(device.gpsTimestamp).toLocaleDateString('en-US');
          const date_day_initial = new Date(device.gpsTimestamp).toLocaleTimeString('en-US');
          const deviceLastUpdated_initial = date_month_initial + ' ' + date_day_initial;

          const deviceLastUpdated = deviceLastUpdated_initial;

          const infoHTML = '<b>'
            + deviceDetails.VehicleNo + '</b><br/> Updated on: '
            + deviceLastUpdated + '<br/>Speed: '
            + device.speed + ' Km/h' + '<br>'
            + '<a class="pointer" id="halt_' + device.deviceId + '" >Halt Report</a>'
            + ' &nbsp; <a class="pointer" id="movement_' + device.deviceId + '" >Movement Report</a>'
            + ' &nbsp; <a class="pointer" id="movementandhalt_' + device.deviceId + '" >Movement and Halt Report</a>';

          this.markerInfoContent[deviceDetails.deviceId] = infoHTML;
          this.markerVehInfo[deviceDetails.deviceId] = infoHTML;
          this.markerVehLatLng[deviceDetails.deviceId] = new google.maps.LatLng(device.location.lat, device.location.lng);

          const parent = this;



          setTimeout(() => {
            // console.log(this.markers)
            this.markerListener(parent, infoWindow, deviceDetails);
          }, 20000)


        }
      }
    }
    // this.globalMapObj.fitBounds(this.mapBounds);

    const clusteringOptions = {
      styles: [{
        url: "assets/webroot/images/map/map_ic3/m1.png",
        width: 53,
        height: 53,
        textSize: 12,
        textColor: "white",
      }],
      minimumClusterSize: 3,
      maxZoom: 14
    };

    this.deviceMarkerClusters = new MarkerClusterer(
      this.globalMapObj, this.deviceMarkers,
      clusteringOptions
    );
  }



  markerListener(parent: any, infoWindow: any, deviceDetails: any) {

    if (this.markers[deviceDetails.deviceId]) {
      this.markers[deviceDetails.deviceId].addListener('mouseover', function () {
        (parent.focus_info_windows).forEach((windowObj: any) => {
          windowObj.close();
        });
        const desc = '<div href="#" data-id = "' + deviceDetails.deviceId + '" id="live-' + deviceDetails.deviceId + '">'
          + parent.markerInfoContent[deviceDetails.deviceId] + '</div>';
        infoWindow.setContent(desc);
        infoWindow.open(this.globalMapObj, this);
        parent.focus_info_windows.push(infoWindow);
      });
      this.markers[deviceDetails.deviceId].addEventListener('gmp-click', function () {
        (parent.focus_info_windows).forEach((windowObj: any) => {
          windowObj.close();
        });
        const desc = '<div href="#" data-id = "' + deviceDetails.deviceId + '" id="live-' + deviceDetails.deviceId + '">'
          + parent.markerInfoContent[deviceDetails.deviceId] + '</div>';
        infoWindow.setContent(desc);
        infoWindow.open(this.globalMapObj, this);
        parent.focus_info_windows.push(infoWindow);
      });
      this.markers[deviceDetails.deviceId].addListener('mouseout', function () {
        // infoWindow.close();
      });

    }
  }

  haversineDistance_meters(lat1: number, lng1: number, lat2: number, lng2: number) {
    const R = 6371e3; // Radius of earth in meters
    const radLat1 = (lat1 * Math.PI) / 180;
    const radlng1 = (lng1 * Math.PI) / 180;
    const radLat2 = (lat2 * Math.PI) / 180;
    const radlng2 = (lng2 * Math.PI) / 180;
    const dLat = radLat2 - radLat1;
    const dlng = radlng2 - radlng1;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  animateAdvancedMapMarker(deviceId: string = 'default', myLatLng: any = {}) {
    const numDeltas = 20;
    const delay = 100; //milliseconds
    const targetLat = myLatLng['lat'];
    const targetLng = myLatLng['lng'];
    const currentLat = this.markers[deviceId].position.lat;
    const currentLng = this.markers[deviceId].position.lng;

    const latDelta = (targetLat - currentLat) / numDeltas;
    const lngDelta = (targetLng - currentLng) / numDeltas;

    let i = 0;

    const moveMarker = () => {

      // if (this.haversineDistance_meters(currentLat, currentLng, targetLat, targetLng) > 1000) {
      //   this.markers[deviceId].position = {
      //     lat: targetLat,
      //     lng: targetLng
      //   }
      //   return;
      // }
      if (i < numDeltas) {
        i++;
        const newLat = currentLat + latDelta * i;
        const newLng = currentLng + lngDelta * i;
        // this.markers[deviceId].setPosition({ lat: newLat, lng: newLng });
        this.markers[deviceId].position = {
          lat: newLat,
          lng: newLng
        }
        setTimeout(moveMarker, delay);
      }
    }

    moveMarker();
  }

  debounce(callback: (...args: any[]) => void, wait: number) {
    let timeoutId: number | null = null;
    return (...args: any[]) => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = window.setTimeout(() => {
        callback(...args);
      }, wait);
    };
  }

  async markerCreator(location: any = undefined, device: any = undefined, options: any = { title: '' }) {
    const myLatLng = { lat: location.lat, lng: location.lng };
    // console.log(device)
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    if (this.markers[device.deviceId || 'default']) {

      // this.markers[device.deviceId || 'default'].position = myLatLng;
      this.markers[device.deviceId || 'default'].content = this.convertImagePathToIcon(device, options.bearing || 0, { titleInfo: device['currentRouteAssigned'] || undefined });//`<img src="${this.getMarkerImgPath({ speed: 20 }, 0).url}" />`;

      const marker_info = this.markers_info[device.deviceId || 'default'];
      // const ts_in_min = (Date.now() - marker_info['gps_ts']) / 60000;
      // const gps_ts = device['gpsTimestamp'];

      if (device['gpsTimestamp'] > marker_info['gps_ts']) {
        this.markers_info[device.deviceId || 'default']['ts'] = Date.now();

        this.animateAdvancedMapMarker(device.deviceId || 'default', myLatLng);

      }



    } else {
      // The marker, positioned
      this.markers[device.deviceId || 'default'] = new AdvancedMarkerElement({
        map: this.globalMapObj,
        position: myLatLng,
        title: options['title'] || '',
        gmpClickable: true,
        content: this.convertImagePathToIcon(device, options.bearing || 0, { titleInfo: device['currentRouteAssigned'] || undefined })//`<img src="${this.getMarkerImgPath({ speed: 20 }, 0).url}" />`,
      });
      this.markers_info[device.deviceId || 'default'] = {
        ts: Date.now(),
        gps_ts: device['gpsTimestamp']
      }

    }

  }

  convertImagePathToIcon(device: any = { speed: 0 }, bearing = 0, options: any = {}) {
    // {titleInfo:'test'}
    // A marker with a with a URL pointing to a PNG.
    const img = document.createElement('img');
    const pathGenerated: any = this.getMarkerImgPath(device, bearing);
    img.src = pathGenerated.url;//'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    const div_holder = document.createElement('div');
    if (options['titleInfo']) {
      const title_span = document.createElement('span');
      title_span.innerHTML = options['titleInfo'];
      title_span.style.color = 'white';
      title_span.style.backgroundColor = 'rgba(35, 35, 35,70%)'; // 90% black
      title_span.style.padding = '3px';
      title_span.style.borderRadius = '3px';
      title_span.style.position = 'absolute';
      title_span.style.top = '-10px';
      title_span.style.left = '5px';
      title_span.style.whiteSpace = 'nowrap';
      div_holder.appendChild(title_span);
    }
    div_holder.appendChild(img);
    return div_holder;
  }



















  getAllDevices() { // getting all devices for an org.
    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken')
      }
    };
    this.apiService.getOrgVehicles(apiHeader).then(response => {
      this.allDevices = response.response;
      this.getCurrentLocation();
    }).catch(error => { });

  }

  getCurrentLocation() { // Gettings current location of all active trackers in an org.


    const apiHeader = {
      'data': {
        'key': localStorage.getItem('scbToken')
      }
    };



    this.apiService.getOrgVehicles(apiHeader).then(response => {
      const APIresponse = response.response;

      this.ngRedux.dispatch({
        type: VehicleListLoad,
        payload: APIresponse
      });

    }).catch(error => { });

    this.apiService.getVehicleId(apiHeader).then(response => {
      this.storage.data.vehicleLists = response.response;
    }).catch(error => { });



    const getCurrentLocParam = apiHeader;
    // getCurrentLocParam.data['getLatestPosition'] = 1;
    this.storage.data.vehicleIds = [];
    this.apiService.getCurrentLocation(getCurrentLocParam).then(response => {
      (response.response).forEach((itm: any) => {

        this.storage.data.vehicleIds[itm['imei']] = itm['deviceId'];
        itm['VehicleNo'] = this.storage.data['vehicleLists'][itm['imei']];
        try {
          if (itm['routeWithTime']) {
            let ascTiming: any = Object.keys(itm['routeWithTime']).map((time: any) => itm['routeWithTime'][time]);
            if (ascTiming.length > 0) {
              ascTiming = ascTiming.sort((a: any, b: any) => parseFloat(a.replace(':', '.')) - parseFloat(b.replace(':', '.')));
              itm['routeAssigned'] = [];
              ascTiming.map((item: any) => {
                Object.keys(itm['routeWithTime']).map((name: any) => {
                  if (itm['routeWithTime'][name] == item)
                    itm['routeAssigned'].push(name)
                });
              })
            }
          }
        } catch (err) {
          console.log(err);
        }
        this.ngRedux.dispatch({
          type: VehicleListUpdate,
          payload: itm
        });
      });


      this.orgDevices = [];
      (this.vehicleData$).forEach((vehData: any) => {
        (vehData).forEach((device: any) => {
          this.orgDevices.push(device);
        });
      });
      this.renderDevices(this.orgDevices);

      // console.log( '^^^', this.storage.data );


    }).catch(error => { });



    // getCurrentLocation BEAT() dont do this here
    // setTimeout(() => { this.getCurrentLocation(); }, 10 * 1000);




  }








  haltrepofunc() {



    let haltReport = document.querySelector('.halt-log');

    haltReport.addEventListener('click', (e: any) => {

      // console.log('haltReport triggered', e.target.id)



    });

  }













  mapUpdatePosition(e: any) {
    // bottle neck
    const device: any = e['data'];
    if (device.vehNo !== '') {
      device.VehicleNo = device.vehNo;
    }
    if (device.VehicleNo !== undefined && device.VehicleNo !== 'undefined') {
      // console.log( '@@',device,"###",this.markerInfoContent[device.deviceId] ,"###");
      const deviceLastUpdated = device.gpsDate + ', ' + device.gpsTime;
      this.markerInfoContent[device.deviceId] = '<b>'
        + device.VehicleNo + '</b><br/> Updated on: '
        + deviceLastUpdated + '<br/>Speed: '
        + device.speed + ' Km/h <br/>'
        // + '<a class="halt-log" id="' + deviceDetails.VehicleNo + '">Halt Report</a>';
        + '<a class="pointer" id="halt_' + device.deviceId + '" >Halt Report</a>'
        + ' &nbsp; <a class="pointer" id="movement_' + device.deviceId + '" >Movement Report</a>';
      // console.log('######', deviceDetails);






      if (e && device && device.location) {
        const Imei = device.imei;

        const deviceDetails = device;

        try {

          let bearing = this.geo.bearing(
            this.markers[deviceDetails.deviceId].position.lat,
            this.markers[deviceDetails.deviceId].position.lng,
            device.location.lat, device.location.lng
          );
          bearing = Math.round(bearing);

          const location = { lat: device.location.lat, lng: device.location.lng }
          this.markerCreator(location, device, { title: device.VehicleNo, bearing: bearing });


          // console.log(location, { title: device.VehicleNo, bearing: bearing })

        } catch (error) {
          console.log(this.markers[deviceDetails.deviceId])
          console.log(error)
        }


        /*

        const marker = this.deviceMarkers.filter((el: any) => {
          // console.log(  el.index ,this.storage.data,   Imei  );
          return el.index === device.deviceId; // this.storage.data.vehicleIds[ Imei ];
        });
        if (marker[0]) { // if device exists then carry operations.

          const mlatLng = new google.maps.LatLng(device.location.lat, device.location.lng);

          this.markerVehLatLng[device.deviceId] = mlatLng;


          let bearing = this.geo.bearing(
            marker[0].getPosition().lat(),
            marker[0].getPosition().lng(),
            device.location.lat, device.location.lng
          );
          bearing = Math.round(bearing);
          // marker[0].setIcon(this.getMarkerImgPath(device, bearing));




          const deviceDetails = e.data;



          //   document.getElementById('live-' + deviceDetails.deviceId ).innerHTML = desc;

          this.animateMarker.animateTo(marker[0], mlatLng, { easing: 'linear', duration: 2000 });
        } else {
          // console.log('marker-unavailable');
        }

        */

      }


    } else { // ! undefined
      // console.log(device);
    }
  }










  getTransparentMarker() {
    return '<img src="assets/webroot/images/map/trans.png" />';
  }
  getMarkerImgPath(device: any, bearing = 0) {
    let overspeed = this.storageSrv.get('overspeed');
    let markerIcon = {};

    if (device.speed >= 0) {
      markerIcon = {
        url: 'assets/webroot/images/map/bus_red.png?ang=' + (bearing),
        rotation: bearing,
      };
    }

    if (device.speed >= 5) {
      markerIcon = {
        url: 'assets/webroot/images/map/marker-icon-green.png?ang=' + (bearing), // this.busUrlRotate( 50 ),
        //  origin: new google.maps.Point(0, 100), // origin
        rotation: bearing,
        // anchor: new google.maps.Point(0, 0) // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
      };
    }
    if (overspeed && device.speed >= overspeed) {
      markerIcon = {
        url: 'assets/webroot/images/map/marker-icon-blue.png?ang=' + (bearing),
        rotation: bearing,
      };
    }
    // console.log(markerIcon,overspeed);

    return markerIcon;
  }
  distance(lat1: any, lng1: any, lat2: any, lng2: any) {
    if ((lat1 == lat2) && (lng1 == lng2)) {
      return 0;
    }
    else {
      let R = 6378137;
      let dLat = this.degreesToRadians(lat2 - lat1);
      let dlngg = this.degreesToRadians(lng2 - lng1);
      let a = Math.sin(dLat / 2)
        *
        Math.sin(dLat / 2)
        +
        Math.cos(this.degreesToRadians(lat1))
        *
        Math.cos(this.degreesToRadians(lat1))
        *
        Math.sin(dlngg / 2)
        *
        Math.sin(dlngg / 2);

      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let distance = R * c;

      return distance * 0.001;
    }
  }
  degreesToRadians(degrees: any) {
    return degrees * Math.PI / 180;
  }



  getVehCurrentLocation(vehObj: any) {
    // console.log(vehObj);
    if (vehObj) {
      const desiredImei = this.orgDevices.filter(function (index: any) {
        // console.log('click',vehObj);
        return index.deviceId === vehObj.vehId;
      });
      if (desiredImei.length) {


        const look_deviceId = desiredImei[0]['deviceId']; //  this.storage.data.vehicleIds[ desiredImei[0]['imei'] ];


        const look_device_itm: any = (this.ngRedux.getState().vehicleData).filter((item: any) => {
          // console.log(item.deviceId ,'===', look_deviceId);
          return item.deviceId === look_deviceId;
        });

        // console.log(look_device_itm[0]);
        if (look_device_itm[0].location) {

          const mLatlngg = new google.maps.LatLng(look_device_itm[0].location.lat, look_device_itm[0].location.lng);
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(mLatlngg);
          this.globalMapObj.fitBounds(bounds);
          this.globalMapObj.panTo(mLatlngg);
        }

      }
    }
  }




}

/*
Notes : npm install node-sass -g
*/
