import { Component, Input, EventEmitter, Output } from "@angular/core";
import { ApiService } from "../../../services/ApiService";
import * as XLSX from 'xlsx';

@Component({
    selector: 'mem-wise-face-attendance',
    template: `
   
   <style>
   [data-tooltip]:before{
      left: 5% !important;
   }
   </style>



   <ng-container *ngIf="memwiseAttendance.length">
   
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row mb5">
                <div class="reportHeading text-center mb5">Memberwise Face Attendance Report</div>
            </div>
            <div class="row mb5">
                <div class="col-md-6 text-left">
                    <label class="control-label">Date : </label>
                    <span class="reportStartDate">{{selectedDate}}</span>
                </div>
                <!-- <div class="col-md-6 text-right">
                    <label class="control-label">EndDate : </label>
                    <span class="reportEndDate"> {{intermediateObj.reportEndDate}}</span>
                </div> -->
            </div>
            <div class="row mb5">
                <div class="col-md-6 text-left">
                    <label class="control-label">MemberName : </label>
                    <span class="reportStartDate"> {{selectedMember[0]['memberName']}}</span>
                </div>
                <div class="col-md-6 text-right">
                    <label class="control-label">MemberId : </label>
                    <span class="reportEndDate"> {{selectedMember[0]['memberUID']}}</span>
                </div>
            </div>
            <div class="row mb5">
                <div class="col-md-6 text-left">
                    <label class="control-label">MemberClass : </label>
                    <span class="reportStartDate"> {{selectedMember[0]['memberClass']}}</span>
                </div>
                <div class="col-md-6 text-right">
                    <label class="control-label">Member Section : </label>
                    <span class="reportEndDate"> {{selectedMember[0]['memberSection']}}</span>
                </div>
            </div>
            <div class="row mb5">     <span class="excel-template-downalod-ref pull-right meager-e2e-download-update-stu-info-details"
            (click)="memberwiseExcel()" style="cursor:pointer;"><a   class="meager-e2e-datewise-tab-attendance">
                    Download excel Report
                </a></span></div>
        </div>
        <div class="panel-body" style="max-height:444px">
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="text-center">
                                <th class="text-left">Sl.No</th>
                        <th class="text-center">Route</th>
                        <th class="text-center">Attendance Status</th>
                        <th class="text-left">Date</th>

                                <!--<th colspan="2" class="text-center">
                                    <tr class="text-center">
                                        <td class="present">Present
                                            <td>
                                            </tr>
                                            <tr class="text-center">
                                                <td>Boarded</td>
                                                <td style="position:relative;left:130%">Deboarded</td>
                                            </tr>
                                        </th> -->
                                    </tr>
                                </thead>
                                <tbody id="memtable">
                           <ng-container *ngFor="let item of memwiseAttendance ;let i =index;">
                           
                           
                       
        <tr>
           
        <td class="v-align" >{{i+1}}</td>                     
       
                    
       <td  class="v-align">{{item.routeName}}</td>
                   
                    
                  
                
                     <td>
                     <ng-container *ngIf="item.attendancestatus=='true' ">
                           <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.deboarded_ts}}</span> -->
                           <div class="text-center">
                              <span>
                                 <i class="fa fa-check-circle greenText" aria-hidden="true"></i>
                              </span>
                          
                           </div>
                        
                        </ng-container>
                        <ng-container *ngIf="item.attendancestatus=='false'">
                           <!-- <span class="text-center"><i class="fa fa-check text-success" aria-hidden="true"></i></span><br><span>{{item.notboarded_ts}}</span> -->
                           <div class="text-center">
                              <i class="fa fa-times-circle redText"></i>
                           </div>
                        </ng-container>
                     </td>
                 

                     <td class="v-align">{{item.ts | date: 'dd-MM-yyyy HH:mm'}}</td>


                     </tr>
                              </ng-container>

                          
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--    <ng-container *ngFor="let item of memwiseAttendance"><div class="col-sm-3 card bordered box-shad"><div class="text-center memDate">{{item.date}}</div><ng-container *ngIf="item.mess != 'noData';else invalidCard"><table class="table table-bordered table-condensed"><tr><th>Present</th><th>Absent</th></tr><tbody><tr><td><div class="text-center"><i class="fa fa-check-circle greenText" aria-hidden="true"></i></div></td><td><div class="text-center"><i class="fa fa-check-circle greenText" aria-hidden="true"></i></div></td></tr><tr><td><table><tr><td><i class="fa fa-clock-o">{{item.mess.boarded_ts | date:'HH:mm'}}</i></td><td class="cut-text" style="padding-left:15px;">{{item.mess.board_routeName}}</td></tr></table></td><td><table><tr><td><i class="fa fa-clock-o">{{item.mess.deboarded_ts | date:'HH:mm'}}</i></td><td class="cut-text" style="padding-left:15px;">{{item.mess.deboard_routeName}}</td></tr></table></td></tr><tr><td class="cut-pickup-point">{{item.mess.boarded_pickup}}</td><td class="cut-pickup-point">{{item.mess.deboarded_pickup}}</td></tr></tbody></table></ng-container></div></ng-container></div> -->
                </div>
            </div>
        </ng-container>
  
        <ng-container *ngIf="!memwiseAttendance.length">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="row mb5">
                        <div class="reportHeading text-center mb5">Memberwise Face Attendance Report</div>
                    </div>
                </div>
                <div class="panel-body">
                    <h4 class="animated pulse text-center" style="animation-duration: 1s;color:#999FBF">
                        <i class="fa fa-frown-o"></i>&nbsp;No records found
                    </h4>
                </div>
            </div>
        </ng-container>












   
    `,
    styleUrls: ['./face-attendance-reports.css'],
})

export class MemberwiseFaceAttendanceComponent {
    // variable declarations

    apiHeader: any;
    selectedDate: any;
    memwiseAttendance: any[];
    keying: any = Object.keys;
    public intermediateObj: any = {};

    rowSpanFlag: false;

    // end of variable declarations
    constructor(public api: ApiService) {

    }
    @Input('headerData')
    set headerData(val: any) {
        this.intermediateObj = '';
        this.intermediateObj = val;
    }


    //business logics for display

    @Input('getRouteWiseData') // get api response in this variable
    set getRouteWiseData(val: any) {
        try {
            this.valueResetter();
            this.apiHeader = val;
            this.selectedDate = val.data.form.fromDate.split('-').reverse().join('-');
            this.getMemAttendance2();
        } catch (e) { }
    }
    @Output() errorDetected: EventEmitter<any> = new EventEmitter();
    @Input() selectedMember: any = {};
    ngOnInit() {
        // console.log(this.selectedMember);
    }

    ngAfterViewInit() {

    }



    valueResetter() {
        this.memwiseAttendance = [];
        this.apiHeader = {};
    }




    getMemAttendance2() {
        let tempobj = {};
        this.intermediateObj = {};

        this.api.faceAttendanceReport(this.apiHeader).then((res: any) => {

            let routeRepo = res.hasOwnProperty('response') ? res.response : res;
            if (!routeRepo.ec) {
                let arr: any = [];
                let obj: { date?: string;[key: string]: any } = {};
                Object.keys(routeRepo).forEach((item: any, index) => {
                    let validFormat = Array.isArray(routeRepo[item]);
                    obj = {};
                    tempobj = {};
                    this.memwiseAttendance = routeRepo[item];
                    if (!validFormat) {

                        Object.keys(routeRepo[item]).forEach((val: any, key: any) => {
                            let obj: { date?: string; rType?: string } = {};
                            obj['date'] = item;
                        });

                    } else {
                        obj['date'] = item;
                        obj['status'] = 'absent';

                    }
                    arr.push(obj);

                    arr.push(tempobj);
                });

                // this.memwiseAttendance = JSON.parse(JSON.stringify(arr));


                this.errorDetected.emit({
                    ec: ''
                });

            } else {
                this.errorDetected.emit(routeRepo);
            }
        });

    }
    memberwiseExcel() {
        if (!this.memwiseAttendance || this.memwiseAttendance.length === 0) {
            alert("No data available to export!");
            return;
        }
        let excelData = this.memwiseAttendance.map((item, index) => {
            return {
                "Sl. No": index + 1,
                "Student ID": item.memberUID,
                "Student Nmae": item.memberName,
                "Class": item.memberClass,
                "Section": item.memberSection,
                "Route Name": item.routeName,
                "Attendance Status": item.attendancestatus === 'true' ? 'Present' : 'Absent',
                "Date": new Date(item.ts).toLocaleDateString("en-GB"), // Convert timestamp to readable date
                "Time": new Date(item.ts).toLocaleTimeString("en-GB")  // Convert timestamp to time
            };
        });

        // Create a worksheet
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Attendance Report': worksheet },
            SheetNames: ['Attendance Report']
        };
        const currentDate = new Date();

        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        // Generate Excel file and trigger download
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = `member_wise_face_attendance_report_${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, filename);


        // saveAs(data, `Attendance_Report_${new Date().toISOString().split('T')[0]}.xlsx`);
    }


}