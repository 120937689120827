
/**
 * @Date:   2017-11-15T11:10:05+05:30
 * @Last modified time: 2018-04-25T15:24:06+05:30
 */



import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable, Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AmazingTimePickerModule } from 'amazing-time-picker';

import { RouterModule, Routes } from '@angular/router';

// import { AgmCoreModule,GoogleMapsAPIWrapper,MapsAPILoader, MarkerManager } from '@agm/core';
import { AppComponent } from './app.component';

import swal from 'sweetalert';



// Importing Services
import { ApiService } from './services/ApiService';
import { DatePipe } from '@angular/common';
import { GlobalUtils } from './services/global-utils';
import { globalService } from './services/global.service';
import { StorageService } from './services/storage.service';
import { googleMapService } from './services/googleMapService';
import { bootstrapDateTime } from './services/bootstrapDateTime/datePicker';
import { decodeAddress } from './services/decodeAddress.service';
import { iconService } from './services/svg-icons/path';
import { animateMarker } from './services/markerAnimate';
import { excelUpload } from './services/excelUploadService';
import { authGaurd } from './services/auth-guard.service';
import { UtilityServices } from './services/utility.service';
import { VehicleServices } from './services/Vehicle-services';
import { ChartControllerService } from './services/chartControler.service';
import { GetDataServices } from './services/dataServices';
import { ImeiFinderServices } from './services/imeiFinder.service';
import { PagerService } from './services/pager.service';
import { chosenUtility } from './services/chosen-utility/jq-chosen-utility.js';
import { ApiCallDateFormatService } from "./services/api-call-date-format.service";
import { KDTreeService } from './services/kdtree.service';
import { ExcelService } from './services/excel.service';

// Importing Directives
import { TrigTrackerListenerComponent } from './adapter/tracker-listener/trig.dir';
import { TopMenuDir } from './directives/top-navbar/nav.dir';
import { addNotificationSettingsDir } from './directives/notifications/addNotificationSettings.dir';
import { viewNotificationSettingsDir } from './directives/notifications/view-notifications.dir';
import { editNotificationSettingsDir } from './directives/notifications/editNotification.dir';
import { orgMobileConfig } from './directives/notifications/orgMobileConfiguration';
import { OrgEmailConfig } from './directives/notifications/orgEmailConfiguration.dir'
import { alertConfigDir } from './directives/notifications/alertConfig.dir';
import { TrackerLogReport } from './directives/organization/tracker-Log/trackerLog.dir';
import { OrgUserFormDir } from './directives/organization/user-form/userForm.dir';
import { OrgEditUserForm } from './directives/organization/user-form/editUserForm.dir';
import { OrgTrackerFormDir } from './directives/organization/tracker-form/trackerForm.dir';
import { OrgTrackerUpdateFormDir } from './directives/organization/tracker-form/trackerUpdateForm.dir';
import { OrgTemplateFormDir } from './directives/organization/template-form/templateForm.dir';
import { OrgTemplateUpdateFormDir } from './directives/organization/template-form/updateTemplateForm.dir';
import { SchoolPickupFormDir } from './directives/school/pickup/pickupForm.dir';
import { SchoolRouteFormDir } from './directives/school/routes/routeForm.dir';
import { SchoolEditRouteFormDir } from './directives/school/routes/editRouteForm.dir';
import { routeTimelineDir } from './directives/school/routes/routeTimeline.dir';
import { MapExtraFilterDirective } from './directives/school/routes/map-extra-filter-directive.directive';
import { uploadPickPointsDir } from './directives/school/routes/uploadPickPoints.dir';
import { stoppageDetailsDir } from './directives/school/routes/stoppageDetails.dir';
import { SchoolEditPickupFormDir } from './directives/school/pickup/editPickupForm.dir';
import { studentFormDir } from './directives/students/studentForm.dir';
import { studentEditFormDir } from './directives/students/studentEditForm.dir';
import { routeAssignDetailsComponent } from './directives/students/route-assign-details/route-assign-details';
import { secondaryDropRouteChangeComponent } from './directives/students/secondary-drop-route-change/secondary-drop-route-change';

import { appSuccessDialogComponent } from './directives/app-dialog/success-dialog.dir';
import { appDialogComponent } from './directives/app-dialog/dialog.dir';
import { pushAlertsComponent } from './directives/push-alerts/pushAlert.dir';
import { slideAlertsComponent } from './directives/slide-alerts/slideAlerts.dir';
// import { orgGlobalConfiguration } from './directives/configuration/org.configuration.dir';
import { orgGlobalConfiguration } from './pages/org-global-settings/org-global-settings';
import { RouteCloneConfig } from './directives/configuration/route.clone.dir';
import { RouteMoveConfig } from './directives/configuration/route.move.dir';
import { RouteSwapConfig } from './directives/configuration/route.swap.dir';
// import { OrgRouteMove } from './directives/configuration/route.move.dir';
import { alertsReportGroup } from './directives/reports/alert-log-reports/alertLogReport';
import { analyticsReportGroup } from './directives/reports/analytics/notReachedReport';
import { trackingReportGroup } from './directives/reports/tracking-reports/trackingReports';
import { otherReportsGroup } from './directives/reports/other-reports/allVehicleAllRouteReport';
import { BreakdownMsgLog } from './directives/reports/other-reports/breakdownMsgLog';
import { RfidReports } from './directives/reports/rfidReports/rfidReports';
import { RfidStatusCheckComponent } from './directives/reports/rfidReports/rfidStatusCheck';
import { MemberwiserfidComponent } from './directives/reports/rfidReports/memberwiserfidReport';


import { RoutewiseRfidreportComponent } from "./directives/reports/rfidReports/routewiserfidReport";
import { RfidAppPushComponent } from "./directives/reports/rfidReports/rfid_app_push";
import { AttendanceReportsComponent } from './directives/reports/attendance-reports/attendance-reports.component';
import { DatewiseAttendanceComponent } from './directives/reports/attendance-reports/datewiseAttendance';
import { MemberwiseAttendanceComponent } from './directives/reports/attendance-reports/memberwiseAttendance';
import { RoutewiseAttendanceComponent } from './directives/reports/attendance-reports/routewiseAttendance';

import { faceAttendanceReportsComponent } from './directives/reports/face-attendance-reports/face-attendance-reports';

import { ClasswiseFaceAttendanceComponent } from './directives/reports/face-attendance-reports/classwise-face-report';
import { MemberwiseFaceAttendanceComponent } from './directives/reports/face-attendance-reports/memberwise-face-report';
import { RoutewiseFaceAttendanceComponent } from './directives/reports/face-attendance-reports/routewise-face-report';



import { RouteWiseRFIDCountComponent } from './directives/reports/rfidReports/routeWiseRFIDCountReport';
import { VehicleUpdateStatusDir } from './directives/reports/vehicle-update/lastUpdateStatus.dir';
import { searchAndselectDir } from './directives/search-select/searchAndSelect';
import { singleSearchSelect } from './directives/search-select/searchSelectSingleItem';
import { availableTemplatesDir } from './directives/campaign/templates/availableTemplates.dir';
import { createTemplateSectionDir } from './directives/campaign/templates/createTemplate.dir';
import { editCampaignTemplate } from './directives/campaign/templates/editTemplateDir';
import { DateWiseHistoryLogDir } from './directives/MobileAppInfo/datewiseLog.dir';
import { AutofocusDirective } from './directives/auto-focus/autofocus.dir';
import { LoaderDirectiveClass } from './directives/loader/loader.dir';
import { ProgressLoaderClass } from './directives/loader/progressLoader.dir';

import { SideMenuDir } from './directives/client-dashboard/side-menu/sideMenu';
import { MapDir } from './directives/client-dashboard/map-panel/map.dir';
import { BottomPanelDir } from './directives/client-dashboard/bottom-panel/bottom.dir';
import { SettingsDir } from './directives/client-dashboard/settings-panel/settings.dir';
import { AlertDir } from './directives/client-dashboard/alert-panel/alert.dir';
import { userLogDir } from './directives/client-dashboard/userLog/userLog.dir';
import { vehicleInfoDir } from './directives/client-dashboard/vehicleInfo/vehicleInfo.dir';
import { InputTaggingComponent } from './directives/input-tagging/input-tagging.component';


import { FusionGanttTimelineChart } from './directives/fusion-time-line-chart/fusion.timeline.chart';
import { createCampaignOperation } from './directives/campaign/CreateCampaignDirective';
import { UserManagement } from './directives/user-management/usermanagement';
import { TimepickerComponent } from './directives/datetimepicker/timepickerwidget'
import { SessionConfigurationComponent } from './directives/session-configurer/session-configuration/session-configuration.component';

// Importing custom pipes
import { PipeDeclarations } from './pipe.config';

// Importing Page Components
import { LoginPageComponent } from './pages/login/login';
import { PasswordResetPageComponent } from './pages/login/resetPassword';
import { adminLoginComponent } from './pages/login/admin.login';
import { NewOrgPageComponent } from './pages/new-organization/new';
import { ViewAdminOrgPageComponent } from './pages/organization/view-admin-organization/view.component';
import { ViewOrgDetailsComponent } from './pages/organization/view-admin-organization-details/viewOrgDetails.component';
import { ViewSchoolProfilePageComponent } from './pages/school/view-school-profile-details/viewSchoolProfile';
import { ViewNotReachedPageComponent } from './pages/school/analytics/notReached';
import { schoolVehicleList } from './pages/school/school-vehicle-list/schoolVehicleList';
import { schoolVehicleNotMoveList } from './pages/school/vehicle-not-move/schoolVehicleNotMoveList';
import { OrgSettings } from './pages/school/organization-settings/orgSettings';
import { schoolRouteListComponent } from './pages/school/school-routes/school.routes.list';
import { adminDashboardComponent } from './pages/dashboard/admin/adminDashboard';
import { clientDashboardComponent } from './pages/dashboard/client-dashboard/dashboard.component';
import { studentsListPageComponent } from './pages/students/students.list';
import { studentEdit } from './pages/students/editStudent';
import { addStudentComponent } from './pages/add-student-details/addStudent.component';
import { HolidaysPageComponent } from './pages/holidays/holidays.list';
import { studentExcelUploadPage } from './pages/students/studentExcelUpload';
import { rfidUploadPage } from './pages/students/rfidUpload';
import { StudentInfoExcelUpdate } from './pages/students/studentInfoUpdateExcel';
import { schoolCampaignComponent } from './pages/Campaigns/school-campaign';
import { MovementReportComponent } from './pages/Reports/movementReport/movementReport';
import { MobileDashboardComponent } from './pages/mobileAppDasboard/mobileDashboard';
import { AlertAppSettingsComponent } from './pages/mobileAppDasboard/appAlertSettings';
import { NotFoundComponent } from './pages/pageNotFound/notFound';
import { LiveLocationClass } from './pages/locationFinder/liveLocation';
import { ListViewComponent } from './pages/routelistview/list-view.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { CallMadeComponent } from './pages/call-made/call-made.component';
import { DateTimePicker } from "../app/directives/datetimepicker/datetimepicker";
import { InlineDateTimePicker } from "../app/directives/datetimepicker/inlinedatetimepicker";
import { ProgressBar } from "../app/directives/progressbar/progressbar.dir";
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { MobileStudentInfoComponent } from './pages/mobileAppDasboard/student-info/student-info';
import { AddUsersComponent } from './pages/user-management/add-users/add-users.component';
import { roleFormModule } from './model/role-model';
import { IncrementDir } from './pages/increment-index/inc';
import { RfidSMSLog } from './pages/increment-index/rfid.smslog';
import { RoleManagementComponent } from './pages/role-management/role-management.component';
import { AddRoleComponent } from './pages/role-management/add-role/add-role.component';
import { EditRoleComponent } from './pages/role-management/edit-role/edit-role.component';

import { EditUsersComponent } from './pages/user-management/edit-users/edit-users.component';
import { ViewOrgTrackerInfoComponent } from './pages/organization/tracker-info/tracker-info';



import { SerialNumberComponent } from "./pages/contentprojections/serailnumber";

import { COM } from './services/com.services';

import { ChannelPartnerRoutingDeclarations, ChannelPartnerRoutingConfig } from './pages/admin-profile/channel-partners/channel-partner-routing.config';
import { PermissionRestractedPage } from './pages/permission-retracted-page/permission-restracted';
import { TabularDashboardComponent } from './pages/tabular-dashboard/tabular-dashboard.component';
import { SurveillanceDashboardComponent } from './pages/surveillance-dashboard/surveillance-dashboard.component';

import { clientDashboardComponentConfig } from './pages/dashboard/client-dashboard/component.config';
// Redux

import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';

import { rootReducer, AppState } from './store/index';
import { RfidnotassignedlogComponent } from './pages/organization/rfidnotassignedlog/rfidnotassignedlog.component';
import { InputTagEmailComponent } from './directives/input-tag-email/input-tag-email.component';
import { MenuComponent } from './pages/Reports/menu/menu.component';
// import { RouteDeviationComponent } from './pages/route-deviation/route-deviation.component';
import { chartPageDeclaration } from './directives/charts/chart.page.declaration';

import { AnalyticsPageDeclaration } from './directives/analytics/analytics-page-declaration-component';
import { SearchBoxComponent } from './directives/search-box/search-box';
import { mapAnalyticComponentConfig, mapAnalyticServiceConfig } from './pages/map-analytics/map.analytics.file.config';
import { PoliciesComponent } from './pages/policies/policies.component';
import { TermsAndConditionsComponent } from './pages/policies/terms-and-conditions/terms-and-conditions.component';
import { newVehicleInfoDir } from './directives/client-dashboard/vehicleInfo/newVehicleInfo.dir';

// student-route-management
import { StudentRouteManagementComponent } from './pages/student-route-management/student-route-management';
import { AddStudentRouteManagementComponent } from './pages/student-route-management/add-student-route-management/add-student-route-management';
import { routeAnalyticsComponent } from './pages/route-analytics/route-analytics';
import { routeAnalyticsWeekDataComponent } from './pages/route-analytics/route-analytics-week-data/route-analytics-week-data';

import { allRouteDownloadComponent } from './pages/all-route-download/all-route-download';
import { VehicleListManagement } from './pages/school/vehicle-management/vehicle-list-management';
import { AddNewVehicleManagement } from './pages/school/vehicle-management/add-new-vehicle/add-new-vehicle';
import { OrgVehicleUpdateForm } from './pages/school/vehicle-management/update-vehicle-management/update-vehicle-management';

const appRoutes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'forgotPassword/:token', component: PasswordResetPageComponent }, /*getting token from mail for reset */
  { path: 'sysAdmin/admin-login', component: adminLoginComponent },
  { path: 'sysAdmin/new-organization', component: NewOrgPageComponent },
  { path: 'sysAdmin/view-admin-organization', component: ViewAdminOrgPageComponent },
  { path: 'sysAdmin/view-admin-organization-details', component: ViewOrgDetailsComponent },
  { path: 'sysAdmin/view-admin-profile', component: AdminProfileComponent },
  { path: 'client-dashboard', component: clientDashboardComponent },
  { path: 'client-dashboard-2', component: TabularDashboardComponent },
  { path: 'surveillance-dashboard', component: SurveillanceDashboardComponent },
  { path: 'sysAdmin/admin-dashboard', component: adminDashboardComponent },
  { path: 'view-school-profile-details', component: ViewSchoolProfilePageComponent },
  { path: 'view-not-reached-details', component: ViewNotReachedPageComponent },
  { path: 'school-vehicle-list', component: schoolVehicleList },
  { path: 'vehicle-not-move', component: schoolVehicleNotMoveList },
  { path: 'school-routes-list/:id', component: schoolRouteListComponent },
  { path: 'vehicle-list-management', component: VehicleListManagement },
  { path: 'school-vehicle-list/update-vehicle-form/:id', component: OrgVehicleUpdateForm },

  /* using this redundant path to pass the id from vehicle list page*/
  { path: 'school-routes-list', component: schoolRouteListComponent },
  { path: 'school-students-list', component: studentsListPageComponent },
  { path: 'org-settings', component: OrgSettings },
  { path: 'call-made-report', component: CallMadeComponent },
  { path: 'add-student-details', component: addStudentComponent },
  { path: 'edit-student-details/:id', component: studentEdit },
  { path: 'edit-student-details/route-assign/:id', component: routeAssignDetailsComponent },
  { path: 'edit-student-details/secondary-route-assign/:id', component: secondaryDropRouteChangeComponent },

  { path: 'student-excel-upload', component: studentExcelUploadPage },
  { path: 'rfid-upload', component: rfidUploadPage },
  { path: 'studentInfo-update-upload', component: StudentInfoExcelUpdate },
  { path: 'holidays-list', component: HolidaysPageComponent },
  { path: 'campaigns', component: schoolCampaignComponent },
  { path: 'movementReport', component: MovementReportComponent },
  { path: 'sysAdmin/mobileAppInfo', component: MobileDashboardComponent },
  { path: 'sysAdmin/alertappSettings', component: AlertAppSettingsComponent },
  { path: 'sysAdmin/studentInfo', component: MobileStudentInfoComponent },
  { path: 'sysAdmin/trackerInfo', component: ViewOrgTrackerInfoComponent },

  { path: 'orgGlobalConfiguration', component: orgGlobalConfiguration },
  { path: 'locateLive', component: LiveLocationClass },
  { path: 'routelistview', component: ListViewComponent },
  { path: 'user-management', component: UserManagementComponent },
  { path: 'role-management', component: RoleManagementComponent },
  { path: 'add-users', component: AddUsersComponent },
  { path: 'add-roles', component: AddRoleComponent },
  { path: 'edit-roles/:roleid', component: EditRoleComponent },
  { path: 'edit-users/:orguserid', component: EditUsersComponent },
  { path: 'permission-not-allowed', component: PermissionRestractedPage },
  { path: 'sysAdmin/rfidnotassignedlog', component: RfidnotassignedlogComponent },

  { path: 'student-route-management', component: StudentRouteManagementComponent },
  { path: 'add-student-route-management', component: AddStudentRouteManagementComponent },
  { path: 'route-analytics', component: routeAnalyticsComponent },
  { path: 'route-analytics-week', component: routeAnalyticsWeekDataComponent },

  { path: 'all-route-download', component: allRouteDownloadComponent },

  {
    path: 'analytics', component: MenuComponent, children: [
      // { path: '', component: MenuComponent },
      { path: 'vehicle-reach-insight', component: ViewNotReachedPageComponent },
    ]
  },
  { path: 'privacy-policy', component: PoliciesComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },

  ChannelPartnerRoutingConfig,

  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },


  // { path: '**', component: ListingComponent }
];



// Redux

@NgModule({
  imports: [BrowserModule, FormsModule, AmazingTimePickerModule, ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    HttpClientModule, NgReduxModule
  ],
  exports: [],
  declarations: [
    AppComponent, TrigTrackerListenerComponent, LoginPageComponent, adminLoginComponent, NewOrgPageComponent, ProgressBar, DateTimePicker, InlineDateTimePicker,
    ViewAdminOrgPageComponent, ViewOrgDetailsComponent, TopMenuDir, OrgUserFormDir, OrgTrackerFormDir,
    OrgTemplateFormDir, adminDashboardComponent, clientDashboardComponent,
    ViewSchoolProfilePageComponent, ViewNotReachedPageComponent, schoolRouteListComponent, SchoolPickupFormDir,
    SideMenuDir, MapDir, BottomPanelDir, SettingsDir, AlertDir, FusionGanttTimelineChart,
    pushAlertsComponent, otherReportsGroup, BreakdownMsgLog,
    trackingReportGroup, alertsReportGroup, analyticsReportGroup, RfidReports, RfidStatusCheckComponent, MemberwiserfidComponent, RouteWiseRFIDCountComponent, RoutewiseRfidreportComponent,
    RfidAppPushComponent,
    addNotificationSettingsDir, viewNotificationSettingsDir, editNotificationSettingsDir, alertConfigDir,
    orgMobileConfig, OrgEmailConfig, createCampaignOperation, searchAndselectDir, singleSearchSelect,
    slideAlertsComponent, availableTemplatesDir, createTemplateSectionDir, editCampaignTemplate,
    VehicleUpdateStatusDir, DateWiseHistoryLogDir, userLogDir, vehicleInfoDir, newVehicleInfoDir,
    PipeDeclarations, AutofocusDirective, LoaderDirectiveClass, OrgEditUserForm,
    OrgTrackerUpdateFormDir, OrgTemplateUpdateFormDir, ProgressLoaderClass, TrackerLogReport, InputTaggingComponent,
    SerialNumberComponent, ChannelPartnerRoutingDeclarations, appDialogComponent, appSuccessDialogComponent,
    HolidaysPageComponent, SchoolRouteFormDir, routeTimelineDir, MapExtraFilterDirective, uploadPickPointsDir, stoppageDetailsDir,
    SchoolEditPickupFormDir, studentEditFormDir, orgGlobalConfiguration, RouteCloneConfig, RouteMoveConfig,
    RouteSwapConfig, studentsListPageComponent, addStudentComponent, studentFormDir, TimepickerComponent,
    SchoolEditRouteFormDir, schoolVehicleList, schoolVehicleNotMoveList, studentEdit, studentExcelUploadPage, rfidUploadPage,
    StudentInfoExcelUpdate, OrgSettings, schoolCampaignComponent, MovementReportComponent, routeAssignDetailsComponent,
    MobileDashboardComponent, PasswordResetPageComponent, NotFoundComponent, LiveLocationClass, secondaryDropRouteChangeComponent,
    AlertAppSettingsComponent, ListViewComponent, AdminProfileComponent, CallMadeComponent, clientDashboardComponentConfig,
    IncrementDir, RfidSMSLog, UserManagementComponent, RoleManagementComponent, AddUsersComponent, AddRoleComponent,
    DatewiseAttendanceComponent, MemberwiseAttendanceComponent, RoutewiseAttendanceComponent,
    EditRoleComponent, AddRoleComponent, EditUsersComponent, EditUsersComponent, UserManagement, PermissionRestractedPage,
    TabularDashboardComponent, SessionConfigurationComponent, routeAnalyticsComponent, routeAnalyticsWeekDataComponent, ViewOrgTrackerInfoComponent,
    RfidnotassignedlogComponent, InputTaggingComponent, InputTagEmailComponent, SurveillanceDashboardComponent, AttendanceReportsComponent, MenuComponent,
    chartPageDeclaration, AnalyticsPageDeclaration, SearchBoxComponent, allRouteDownloadComponent,
    mapAnalyticComponentConfig, StudentRouteManagementComponent, AddStudentRouteManagementComponent,
    PoliciesComponent, TermsAndConditionsComponent, faceAttendanceReportsComponent, MobileStudentInfoComponent, ClasswiseFaceAttendanceComponent,
    VehicleListManagement, AddNewVehicleManagement, OrgVehicleUpdateForm, RoutewiseFaceAttendanceComponent, MemberwiseFaceAttendanceComponent
  ],
  providers: [ApiService, GlobalUtils, globalService, StorageService, googleMapService, bootstrapDateTime,
    decodeAddress, iconService, animateMarker, excelUpload, authGaurd, chosenUtility, ApiCallDateFormatService,
    UtilityServices, VehicleServices, GetDataServices, ImeiFinderServices, ,
    PagerService, COM, DatePipe, roleFormModule, ChartControllerService, KDTreeService, ExcelService,
    mapAnalyticServiceConfig
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<AppState>
  ) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as AppState
    );

  }
}