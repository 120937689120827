/**
 * @Author: Deepak Pookkote <group10>
 * @Date:   2018-04-24T11:51:53+05:30
 * @Email:  deepak@groupten.com
 * @Project: School-Bus
 * @Filename: schoolVehicleList.ts
 * @Last modified by:   group10
 * @Last modified time: 2018-05-09T12:55:45+05:30
 * @Copyright: Group10 Technologies
 */



/*Auther:Deepak */
import { Component } from '@angular/core';
import {
    ElementRef,
    AfterViewInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from './../../../app.component';

import { globalService } from './../../../services/global.service';
import { ApiService } from './../../../services/ApiService';
import { chosenUtility } from './../../../services/chosen-utility/jq-chosen-utility.js';

@Component({
    //template: ``,
    templateUrl: 'Vehicle-list-management.html',
    selector: 'vehicle-list-management',

    styles: [`

     .clickRouteClass{
      cursor: pointer;
      min-width: 20vh;
      /* background: red; */
      display: inline-block;
      min-width: 10px;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      background-color: #777;
      border-radius: 10px;
      margin-right: 15px;
     }
     .customized-form-control{
        height: 42px;
        border: 1px solid lightgray;
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #000;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius:2px;
     }
     .Windowactive {
       border-bottom: 3px solid #0095b9;
       padding-top: 7px;
     }
     .pagination a {
       color: black;
       float: left;
       padding: 7px 16px;
       text-decoration: none;
       border: 1px solid #bcb9b9;
     }
     .panel-default>.panel-heading {
      color: #333;
      background-color: #ffff;
      border-color: #ffff;
     }
     .panel {
      box-shadow: 1px 2px 4px 0px lightgrey;
     }
     .panel-default {
      border-color: #fdfafa;
     }

     /*search box CSS starts */
     .search-box {
      display: inline-block;
      width: 100%;
      border-radius: 3px;
      padding: 4px 55px 4px 15px;
      position: relative;
      background: #fff;
      border: 1px solid #ddd;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
     }
     .search-box.hovered, .search-box:hover, .search-box:active {
      border: 1px solid #aaa;
     }
     .search-box input[type=text] {
      border: none;
      box-shadow: none;
      display: inline-block;
      padding: 0;
      background: transparent;
     }
     .search-box input[type=text]:hover, .search-box input[type=text]:focus, .search-box input[type=text]:active {
      box-shadow: none;
     }
     .search-box .search-btn {
      position: absolute;
      right: 2px;
      top: 2px;
      color: #aaa;
      border-radius: 3px;
      font-size: 21px;
      padding: 5px 10px 1px;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
     }
     .search-box .search-btn:hover {
      color: #fff;
      background-color: #8FBE00;
     }

     div.section-title{
       font-size:20px;
     }
     div.section-title-border{
       margin-bottom: 20px;
       border-bottom: 1px solid #00BCD4;
       border-bottom-width: medium;
       border-spacing: 1px;
       width:13%;
     }

     .report-basic-info,.student-pickup-info,.student-drop-info,.student-notif-status{
       margin-bottom:10px;
     }

     input[type=checkbox], input[type=radio] {
       margin: 0px 4px -3px !important;
       line-height: normal;
     }

     /*search box CSS ends */

     a:hover {
     color: #23527c; 
     text-decoration:none;
    cursor: pointer;
     }
    `],
})
export class VehicleListManagement {

    public vehicleDetails: any[];
    public apivalues: any = {};
    private routes: any = [];
    public pageJump: number = 0;
    public limitOfRecords: number;
    private vehicleInfoAvailable: any = [];
    private allOrgRoutes: any[] = [];
    private filterRouteId: any;
    private filterVehicleReg: any;
    private globalFilterObj: any = {};
    private dummy: any = [];
    public isLoading: boolean; //Loader Var
    public vehicleManagement: any = {};
    public vehicleManagementList: boolean = true;
    public selectedTrackerId: any;
    public showUpdateTrackerForm: boolean = false;

    public addVehicle: boolean = false;
    public VehicleList: boolean = true;
    public sortedArray: any = [];
    trackerInfo: any;
    constructor(private router: Router, private apiService: ApiService, private chosenUtility: chosenUtility) { }
    hasSeatCapacity: boolean = false;
    ngOnInit() {
        this.viewTrackers()
        this.getAllOrgRoutes();

    }

    passCurrentTrackerId(id: any) {
        this.selectedTrackerId = id;


    }

    trackerAddformEvent($event: any) {
        setTimeout(() => this.addVehicle = $event, 1000);
        this.VehicleList = true
        this.viewTrackers();
        console.log("hhh")
    }
    trackerUpdateFormEvent($event: any) {

        setTimeout(() => this.showUpdateTrackerForm = $event, 1000);
        this.viewTrackers();
    }
    getAllOrgRoutes(): void {
        let apiHeader = {
            data: {
                key: localStorage.getItem('scbToken'),
            }
        }
        this.apiService.getRoutes(apiHeader).then(response => {
            this.allOrgRoutes = response.response;
            this.initializeChosenUtility();
        }).catch(error => { })
        this.viewOrgDetail()

    }


    applyFilters(): void {
        this.globalFilterObj = {};
        let isFilterRouteSelected = this.chosenUtility.getControlvalue('orgRoutesDropdown');
        if (this.filterVehicleReg) {
            Object.assign(this.globalFilterObj, { regNo: this.filterVehicleReg });
        }
        this.pageJump = 0;
        this.trackerInfo = [];
        this.viewTrackers();
    }


    viewOrgDetail() {
        this.apiService.getCurrentOrgDetails({
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {},
            }
        }).then(response => {
            let result = response;
            const classandsection: any = {};
            if (result.response && result.response.reports && result.response.reports.vehicleManagement
                && result.response.reports.vehicleManagement.vehicleManagement
            ) {
                this.vehicleManagement = result.response.reports.vehicleManagement.vehicleManagement

            }
        })
    }


    initializeChosenUtility() {
        setTimeout(() => {
            this.chosenUtility.initializeChosen('orgRoutesDropdown');
        }, 200)
    }
    uniqueItem(arr: any) {
        return Array.from(new Set(arr));
    }


    viewTrackers() {
        let apiData = {
            data: {
                key: localStorage.getItem('scbToken'),
                filter: {


                },
                extra: {

                    pageJump: this.pageJump
                }
            }

        }
        if (Object.getOwnPropertyNames(this.globalFilterObj).length) {
            apiData.data['filter'] = this.globalFilterObj;
        } else {
            delete apiData.data['filter'];
        }
        this.apiService.viewVehicleTrack(apiData).then((response: any) => {
            let result = response;
            if (result.status = "success") {
                this.trackerInfo = response.response;

            }
            if (this.pageJump == 0) {
                this.limitOfRecords = this.routes.length;
            }
        }).catch(error => {
            console.log('Error while logging in', error);
        });


    }





}
